<!-- eslint-disable vue/multi-word-component-names -->
<script async setup>
  import { ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useHead } from '@unhead/vue';
  import { useFaqStore } from '@/stores/faq.store.js';
  import { mergeTranslations } from '@/translations/i18n';
  import SectionDropdown from '@/components/SectionDropdown.vue';

  const { t } = useI18n();
  const faqStore = useFaqStore();

  const categoryTranslations = ref([]);
  const questionTranslations = ref([]);

  await faqStore.findParentCategories({}, { populate: [{ path: 'children', populate: 'faq' }, 'faq'] });

  // Create objects for merging translations
  faqStore.parentCategories.forEach((parentCategory) => {
    categoryTranslations.value.push({ _id: parentCategory._id, translations: parentCategory.translations });
    parentCategory.faq.forEach((faq) => {
      questionTranslations.value.push({ _id: faq._id, translations: faq.translations });
    });

    if (parentCategory.children.length) {
      parentCategory.children.forEach((childCategory) => {
        categoryTranslations.value.push({ _id: childCategory._id, translations: childCategory.translations });
        childCategory.faq.forEach((faq) => {
          questionTranslations.value.push({ _id: faq._id, translations: faq.translations });
        });
      });
    }
  });

  mergeTranslations(categoryTranslations.value, 'faqCategories');
  mergeTranslations(questionTranslations.value, 'faqs');

  useHead({
    title: t('faqs_title'),
    meta: [
      {
        name: 'description',
        content: t('faqs_title'),
      },
    ],
  });
</script>

<template>
  <main class="mx-auto max-w-7xl px-4 lg:px-8 py-8" style="min-height: calc(100vh - 340px)">
    <header class="border-b pb-5 mb-5">
      <h1 class="text-3xl font-medium text-gray-900">
        {{ $t('faqs_title') }}
      </h1>
      <p class="mt-2 text-gray-700 font-light text-lg" v-html="$t('faqs_page_description')"></p>
    </header>

    <div class="w-full m-auto" id="faq">
      <div v-for="category in faqStore.parentCategories" :key="category._id" class="border-b pb-5 border-gray-200">
        <div class="bg-white px-6 py-4 space-y-4 shadow shadow-sprb-700/30 sm:rounded-md">
          <h2 :id="category._id" class="text-sprb-700 font-medium text-xl mb-4 uppercase">
            {{ $t(`faqCategories.${category._id}.name`) }}
          </h2>
          <template v-if="category?.children?.length">
            <template v-for="childCategory in category.children" :key="childCategory._id">
              <div v-if="childCategory.faq.length" class="space-y-4">
                <div class="text-gray-800 font-semibold text-lg">
                  {{ $t(`faqCategories.${childCategory._id}.name`) }}
                </div>
                <SectionDropdown
                  v-for="(faq, index) in childCategory.faq"
                  :key="faq._id"
                  :title="`${index + 1}. ${$t(`faqs.${faq._id}.title`)}`"
                >
                  <div
                    v-html="$t(`faqs.${faq._id}.content`)"
                    class="py-2 leading-8 prose max-w-none prose-a:text-sprb-700"
                  />
                </SectionDropdown>
              </div>
            </template>
          </template>
          <SectionDropdown
            v-for="(faq, index) in category.faq"
            :key="faq._id"
            :title="`${index + 1}. ${$t(`faqs.${faq._id}.title`)}`"
          >
            <div v-html="$t(`faqs.${faq._id}.content`)" class="py-2 leading-8 prose max-w-none prose-a:text-sprb-700" />
          </SectionDropdown>
        </div>
      </div>
    </div>
  </main>
</template>
