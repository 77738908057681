<!-- eslint-disable max-len -->
<script setup>
  import { onMounted } from 'vue';
  import '@/styles/sprb-footer.css';
  import CookiesEuBanner from '@/utilities/cookies-eu-banner.js';
  onMounted(() => {
    const cookieAnalyticsCheckboxContainer = document.getElementById('cookies-analytics-checkbox');

    // Init CookiesEuBanner instance
    // Execute code if consent is OK
    const cookiesBanner = new CookiesEuBanner(function () {
      // Execute code when user accept cookies here...

      //  MATOMO
      if (import.meta.env?.VITE_ENV && import.meta.env?.VITE_ENV == 'PROD') {
        const _paq = (window._paq = window._paq || []);
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function () {
          const u = '//matomo.sprb-gob.brussels/';
          _paq.push(['setTrackerUrl', u + 'matomo.php']);
          _paq.push(['setSiteId', '4']);
          const d = document;
          const g = d.createElement('script');
          const s = d.getElementsByTagName('script')[0];
          g.async = true;
          g.src = u + 'matomo.js?v=4.14.2';
          s.parentNode.insertBefore(g, s);
        })();
      }
    }, true);

    // Display cookies parameters table
    const cookiesEuParameters = document.getElementById('cookies-eu-parameters');
    cookiesEuParameters.addEventListener('click', function () {
      const cookiesParameters = document.getElementById('cookies-parameters');
      const cookiesEuParametersClass = cookiesEuParameters.getAttribute('class');

      if (cookiesParameters.clientHeight) {
        cookiesParameters.style.height = 0;
        cookiesParameters.style.overflow = 'hidden';
        cookiesParameters.style.transition = 'height 0.5s ease-in-out';
      } else {
        cookiesParameters.style.height = cookiesParameters.scrollHeight + 'px';
      }

      if (cookiesEuParametersClass.indexOf('active') !== -1) {
        cookiesEuParameters.setAttribute('class', cookiesEuParametersClass.replace(' active', ''));
      } else {
        cookiesEuParameters.setAttribute('class', cookiesEuParametersClass + ' active');
      }
    });

    // Submit parameters
    const cookiesSaveParameters = document.getElementById('cookies-save-parameters');
    if (cookiesSaveParameters !== null) {
      cookiesSaveParameters.addEventListener('click', function () {
        const cookieAnalyticsCheckbox = document.querySelector('#cookies-analytics-checkbox');
        if (cookieAnalyticsCheckbox.checked) {
          cookiesBanner.setConsent(true);
        } else {
          cookiesBanner.setConsent(false);
        }

        cookiesBanner.removeBanner(0);
      });
    }

    // All accepted click button
    document.getElementById('cookies-eu-accept').addEventListener('click', function () {
      cookieAnalyticsCheckboxContainer.prop('checked', true);
    });

    // Has no consent notice
    if (cookiesBanner.hasConsent()) {
      // nothing
    } else {
      console.warn('COOKIE NOTICE: cookies are rejected or not set. No analytics code will be excecuted');
    }

    // Set analytics checkbox according user choice
    const cookieAnalyticsCheckbox = document.querySelector('#cookies-analytics-checkbox');
    if (cookiesBanner.hasConsent()) {
      cookieAnalyticsCheckbox.checked = true;
    } else {
      cookieAnalyticsCheckbox.checked = false;
    }

    // Show cookies banner
    // Applied on all elements with class="display-cookies-banner", including the cookieRevocationShortcode
    const displayBanner = document.querySelectorAll('.display-cookies-banner');
    const cookieEuParameters = document.querySelector('#cookies-eu-parameters');

    // Show hide banner on click
    for (let i = 0; i < displayBanner.length; ++i) {
      displayBanner[i].addEventListener('click', function () {
        cookieEuParameters.classList.add('active');
        cookiesBanner.showBanner();
      });
    }
  });

  // Used for rainbow
  const administrations = [
    '_mobility',
    '_connectit',
    '_international',
    '_finance',
    '_logement',
    '_synergie',
    '_pouvoirslocaux',
    '_economy',
  ];
</script>
<template>
  <div class="min-h-full">
    <!-- Start: SPRB cookies bar (block) -->
    <div id="" class="sprb-styles">
      <!-- Start: cookies banner (macro) -->
      <div
        id="cookies-eu-banner"
        class="cookies-banner shadow-lg z-5 text-sm fixed w-full bg-white-500 sm:mx-2 transition-height duration-1000 ease-in-out"
        style="position: relative; top: 0px; /* overide css for preview example */"
      >
        <!-- Start: Rainbow (partial) -->
        <div class="h-2 grid grid-cols-8">
          <div v-for="(admin, index) in administrations" :key="index" :class="admin"></div>
        </div>
        <!-- End: Rainbow (partial) -->
        <div class="cookies-banner__wrapper py-6 md:px-32 sm:px-8" style="background-color: white">
          <div class="flex flex-wrap justify-between mt-4 mb-3">
            <p class="cookies-banner__text">
              {{ $t('cookieNotice.noticeMessage') }}
              <RouterLink :to="localePath(`/page/privacy-policy`)" class="text-sprb-700">
                {{ $t('cookieNotice.knowMore') }}
              </RouterLink>
              .<br />{{ $t('cookieNotice.agreementMessage') }}
            </p>
            <div class="cookies-banner__buttons flex flex-wrap space-x-8">
              <button
                type="button"
                id="cookies-eu-parameters"
                class="active cookies-banner__button text-sprb-700 border-sprb-700 btn btn-outline-primary mb-3 uppercase font-semibold"
              >
                {{ $t('cookieNotice.cookiesParameters') }}
              </button>
              <button
                type="button"
                id="cookies-eu-accept"
                class="cookies-banner__button btn btn-primary mb-3 uppercase font-semibold"
              >
                {{ $t('cookieNotice.cookiesAgreement') }}
              </button>
            </div>
          </div>

          <!-- TODO auto-animate banner params -->
          <div class="cookies-parameters-trigger" id="cookies-parameters" style="/*display:none;*/">
            <h5 class="text-xl">
              {{ $t('cookieNotice.parametersTitle') }}
            </h5>
            <p class="mb-3 font-extralight">
              {{ $t('cookieNotice.parametersMessage') }}
            </p>

            <table role="presentation" class="border-gray-500 border ml-0 my-6 divide-y divide-gray-300">
              <tbody>
                <tr class="border-b border-gray-500">
                  <td class="p-4 border-r border-gray-500">
                    <label for="required-cookies">{{ $t('cookieNotice.parametersRequired') }}</label>
                  </td>
                  <td class="p-4" style="text-align: center">
                    <input
                      id="required-cookies"
                      type="checkbox"
                      style="background-color:#dfe5e8; !important"
                      checked
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td class="p-4 border-r border-gray-500">
                    <label for="cookies-analytics-checkbox">{{ $t('cookieNotice.parametersAnalytics') }}</label>
                  </td>
                  <td class="p-4" style="text-align: center">
                    <input type="checkbox" id="cookies-analytics-checkbox" name="cookies-analytics" />
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              type="button"
              id="cookies-save-parameters"
              class="active cookies-banner__button btn btn-primary mb-3 uppercase font-semibold"
            >
              {{ $t('cookieNotice.parametersButton') }}
            </button>
          </div>
        </div>
      </div>
      <!-- End: cookies banner (macro) -->
    </div>
    <!-- End: SPRB cookies bar (block) -->
  </div>
</template>
<style>
  #cookies-eu-banner {
    display: none;
    transition: height 0.5s ease-out;
    overflow: hidden;
    height: 0;
  }
</style>
