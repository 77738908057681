<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'PdfViewerLayout',
  });
</script>

<template>
  <slot></slot>
</template>
