<script setup>
  import { computed } from 'vue';

  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/20/solid';

  const props = defineProps({
    totalItems: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  });

  const emit = defineEmits(['changePage']);

  const totalPages = computed(() => {
    return Math.ceil(props.totalItems / props.perPage);
  });

  function remove(array, predicate) {
    const result = [];
    for (let i = 0; i < array.length; i++) {
      if (predicate(array[i])) {
        result.push(array[i]);
        array.splice(i, 1);
        i--;
      }
    }
    return result;
  }

  function uniq(array) {
    const result = [];
    for (let i = 0; i < array.length; i++) {
      if (!result.includes(array[i])) {
        result.push(array[i]);
      }
    }
    return result;
  }

  const treshold = computed(() => {
    const treshold = [
      1,
      props.currentPage - 2,
      props.currentPage - 1,
      props.currentPage,
      props.currentPage + 1,
      props.currentPage + 2,
      totalPages.value,
    ];
    remove(treshold, (v) => {
      return v < 1 || v > totalPages.value;
    });
    const result = uniq(treshold);

    if (result[1] > 2) {
      result.splice(1, 0, '...');
    }
    if (result[result.length - 2] < totalPages.value - 1) {
      result.splice(result.length - 1, 0, '...');
    }

    return result;
  });

  function goToPage(target) {
    if (target) {
      const skip = props.perPage * (target - 1);
      emit('changePage', skip);
    }
  }
</script>

<!-- eslint-disable max-len -->
<template>
  <div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
    <div class="flex flex-1 justify-between sm:hidden">
      <button
        @click="goToPage(currentPage - 1)"
        :disabled="currentPage === 1"
        class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
      >
        {{ $t('common.actions.previous') }}
      </button>
      <button
        @click="goToPage(currentPage + 1)"
        :disabled="currentPage === totalPages"
        class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
      >
        {{ $t('common.actions.next') }}
      </button>
    </div>
    <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700">
          <span class="font-medium">{{ (currentPage - 1) * perPage + 1 }}</span>
          {{ ' ' }}
          {{ $t('common.conjunctions.to') }}
          {{ ' ' }}
          <span class="font-medium">{{ Math.min(currentPage * perPage, totalItems) }}</span>
          {{ ' ' }}
          {{ $t('common.conjunctions.of') }}
          {{ ' ' }}
          <span class="font-medium">{{ totalItems }}</span>
          {{ ' ' }}
          {{ $t('common.result', totalItems).toLowerCase() }}
        </p>
      </div>
      <div>
        <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
          <button
            @click="goToPage(currentPage - 1)"
            :disabled="currentPage === 1"
            class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
          >
            <span class="sr-only">{{ $t('common.actions.previous') }}</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            v-for="page in treshold"
            :key="page"
            :disabled="page === '...'"
            @click="goToPage(page)"
            class="relative inline-flex items-center border px-4 py-2 text-sm font-medium focus:z-20"
            :class="{
              'z-10 bg-sprb-700 border-sprb-700 text-white': page === currentPage,
              'bg-white border-sprb-100 text-sprb-700 hover:bg-sprb-50 hover:border-sprb-100': page !== currentPage,
            }"
          >
            {{ page }}
          </button>
          <button
            @click="goToPage(currentPage + 1)"
            :disabled="currentPage === totalPages"
            class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20"
          >
            <span class="sr-only">{{ $t('common.actions.next') }}</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>
