import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

import UserService from '@/services/user.service';
import tokenService from '@/services/token.service';

export const useUserStore = defineStore('user', () => {
  const connectedUser = ref(tokenService.getUser());
  const loggedIn = computed(() => !!connectedUser.value);
  let mailConfirmed = false;
  if (connectedUser.value?.user?.verified == true) {
    mailConfirmed = true;
  }
  const memberships = ref({});

  function disconnect() {
    connectedUser.value = '';
    tokenService.removeUser();
  }

  async function update(user) {
    const updatedUser = await UserService.update(user._id, user);
    connectedUser.value.user = updatedUser;
    // TODO bizarre d'avoir le user aussi dans le local storage...
    updateUserLocalStorage(updatedUser);
  }

  async function updateUserLocalStorage(user) {
    connectedUser.value.user = user;
    const localStorageUser = tokenService.getUser();
    localStorageUser.user = user;
    tokenService.setUser(localStorageUser);
  }

  async function deleteUser() {
    await UserService.delete(connectedUser.value.user._id);
  }

  return {
    connectedUser,
    loggedIn,
    mailConfirmed,
    memberships,
    deleteUser,
    update,
    disconnect,
    updateUserLocalStorage,
  };
});
