<script setup>
  import { useSwitchLocalePath } from 'vue-i18n-routing';
  const switchLocalePath = useSwitchLocalePath();
  defineProps({
    classProp: {
      type: String,
    },
  });
</script>

<template>
  <router-link
    :key="`locale-${locale}`"
    v-for="locale in $i18n.availableLocales"
    :class="classProp"
    :to="switchLocalePath(locale)"
  >
    {{ locale.toUpperCase() }}
  </router-link>
</template>
