<script async setup>
  import { ref } from 'vue';

  import { useRoute } from 'vue-router';
  import { mergeTranslations, setLocale } from '@/translations/i18n';
  import SurveyPdfPreview from '@/components/SurveyPdfPreview.vue';

  import { useSurveyStore } from '@/stores/survey.store.js';
  import { useSelectListStore } from '@/stores/selectList.store.js';

  import '@/styles/animation.css';

  const surveyStore = useSurveyStore();
  const selectListStore = useSelectListStore();

  const route = useRoute();

  const project = ref([]);

  // Switch to url params language
  if (route?.query?.language) {
    setLocale(route.query.language);
  }

  await selectListStore.find();
  await surveyStore.populatedFindById(route.params.id);

  // insert questionnaire's project in array to be merged with translations
  project.value.push(surveyStore?.survey?.questionnaire?.project);

  mergeTranslations(surveyStore?.survey?.questionnaire?.panels, 'panels');
  mergeTranslations(project.value, 'projects');
  surveyStore?.survey?.questionnaire?.panels.forEach((panel) => {
    mergeTranslations(panel.questions, 'questions');
  });
  selectListStore?.optionLists.forEach((list) => {
    mergeTranslations(list.options, 'selectLists');
  });
</script>

<!-- eslint-disable max-len -->
<template>
  <SurveyPdfPreview
    :survey="surveyStore?.survey"
    :questionnaire="surveyStore?.survey?.questionnaire"
    :project="surveyStore?.survey?.questionnaire?.project"
    :exceptions="surveyStore?.survey?.exceptions"
    :selectLists="selectListStore?.optionLists"
  />
</template>
