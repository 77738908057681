<script setup>
  import { ref } from 'vue';
  import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
  import { XMarkIcon } from '@heroicons/vue/24/outline';

  defineProps({
    title: {
      type: String,
      default: 'Confirmation',
    },
    content: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'triangle-exclamation',
    },
    confirmBtnText: {
      type: String,
      required: true,
    },
    confirmBtnColor: {
      type: String,
      default: 'sprb',
    },
    useInput: {
      type: Boolean,
      default: false,
    },
    emptyInput: {
      type: Boolean,
      default: false,
    },
    disabledConfirm: {
      type: Boolean,
      default: false,
    },
  });
  defineEmits(['confirm', 'close']);
  const open = ref(true);
  const submitButtonRef = ref(null);
</script>

<!-- bg-red -->
<template>
  <!-- eslint-disable max-len -->
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="relative z-10"
      :initialFocus="submitButtonRef"
      @close="
        open = false;
        $emit('close');
      "
    >
      <div class="fixed inset-0 bg-sprb-900/75" aria-hidden="true" />
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button
                  type="button"
                  class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sprb-700 focus:ring-offset-2"
                  @click="
                    open = false;
                    $emit('close');
                  "
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10"
                  :class="{
                    'bg-sprb-700': confirmBtnColor === 'sprb',
                    'bg-red-100': confirmBtnColor === 'red',
                    'bg-orange-100': confirmBtnColor === 'orange',
                    'bg-yellow-100': confirmBtnColor === 'yellow',
                    'bg-green-100': confirmBtnColor === 'green',
                  }"
                >
                  <fontawesome-icon
                    :icon="['fas', icon]"
                    class="h-5 w-5"
                    :class="{
                      'text-white': confirmBtnColor === 'sprb',
                      'text-red-600': confirmBtnColor === 'red',
                      'text-orange-600': confirmBtnColor === 'orange',
                      'text-yellow-600': confirmBtnColor === 'yellow',
                      'text-green-600': confirmBtnColor === 'green',
                    }"
                    transform="up-1"
                    aria-hidden="true"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                    {{ title }}
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500" v-html="content"></p>
                    <slot></slot>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  ref="submitButtonRef"
                  class="inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  :class="{
                    'bg-sprb-600 hover:bg-sprb-700 focus:ring-sprb-700': confirmBtnColor === 'sprb',
                    'bg-red-600 hover:bg-red-700 focus:ring-red-500': confirmBtnColor === 'red',
                    'bg-orange-600 hover:bg-orange-700 focus:ring-orange-500': confirmBtnColor === 'orange',
                    'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500': confirmBtnColor === 'yellow',
                    'bg-green-600 hover:bg-green-700 focus:ring-green-500': confirmBtnColor === 'green',
                  }"
                  @click="
                    open = false;
                    $emit('confirm');
                  "
                  :disabled="disabledConfirm"
                >
                  {{ confirmBtnText }}
                </button>
                <button
                  type="button"
                  class="bg-sprb-50 hover:bg-sprb-100 focus:ring-red-500 text-sprb-700 inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  @click="
                    open = false;
                    $emit('close');
                  "
                >
                  {{ $t('common.actions.cancel') }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
