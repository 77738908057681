<script setup>
  import { ref, watch, markRaw } from 'vue';
  import { RouterView, useRoute } from 'vue-router';
  import { useLocaleHead } from 'vue-i18n-routing';

  import DefaultLayout from '@/layouts/DefaultLayout.vue';
  import PdfViewerLayout from '@/layouts/PdfViewerLayout.vue';

  const route = useRoute();
  const layout = ref();

  useLocaleHead({ addSeoAttributes: true });

  watch(
    () => route.meta?.layout,
    async (routeLayout) => {
      layout.value = markRaw(routeLayout === 'PdfViewerLayout' ? PdfViewerLayout : DefaultLayout);
    },
    { immediate: true }
  );
</script>

<template>
  <component :is="layout">
    <div id="main" style="min-height: calc(100vh - 350px)">
      <Suspense>
        <RouterView />
        <template #fallback> Loading... </template>
      </Suspense>
    </div>
  </component>
</template>
