import { ref } from 'vue';
import { defineStore } from 'pinia';
import authService from '@/services/auth.service';
import { useUserStore } from '@/stores/user.store';
import router from '@/router';
import tokenService from '@/services/token.service';
import { setLocale } from '@/translations/i18n';

const useAuthStore = defineStore('auth', () => {
  const userStore = useUserStore();
  const loginErrors = ref([]);
  // const signupErrors = ref([]);
  const resetPwdStep = ref(1);

  async function signup(data, verifyPath) {
    try {
      await authService.signup(data);
      router.push({
        path: verifyPath,
        query: {
          validated: 0,
          email: data.email,
        },
      });
    } catch (error) {
      let newError;
      switch (error.message) {
        case 'User exists!':
          newError = {
            message: `alreadyUsedEmail`,
            path: 'email',
          };
          break;
        case 'Error: domainNotMembership':
          newError = {
            message: `domainNotMembership`,
            path: 'email',
          };
          break;
      }
      throw newError;
    }
  }

  async function login(login, password, redirectPathAfterLogin, redirectPathIfNotVerified) {
    loginErrors.value = [];
    try {
      const res = await authService.login({ login, password });
      if (res) {
        // user not verified
        if (res.user.verified === false) {
          router.push({
            path: redirectPathIfNotVerified,
            query: {
              validated: 0,
              email: res.user.email,
            },
          });
          // user verified
        } else {
          tokenService.setUser(res);
          userStore.connectedUser = tokenService.getUser();
          // Switch app to user language when login
          setLocale(userStore.connectedUser.user.language);
          router.push({
            path: redirectPathAfterLogin,
          });
        }
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  function logout(redirectPathAfterLogout) {
    tokenService.removeUser();
    userStore.connectedUser = tokenService.getUser();
    router.push({
      path: redirectPathAfterLogout,
    });
  }

  async function verify(userId, token, verifyPath) {
    try {
      const res = await authService.verify(userId, token, verifyPath);
      if (res) {
        tokenService.setUser(res);
        userStore.connectedUser = tokenService.getUser();
        // Switch app to user language when login
        setLocale(userStore.connectedUser.user.language);
        router.push({
          path: verifyPath,
          query: {
            validated: 1,
          },
        });
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async function resendEmailVerify(email) {
    try {
      await authService.resendEmailVerify(email);

      return true;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async function sendResetPassEmail(email) {
    try {
      await authService.sendResetPassEmail(email);
      return true;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async function resetPassword(data) {
    try {
      await authService.resetPassword(data);
      return true;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  return {
    loginErrors,
    resetPwdStep,
    login,
    signup,
    logout,
    verify,
    resendEmailVerify,
    sendResetPassEmail,
    resetPassword,
  };
});

export default useAuthStore;
