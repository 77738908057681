import { defineStore } from 'pinia';
import { ref } from 'vue';

import pageService from '@/services/page.service.js';

export const usePageStore = defineStore('page', () => {
  const page = ref({});

  async function find(filter = {}, params = {}) {
    try {
      const res = await pageService.find({ filter, ...params });
      if (res) {
        page.value = res;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    find,
    page,
  };
});
