import { defineStore } from 'pinia';
import { ref } from 'vue';

import surveyService from '@/services/survey.service';

export const useSurveyStore = defineStore('survey', () => {
  const survey = ref({});
  const surveys = ref([]);
  const count = ref(0);

  async function create(data) {
    try {
      const res = await surveyService.create(data);
      return res;
    } catch (error) {
      console.error(error);
    }
  }

  async function find(filter = {}, params = {}) {
    try {
      const res = await surveyService.find({ filter, ...params });
      if (res) {
        surveys.value = res;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findById(id, populate) {
    try {
      const res = await surveyService.findById(id, { populate });
      if (res) {
        survey.value = res;
      } else {
        survey.value = null;
      }
    } catch (error) {
      // Feed le notif store
      console.error(error);
    }
  }

  async function populatedFindById(id) {
    try {
      const res = await surveyService.findById(id, {
        populate: [
          {
            path: 'advicesRequests',
            populate: [{ path: 'adviceFiles' }],
          },
          'user',
          {
            path: 'questionnaire',
            populate: [{ path: 'project' }, { path: 'panels', populate: 'questions' }],
          },
        ],
      });
      if (res) {
        survey.value = res;
      } else {
        survey.value = null;
      }
    } catch (error) {
      // Feed le notif store
      console.error(error);
    }
  }

  async function update(id, data) {
    try {
      const res = await surveyService.update(id, data);
      if (res) {
        await populatedFindById(res._id);
      }
    } catch (error) {
      // Feed le notif store
      console.error(error);
    }
  }

  async function deleteOne(id) {
    try {
      await surveyService.delete(id);
    } catch (error) {
      // Feed le notif store
      console.error(error);
    }
  }

  async function getCount(filter) {
    try {
      const res = await surveyService.count({ filter });
      if (res >= 0) {
        count.value = res;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function generatePdf() {
    try {
      const getpdf = await surveyService.generatePdf(survey.value);
      // console.log('getpdf', getpdf);
      if (getpdf?.error) {
        return { error: getpdf.error };
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    survey,
    surveys,
    count,
    create,
    find,
    findById,
    populatedFindById,
    update,
    deleteOne,
    getCount,
    generatePdf,
  };
});
