<script setup>
  import { ref } from 'vue';

  defineProps({
    title: {
      type: String,
      required: true,
    },
  });

  const isOpen = ref(false);

  function toggle() {
    isOpen.value = !isOpen.value;
  }
</script>

<template>
  <details @click="toggle" v-auto-animate>
    <summary class="flex items-center justify-between cursor-pointer">
      <h3 class="text-xl hover:text-sprb-700">
        {{ title }}
      </h3>
      <fontawesome-icon
        :icon="['fas', 'chevron-down']"
        class="cursor-pointer transition ease-out duration-500"
        :class="{
          'rotate-180': isOpen,
        }"
      />
    </summary>

    <div class="text-gray-700">
      <slot></slot>
    </div>
  </details>
</template>
