<script setup>
  import { ref, watch, computed, onMounted } from 'vue';
  import router from '@/router';

  import { useSurveyStore } from '@/stores/survey.store.js';
  import { useLocalePath } from 'vue-i18n-routing';
  import { useOptionStore } from '@/stores/option.store';
  import ListPagination from '@/components/ListPagination.vue';
  import ConfirmModal from '@/components/ConfirmModal.vue';
  import { getCurrentLanguage } from '@/translations/i18n';

  const localePath = useLocalePath();
  const surveyStore = useSurveyStore();
  const optionStore = useOptionStore();
  const changeUserStatusFrequency = ref({});

  onMounted(async () => {
    await optionStore.find({ key: 'advice_notifications' });
    const options = sessionStorage.getItem('options');
    changeUserStatusFrequency.value = JSON.parse(options).change_user_status_frequency;
  });

  const props = defineProps({
    filters: {
      type: Object,
      default: () => {},
    },
    populate: {
      type: Array,
      default: () => [],
    },
    sort: {
      type: String,
      default: 'updatedAt',
    },
    order: {
      type: String,
      default: 'desc',
    },
    perPage: {
      type: Number,
      default: 5,
    },
    sessionStorageKey: {
      type: String,
      default: 'tests-currentPage',
    },
  });

  const emits = defineEmits(['delete', 'sortBy']);
  defineExpose({ loadData });

  const language = computed(() => {
    return getCurrentLanguage();
  });

  function surveyDefaultLink(item) {
    if (item.status === 'closed') {
      return localePath('/test-preview/' + item._id);
    } else {
      return localePath('/test/' + item._id);
    }
  }

  // TODO refactor this : filter in store result array instead of db query
  const compOrder = computed(() => {
    return props.order;
  });

  watch(compOrder, async () => {
    await loadData();
  });

  const currentPage = ref(getCurrentPage());
  const deleteModal = ref(false);
  const duplicateModal = ref(false);
  const toDelete = ref(null);
  const toDuplicate = ref(null);
  const duplicateSurveyTitle = ref('');

  await loadData();
  async function loadData(skip = (currentPage.value - 1) * props.perPage, limit = props.perPage) {
    await surveyStore.find(props.filters, {
      skip,
      limit,
      populate: props.populate,
      sort: props.sort,
      order: props.order,
    });
    if (surveyStore.surveys.length === 0 && currentPage.value > 1) {
      setPageToSessionStorage(1);
      await loadData(0, props.perPage);
      return;
    }
    await surveyStore.getCount(props.filters);
  }

  async function duplicateItem() {
    try {
      const duplicatedSurvey = surveyStore.surveys.find((survey) => survey._id === toDuplicate.value);
      // eslint-disable-next-line no-unused-vars
      const { _id, createdAt, updatedAt, ...survey } = duplicatedSurvey;
      // TODO Traduction
      survey.title = duplicateSurveyTitle.value;
      survey.status = 'draft';
      survey.advicesRequests = [];
      // Clear general informations answers
      const generalInformationPanelId = duplicatedSurvey.questionnaire.panels.find(
        (panel) => panel.slug === 'general-information'
      )._id;
      for (const answer in survey.answers[generalInformationPanelId]) {
        if (survey.answers[generalInformationPanelId][answer]?.value) {
          survey.answers[generalInformationPanelId][answer].value = '';
        }
      }
      survey.completion = 0;
      survey.exceptions = {
        partialExceptionSelect: [],
        fullExceptionCheckboxes: [],
        partialExceptionExplanation: '',
      };

      const newSurvey = await surveyStore.create(survey);

      router.push({
        path: localePath('/test/new/' + newSurvey._id),
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteItem() {
    await surveyStore.deleteOne(toDelete.value);
    await loadData();
    emits('delete');
  }

  function setPageToSessionStorage(page) {
    currentPage.value = page;
    sessionStorage.setItem(props.sessionStorageKey, currentPage.value);
  }

  function getCurrentPage() {
    const page = +sessionStorage.getItem(props.sessionStorageKey);
    if (page >= 1) {
      return page;
    }
    return 1;
  }

  function sortBy(field) {
    emits('sortBy', field);
  }

  function getSortIcon(sort) {
    if (sort === props.sort) {
      return props.order === 'desc' ? 'sort-up' : 'sort-down';
    }
    return 'sort';
  }
</script>

<template>
  <div class="flex flex-col">
    <div class="overflow-x-auto -mx-2 px-2">
      <div class="px-4 inline-block min-w-full py-2 align-middle">
        <div class="shadow border md:rounded-lg">
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr>
                <th
                  @click="sortBy('title')"
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                >
                  <span class="mr-2">{{ $t('survey.title') }}</span>
                  <span class="cursor-pointer">
                    <fontawesome-icon :icon="['fas', getSortIcon('title')]" class="h-3 w-3 text-gray-400" />
                  </span>
                </th>
                <th
                  @click="sortBy('sort.0.project.' + language)"
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                >
                  <div class="flex items-end">
                    <span class="mr-2">{{ $t('survey.type') }}</span>
                    <span class="cursor-pointer">
                      <fontawesome-icon
                        :icon="['fas', getSortIcon('sort.0.project.' + language)]"
                        class="h-3 w-3 text-gray-400"
                      />
                    </span>
                  </div>
                </th>
                <th
                  @click="sortBy('updatedAt')"
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                >
                  <div class="flex items-end">
                    <span class="mr-2">{{ $t('survey.lastUpdate') }}</span>
                    <span class="cursor-pointer">
                      <fontawesome-icon :icon="['fas', getSortIcon('updatedAt')]" class="h-3 w-3 text-gray-400" />
                    </span>
                  </div>
                </th>
                <th
                  @click="sortBy('sort.0.status.' + language)"
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 cursor-pointer"
                >
                  <div class="flex items-end">
                    <span class="mr-2"> {{ $t('survey.status') }} </span>
                    <span class="cursor-pointer">
                      <fontawesome-icon
                        :icon="['fas', getSortIcon('sort.0.status.' + language)]"
                        class="h-3 w-3 text-gray-400"
                      />
                    </span>
                  </div>
                </th>
                <th id="progression" scope="col" class="px-3 py-3.5 text-sm font-semibold text-gray-900">
                  <div class="flex justify-start items-start">
                    <span>{{ $t('survey.completion') }}<small class="text-gray-500"></small></span>
                  </div>
                </th>
                <th scope="col" class="px-3 py-3.5 text-sm font-semibold text-gray-900">
                  <div class="text-center">
                    <span>{{ $t('survey.adviceRequest') }}</span>
                  </div>
                </th>
                <!-- Actions column -->
                <th scope="col" class="px-3 py-3.5 text-sm font-semibold text-gray-900">
                  <div class="text-left">
                    <span>{{ $t('common.actions.action') }}</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="item in surveyStore.surveys" :key="item._id" class="hover:bg-gray-50">
                <td class="align-middle whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6">
                  <RouterLink
                    :to="surveyDefaultLink(item)"
                    class="block max-w-[30ch] overflow-hidden text-ellipsis font-medium text-gray-900 cursor-pointer"
                  >
                    <fontawesome-icon
                      v-if="item?.status == 'closed'"
                      :icon="['fas', 'lock']"
                      class="text-gray-500 mr-2"
                      aria-hidden="true"
                      :title="`${$t('survey.label')} ${$t('survey.closed')}`"
                    />
                    {{ item.title }}
                  </RouterLink>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <RouterLink :to="surveyDefaultLink(item)">
                    <div class="text-gray-900">
                      {{ item.questionnaire.project.translations[$i18n.locale].name }}
                    </div>
                  </RouterLink>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <RouterLink :to="surveyDefaultLink(item)">
                    <div class="text-gray-900">
                      {{ $filters.formatDate(item.updatedAt) }}
                    </div>
                    <div class="text-gray-500 text-xs">
                      {{ `${$t('survey.createdAt')} : ${$filters.formatDate(item.createdAt)}` }}
                    </div>
                  </RouterLink>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <RouterLink :to="surveyDefaultLink(item)">
                    <span
                      v-if="item.status === 'closed'"
                      class="inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-green-100 text-green-800"
                      >{{ $t('test_status_closed') }}</span
                    >
                    <span
                      v-if="item.status === 'draft'"
                      :title="$t('survey.invalidate.draft', { value: changeUserStatusFrequency })"
                      class="inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-sprb-100 text-sprb-600"
                      >{{ $t('test_status_draft') }}</span
                    >
                    <span
                      v-if="item.status === 'abandoned'"
                      :title="$t('survey.invalidate.abandoned', { value: changeUserStatusFrequency })"
                      class="inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-red-100 text-red-700"
                      >{{ $t('test_status_abandoned') }}</span
                    >
                  </RouterLink>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-right text-gray-500 tabular-nums">
                  <RouterLink :to="surveyDefaultLink(item)">
                    <span
                      :class="{
                        'text-green-700': item.completion == 100,
                        'text-sprb-700': item.completion < 100,
                      }"
                    >
                      {{ item.completion }} %
                    </span>
                    <div class="flex items-center gap-2">
                      <div
                        role="meter"
                        aria-valuemin="0"
                        :aria-valuenow="item.completion"
                        aria-valuemax="100"
                        aria-labelledby="progression"
                        class="relative h-1.5 w-full rounded bg-gray-200"
                      >
                        <div
                          :style="`width: ${item.completion}%`"
                          class="absolute h-full rounded"
                          :class="{
                            'bg-green-600': item.completion == 100,
                            'bg-sprb-600': item.completion < 100,
                          }"
                        ></div>
                      </div>
                    </div>
                  </RouterLink>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-center text-gray-500 tabular-nums">
                  <span v-if="item.advicesRequests">
                    {{ item.advicesRequests.length }}
                  </span>
                  <span v-else>0</span>
                </td>
                <td>
                  <div class="flex justify-start gap-3 px-3">
                    <RouterLink :to="localePath(`/test-preview/${item._id}`)" :title="$t('common.actions.preview')">
                      <fontawesome-icon
                        :icon="['fas', 'file-pdf']"
                        class="text-gray-400 text-lg hover:text-sprb-700"
                        aria-hidden="true"
                      />
                    </RouterLink>
                    <button
                      @click="
                        () => {
                          toDuplicate = item._id;
                          duplicateModal = true;
                        }
                      "
                      :title="$t('survey.duplicateModalTitle')"
                    >
                      <fontawesome-icon :icon="['fas', 'copy']" class="text-gray-400 text-lg hover:text-sprb-700" />
                    </button>
                    <div>
                      <button
                        v-if="item.status !== 'closed'"
                        @click="
                          () => {
                            toDelete = item._id;
                            deleteModal = true;
                          }
                        "
                        class="text-red-600"
                        :title="$t('common.actions.delete')"
                      >
                        <fontawesome-icon :icon="['fas', 'trash']" class="text-gray-400 text-lg hover:text-red-500" />
                      </button>
                      <fontawesome-icon v-else :icon="['fas', 'lock']" class="text-gray-500" aria-hidden="true" />
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <ListPagination
            @change-page="
              (skip) => {
                setPageToSessionStorage(skip / perPage + 1);
                loadData(skip);
              }
            "
            :current-page="currentPage"
            :total-items="surveyStore.count"
            :per-page="perPage"
          />
        </div>
      </div>
    </div>
  </div>
  <Teleport to="body">
    <Transition name="modal-fade">
      <div v-if="deleteModal">
        <ConfirmModal
          @confirm="
            deleteItem();
            deleteModal = false;
          "
          @close="deleteModal = false"
          :title="$t('delete_test_modal_title')"
          :content="$t('delete_test_modal_content')"
          :confirmBtnText="$t('delete_test_modal_confirm')"
          :confirmBtnColor="'red'"
        />
      </div>
    </Transition>
    <Transition name="modal-fade">
      <div v-if="duplicateModal">
        <ConfirmModal
          @confirm="
            duplicateItem();
            duplicateModal = false;
          "
          @close="duplicateModal = false"
          :title="$t('survey.duplicateModalTitle')"
          :content="$t('survey.duplicateModalContent')"
          :confirmBtnText="$t('survey.duplicateModalConfirm')"
          :confirmBtnColor="'green'"
          :disabledConfirm="duplicateSurveyTitle == ''"
        >
          <input v-model="duplicateSurveyTitle" type="text" class="form-input mt-2" :placeholder="$t('survey.title')" />
        </ConfirmModal>
      </div>
    </Transition>
  </Teleport>
</template>
