<script setup>
  import { ref } from 'vue';

  import router from '@/router';
  import { useLocalePath } from 'vue-i18n-routing';

  import { useUserStore } from '@/stores/user.store.js';
  import useQuestionnaireStore from '@/stores/questionnaire.store.js';
  import { useSurveyStore } from '@/stores/survey.store.js';
  import { useVersionStore } from '@/stores/version.store';

  import { mergeTranslations, getCurrentLanguage } from '@/translations/i18n';

  import ConfirmModal from '@/components/ConfirmModal.vue';
  
  const localPath = useLocalePath()

  const localePath = useLocalePath();
  
  const questionnaireStore = useQuestionnaireStore();
  const userStore = useUserStore();
  const surveyStore = useSurveyStore();
  const versionStore = useVersionStore();

  const displayModal = ref(false);
  const selectedQuestionnaire = ref();
  const surveyTitle = ref('');

  await versionStore.getCurrentVersion();
  await questionnaireStore.find(
    {
      version: versionStore.currentVersion._id,
    },
    {
      populate: [{ path: 'project' }, { path: 'panels', populate: 'questions' }],
    }
  );

  const projects = questionnaireStore.questionnaires.map((questionnaire) => questionnaire.project);
  mergeTranslations(projects, 'projects');

  function initAnswers(questionnaire) {
    const answers = {};
    for (const panel of questionnaire.panels) {
      answers[panel._id] = {};
      for (const question of panel.questions) {
        if (
          !questionnaire?.inactiveQuestions?.includes(question._id) &&
          !['firstname', 'lastname', 'email'].includes(question.slug)
        ) {
          answers[panel._id][question._id] = { value: '' };
          if (question.addExplainField) {
            answers[panel._id][question._id].explanation = '';
          }
        }
      }
    }

    return answers;
  }

  async function createSurvey(questionnaire) {
    const newSurveyData = {
      user: userStore.connectedUser.user._id,
      questionnaire: questionnaire,
      title: surveyTitle.value,
      status: 'draft',
      language: getCurrentLanguage(),
      answers: initAnswers(questionnaire),
      exceptions: {
        partialExceptionSelect: [],
        fullExceptionCheckboxes: [],
        partialExceptionExplanation: '',
      },
    };
    const res = await surveyStore.create(newSurveyData);

    router.push({
      path: localePath('/test/new/' + res._id),
    });
  }
</script>

<!-- eslint-disable max-len -->
<template>
  <div>
    <ul role="list" class="grid grid-cols-1 gap-4 sm:grid-cols-2">
      <li v-for="questionnaire in questionnaireStore.questionnaires" :key="questionnaire._id" class="flow-root">
        <div
          class="relative bg-white h-24 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-gray-50"
        >
          <div class="flex-shrink-0 flex items-center justify-center w-16">
            <!-- <fontawesome-icon
              :icon="['fas', questionnaire.project.icon]"
              class="text-white text-3xl cursor-pointer"
            /> -->
            <svg class="scale-75" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 75">
              <defs>
                <clipPath id="a" transform="translate(-21.74 -191.25)">
                  <path d="M21.74 191.25h374.39v69.27H21.74z" style="fill: none" />
                </clipPath>
              </defs>
              <g style="clip-path: url(#a)">
                <path
                  d="M51.61 191.25c-15 0-29.87 11.29-29.87 30.18 0 18.32 17 29.71 31.7 29.71 16.22 0 24-5.08 37.71-2.32A37.21 37.21 0 0 1 110.49 259a3.45 3.45 0 0 0 4.3.68c1.19-.79 2-2.27 1.19-5.81-1.87-8.25-6.27-17.48-11.37-25.36-10-15.48-30.35-37.21-53-37.21"
                  style="fill: #2f3e8b"
                  transform="translate(-21.74 -191.25)"
                />
              </g>
            </svg>
          </div>
          <div>
            <h3 class="font-medium text-gray-900">
              <a
                @click="
                  {
                    displayModal = true;
                    selectedQuestionnaire = questionnaire;
                  }
                "
                class="focus:outline-none cursor-pointer"
              >
                <span class="absolute inset-0" aria-hidden="true"></span>
                <span>{{ $t(`projects.${questionnaire.project._id}.name`) }}</span>
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </h3>
            <p class="mt-1 text-sm text-gray-600">
              {{ $t(`projects.${questionnaire.project._id}.comment`) }}
            </p>
          </div>
        </div>
      </li>
    </ul>
    <Teleport to="body">
      <Transition name="modal-fade">
        <div v-if="displayModal">
          <ConfirmModal
            @confirm="
              createSurvey(selectedQuestionnaire);
              displayModal = false;
            "
            @close="displayModal = false"
            :title="$t('survey.creationModalTitle')"
            :content="$t('survey.creationModalContent')"
            :confirmBtnText="$t('survey.creationModalButton')"
            :disabledConfirm="surveyTitle == ''"
            confirmBtnColor="sprb"
          >
            <input v-model="surveyTitle" type="text" class="form-input mt-2" />
          </ConfirmModal>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>
