import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createHead } from '@unhead/vue';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import App from './App.vue';
import router from './router';
import { useI18n } from 'vue-i18n';
import { i18n } from '@/translations/i18n';
import { FontAwesomeIcon, FontAwesomeLayers } from '@/utilities/fontawesome-icons';
import './styles/main.css';
import setupInterceptors from '@/services/setupInterceptors';

import { loadTranslations } from '@/translations/i18n';

(async () => {
  await loadTranslations();
  setupInterceptors();
  const pinia = createPinia();
  const app = createApp(App);
  const head = createHead();
  /**
   * Custom filters
   */
  app.config.globalProperties.$filters = {
    formatDate(date, options = {}) {
      const { t } = useI18n();
      const time = new Date(date);

      const dayMonthYear = time.toLocaleString('be', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
      const hourMin = time.toLocaleString('be', {
        hour: '2-digit',
        minute: '2-digit',
      });
      // Format options
      if (options?.noHours == true) {
        return dayMonthYear;
      } else {
        // Default
        return dayMonthYear + ' ' + t('common.conjunctions.at') + ' ' + hourMin;
      }
    },
  };
  app.component('fontawesome-icon', FontAwesomeIcon);
  app.component('fontawesome-layers', FontAwesomeLayers);
  app.use(router);
  app.use(pinia);
  app.use(i18n);
  app.use(head);
  app.use(autoAnimatePlugin);
  app.mount('#app');
})();
