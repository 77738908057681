<script async setup>
  import { computed, ref, reactive } from 'vue';

  import { useUserStore } from '@/stores/user.store.js';

  import { getCurrentLanguage } from '@/translations/i18n';

  import AdvicePreviewModal from './AdvicePreviewModal.vue';
  import AdviceModal from './AdviceModal.vue';
  import PanelIcons from '@/components/SurveyPanelIcons.vue';
  import CustomRadio from '@/components/CustomRadio.vue';

  import '@/styles/animation.css';

  // eslint-disable-next-line no-unused-vars
  const props = defineProps({
    survey: {
      type: Object,
      required: true,
    },
    answers: {
      type: Object,
      required: true,
    },
    optionLists: {
      type: Object,
      required: true,
    },
    currentPanel: {
      type: Number,
      default: 0,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  });

  const emit = defineEmits(['stopTyping', 'updateErrors']);

  const userStore = useUserStore();
  const user = userStore.connectedUser.user;

  const openModal = ref(false);
  const openAdvicePreviewModal = ref(false);
  const needingAdvice = reactive({});
  const advicePreview = ref({});
  const prefillUser = {
    lastname: user.lastName,
    firstname: user.firstName,
    email: user.email,
  };

  // Calculate advice list
  const advices = computed(() => {
    const returnedAdvices = {};
    for (const advice in props.survey.advicesRequests) {
      if (Object.prototype.hasOwnProperty.call(props.survey.advicesRequests, advice)) {
        if (typeof returnedAdvices[props.survey.advicesRequests[advice].panel] == 'undefined') {
          returnedAdvices[props.survey.advicesRequests[advice].panel] = {};
        }
        returnedAdvices[props.survey.advicesRequests[advice].panel][props.survey.advicesRequests[advice].question] =
          props.survey.advicesRequests[advice];
      }
    }
    return returnedAdvices;
  });

  const answers = computed(() => props.answers);
  const panel = computed(() => props.survey.questionnaire.panels[props.currentPanel]);
  const questionsWithError = computed(() => props.errors.map((error) => error.questionId));

  function questionHasAdvice(panelId, questionId) {
    if (advices.value[panelId] && advices.value[panelId][questionId]) {
      return advices.value[panelId][questionId];
    } else {
      return false;
    }
  }

  function getOptionList(id) {
    const optionsList = props.optionLists.find((list) => list._id === id);

    // Sort options list
    if (optionsList?.sorted) {
      const options = optionsList?.options;
      const language = getCurrentLanguage();
      options.sort((a, b) => a.translations[language].localeCompare(b.translations[language]));
    }
    // Remove option if current date is after endValideDate or before startValidDate
    if (optionsList?.options) {
      const options = optionsList?.options;
      const currentDate = new Date();
      options.forEach((option) => {
        if (option.endValidDate) {
          const endValidDate = new Date(option.endValidDate);
          if (currentDate > endValidDate) {
            const index = options.indexOf(option);
            if (index > -1) {
              options.splice(index, 1);
            }
          }
        }
        if (option.startValidDate) {
          const startValidDate = new Date(option.startValidDate);
          if (currentDate < startValidDate) {
            const index = options.indexOf(option);
            if (index > -1) {
              options.splice(index, 1);
            }
          }
        }
      });
    }
    return optionsList;
  }

  function openAdviceModal(panel, question) {
    if (!isSurveyDisabled()) {
      openModal.value = true;
      needingAdvice.panel = panel;
      needingAdvice.question = question;
    }
  }

  let timeoutId;
  function debounceEmit(event, delay = 2000) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      emit(event);
    }, delay);
  }

  function isDisabled() {
    return isSurveyDisabled() || isPanelDisabled();
  }

  function isSurveyDisabled() {
    return props.survey.status === 'closed' || props.survey.status === 'abandoned';
  }

  function isPanelDisabled() {
    if (props.survey.exceptions.fullExceptionCheckboxes?.length > 0 && panel.value.useForFullExceptions) {
      return true;
    }
    if (
      props.survey.exceptions.partialExceptionSelect?.includes(panel.value._id) &&
      !panel.value.doNotUseForPartialExceptions
    ) {
      return true;
    }
    return false;
  }

  function numbersOnlyIfNumeric(event, fieldType) {
    if (fieldType !== 'input_amount') {
      return true;
    }
    event = event ? event : window.event;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
      event.preventDefault();
    } else {
      return true;
    }
  }

  function activeQuestions(panel) {
    return panel.questions.filter((question) => {
      return !props.survey.questionnaire.inactiveQuestions.includes(question._id);
    });
  }

  function updateError(questionId) {
    emit('updateErrors', questionId);
  }
</script>

<!-- eslint-disable max-len -->
<template>
  <div
    v-if="isPanelDisabled()"
    class="bg-amber-100 rounded border-2 border-amber-300 border-dashed p-4 text-amber-800 mb-4"
  >
    {{ $t('test_exception_description') }}
  </div>
  <div class="lg:col-span-2 md:col-span-2 pb-5 border-gray-200">
    <div class="overflow-hidden shadow sm:rounded-md">
      <div class="bg-white divide-y divide-gray-200 overflow-hidden rounded-lg shadow">
        <div class="text-xl font-medium p-4 flex">
          <PanelIcons :panelSlug="panel.slug"></PanelIcons>
          {{ $t('panels.' + panel._id + '.title') }}
        </div>
        <div class="grid grid-cols-6 gap-4 p-4">
          <TransitionGroup name="slide-from-right">
            <div v-for="question in activeQuestions(panel)" :key="`${panel._id}-${question._id}`" class="col-span-6">
              <label
                :for="`${panel._id}-${question._id}`"
                class="block mb-2 text-base font-medium text-gray-700 leading-5"
                >{{ $t(`questions.${question._id}.name`) + (question.isRequired ? '*' : '') }}
              </label>
              <div
                v-if="$t(`questions.${question._id}.comment`)"
                v-html="$t(`questions.${question._id}.comment`)"
                class="text-sm text-gray-500 mb-2"
              />
              <div
                v-if="
                  question.fieldType === 'input_text' ||
                  question.fieldType === 'input_amount' ||
                  question.fieldType === 'input_mail' ||
                  question.fieldType === 'date'
                "
              >
                <div v-if="question.slug != 'lastname' && question.slug != 'firstname' && question.slug != 'email'">
                  <div v-if="question.fieldType === 'input_amount'" class="relative mt-1 rounded-md shadow-sm w-1/2">
                    <div
                      v-if="$i18n.locale === 'nl'"
                      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                    >
                      <span class="text-gray-500 sm:text-sm">€</span>
                    </div>
                    <input
                      @keyup="debounceEmit('stopTyping')"
                      @keypress="numbersOnlyIfNumeric($event, question.fieldType)"
                      @input="updateError(question._id)"
                      type="text"
                      v-model.trim="answers[panel._id][question._id].value"
                      :name="`${panel._id}-${question._id}`"
                      :id="`${panel._id}-${question._id}`"
                      class="block w-full rounded-md form-input"
                      placeholder="0"
                      :class="{
                        'bg-gray-100 cursor-not-allowed': isDisabled(),
                        'pl-7': $i18n.locale === 'nl',
                        'border-red-400': questionsWithError.includes(question._id),
                      }"
                      :disabled="isDisabled()"
                    />
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span class="text-gray-500 sm:text-sm" id="price-currency">{{ $t('survey.exVat') }}</span>
                    </div>
                  </div>
                  <input
                    v-else
                    @keyup="debounceEmit('stopTyping')"
                    v-model.trim="answers[panel._id][question._id].value"
                    :type="question.fieldType"
                    :name="`${panel._id}-${question._id}`"
                    :id="`${panel._id}-${question._id}`"
                    class="form-input"
                    :class="{
                      'bg-gray-100 cursor-not-allowed': isDisabled(),
                      'border-red-400': questionsWithError.includes(question._id),
                    }"
                    :disabled="isDisabled()"
                  />
                </div>
                <div v-else>
                  <input
                    v-model.trim="prefillUser[question.slug]"
                    :type="question.fieldType"
                    :name="`${panel._id}-${question._id}`"
                    :id="`${panel._id}-${question._id}`"
                    class="form-input bg-gray-100 cursor-not-allowed"
                    disabled
                  />
                </div>
              </div>
              <textarea
                @keyup="debounceEmit('stopTyping')"
                @input="updateError(question._id)"
                v-if="question.fieldType === 'textarea'"
                v-model.trim="answers[panel._id][question._id].value"
                :id="`${panel._id}-${question._id}`"
                :name="`${panel._id}-${question._id}`"
                class="form-input"
                rows="4"
                :class="{
                  'bg-gray-100 cursor-not-allowed': isDisabled(),
                  'border-red-400': questionsWithError.includes(question._id),
                }"
                :disabled="isDisabled()"
              ></textarea>
              <select
                @change="
                  () => {
                    updateError(question._id);
                    debounceEmit('stopTyping');
                  }
                "
                v-if="question.fieldType === 'select'"
                :id="`${panel._id}-${question._id}`"
                :name="`${panel._id}-${question._id}`"
                v-model="answers[panel._id][question._id].value"
                class="w-1/2 form-input"
                :class="{
                  'bg-gray-100 cursor-not-allowed': isDisabled(),
                  'border-red-400': questionsWithError.includes(question._id),
                }"
                :disabled="isDisabled()"
              >
                <option value="" :selected="answers[panel._id][question._id].value === ''">
                  {{ $t('survey.chooseOption') }}
                </option>
                <option
                  v-for="option in getOptionList(question.selectList).options"
                  :key="option.id"
                  :value="option.id"
                  :selected="answers[panel._id][question._id].value === option.id"
                >
                  {{ $t(`options.${option._id}`) }}
                </option>
              </select>
              <CustomRadio
                v-if="question.fieldType === 'radio'"
                @change="debounceEmit('stopTyping')"
                @update="answers[panel._id][question._id].value = $event"
                :name="`${panel._id}-${question._id}`"
                :options="getOptionList(question.selectList).options"
                :checked="answers[panel._id][question._id].value"
                :disabled="isDisabled()"
              />
              <textarea
                v-if="question.addExplainField"
                v-model.trim="answers[panel._id][question._id].explanation"
                @keyup="debounceEmit('stopTyping')"
                :placeholder="$t('survey.explain')"
                class="form-input"
                rows="4"
                :class="{
                  'bg-gray-100 cursor-not-allowed': isDisabled(),
                }"
                :disabled="isDisabled()"
              ></textarea>
              <div v-if="question.adviceRequestDisplayed && !isDisabled()">
                <div
                  class="flex justify-start items-center mt-1 text-xs font-medium text-gray-500 cursor-pointer hover:text-sprb-700 hover:underline"
                  v-if="questionHasAdvice(panel._id, question._id)"
                  @click="
                    openAdvicePreviewModal = true;
                    advicePreview = advices[panel._id][question._id];
                  "
                >
                  <fontawesome-icon :icon="['far', 'comments']" />
                  <span class="ml-2">
                    {{ $t('survey.askedAdvice') }}
                    {{ $filters.formatDate(advices[panel._id][question._id].createdAt) }}
                  </span>
                </div>
                <div
                  v-else
                  @click="openAdviceModal(panel, question)"
                  class="flex justify-start items-center mt-1 text-xs font-medium hover:underline"
                  :class="{
                    'text-gray-500 cursor-pointer hover:text-sprb-700': !isSurveyDisabled(),
                    'text-gray-500 cursor-not-allowed': isSurveyDisabled(),
                  }"
                >
                  <fontawesome-icon :icon="['far', 'comments']" />
                  <span class="ml-2">{{ $t('test_ask_advice') }}</span>
                </div>
              </div>
              <span v-if="questionsWithError.includes(question._id)" class="text-sm text-red-500">{{
                $t(errors.find((error) => error.questionId === question._id).message)
              }}</span>
            </div>
          </TransitionGroup>
        </div>
      </div>
    </div>
  </div>

  <Teleport to="body">
    <Transition name="modal-fade">
      <div v-if="openModal">
        <AdviceModal @close="openModal = false" :origin="needingAdvice" class="_content" />
      </div>
    </Transition>
    <Transition name="modal-fade">
      <div v-if="openAdvicePreviewModal">
        <AdvicePreviewModal @close="openAdvicePreviewModal = false" :advice="advicePreview" class="_content" />
      </div>
    </Transition>
  </Teleport>
</template>
