import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faAngleUp,
  faSort,
  faSortUp,
  faSortDown,
  faArrowRotateLeft,
  faArrowRotateRight,
  faBalanceScale,
  faBold,
  faCalendar,
  faChevronDown,
  faCircle,
  faCircleInfo,
  faCircleMinus,
  faCircleNotch,
  faCirclePlus,
  faCircleQuestion,
  faCircleXmark,
  faClock,
  faCopy,
  faDownload,
  faEye,
  faEyeSlash,
  faFileArrowUp,
  faFileContract,
  faFilePdf,
  faGavel,
  faHandshake,
  faHeading,
  faImage,
  faItalic,
  faListOl,
  faListUl,
  faLock,
  faQuestion,
  faParagraph,
  faQuoteRight,
  faRulerHorizontal,
  faStamp,
  faTable,
  faTableColumns,
  faTextSlash,
  faTrash,
  faTriangleExclamation,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { faComments } from '@fortawesome/free-regular-svg-icons';
import { faFacebookF, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

library.add(
  faAngleDown,
  faAngleUp,
  faSort,
  faSortUp,
  faSortDown,
  faArrowRotateLeft,
  faArrowRotateRight,
  faBalanceScale,
  faBold,
  faCalendar,
  faChevronDown,
  faCircle,
  faCircleInfo,
  faCircleMinus,
  faCircleNotch,
  faCirclePlus,
  faCircleQuestion,
  faCircleXmark,
  faClock,
  faComments,
  faCopy,
  faDownload,
  faEye,
  faEyeSlash,
  faFacebookF,
  faFileArrowUp,
  faFileContract,
  faFilePdf,
  faGavel,
  faHandshake,
  faHeading,
  faImage,
  faItalic,
  faLinkedinIn,
  faListOl,
  faListUl,
  faLock,
  faQuestion,
  faParagraph,
  faQuoteRight,
  faRulerHorizontal,
  faStamp,
  faTable,
  faTableColumns,
  faTextSlash,
  faTrash,
  faTriangleExclamation,
  faTwitter,
  faYoutube,
  faXmark
);

export { FontAwesomeIcon, FontAwesomeLayers };
