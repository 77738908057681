import { defineStore } from 'pinia';
import { ref } from 'vue';

import newsService from '@/services/news.service.js';

export const useNewsStore = defineStore('news', () => {
  const news = ref({});
  const newsItem = ref({});

  async function find(filter = {}, params = {}) {
    try {
      const res = await newsService.find({ filter, ...params }).catch((error) => {
        console.error(error.response.data);
        throw error;
      });

      news.value = res;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    find,
    news,
    newsItem,
  };
});
