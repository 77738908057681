import CoreService from './core.service';
class NewsService extends CoreService {
  constructor() {
    super({
      endpoint: 'news',
    });
  }
}

export default new NewsService();
