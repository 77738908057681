<script setup>
  import { ref, reactive } from 'vue';
  import { useAdviceRequestStore } from '@/stores/adviceRequest.store.js';
  import ListPagination from '@/components/ListPagination.vue';
  import AdvicePreviewModal from './AdvicePreviewModal.vue';

  const props = defineProps({
    filters: {
      type: Object,
      default: () => {},
    },
    populate: {
      type: Array,
      default: () => [],
    },
    sort: {
      type: String || null,
      default: null,
    },
    order: {
      type: String || null,
      default: null,
    },
    perPage: {
      type: Number,
      default: 5,
    },
    sessionStorageKey: {
      type: String,
      default: 'advices-currentPage',
    },
  });

  defineExpose({ loadData });

  const adviceRequestStore = useAdviceRequestStore();

  const currentPage = ref(getCurrentPage());
  const advicePreview = ref({});
  const previewModal = ref(false);

  await loadData();

  async function loadData(skip = (currentPage.value - 1) * props.perPage, limit = props.perPage) {
    await adviceRequestStore.find(props.filters, {
      skip,
      limit,
      populate: props.populate,
      sort: props.sort,
      order: props.order,
    });
    if (adviceRequestStore.adviceRequests.length === 0 && currentPage.value > 1) {
      setPageToSessionStorage(1);
      await loadData();
      return;
    }
    await adviceRequestStore.getCount(props.filters);
  }

  function setPageToSessionStorage(page) {
    currentPage.value = page;
    sessionStorage.setItem(props.sessionStorageKey, currentPage.value);
  }

  function getCurrentPage() {
    const page = +sessionStorage.getItem(props.sessionStorageKey);
    if (page >= 1) {
      return page;
    }
    return 1;
  }
</script>

<template>
  <div class="flex flex-col">
    <div class="overflow-x-auto -mx-4">
      <div class="px-4 inline-block min-w-full py-2 align-middle">
        <div class="shadow overflow-hidden border md:rounded-lg">
          <table class="min-w-full divide-y divide-gray-300">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="items-end py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  <span class="mr-2">{{ $t('advice.surveyTableTh') }}</span>
                </th>
                <th
                  scope="col"
                  class="items-end py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  <span class="mr-2">{{ $t('advice.name') }}</span>
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <div class="flex items-end">
                    <span>{{ $t('advice.date') }}</span>
                  </div>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="item in adviceRequestStore.adviceRequests" :key="item._id" class="hover:bg-gray-50">
                <td class="align-middle py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <span
                    v-if="item.survey && item.survey.title"
                    class="cursor-pointer"
                    @click="
                      previewModal = true;
                      advicePreview = item;
                    "
                  >
                    <fontawesome-icon
                      v-if="item?.survey?.status == 'closed'"
                      :icon="['fas', 'lock']"
                      class="text-gray-500 mr-2"
                      aria-hidden="true"
                      :title="`${$t('survey.label')} ${$t('survey.closed')}`"
                    />
                    {{ item?.survey?.title }}
                  </span>
                  <span v-else>Test deleted</span>
                </td>
                <td class="align-middle py-4 pl-4 pr-3 text-sm sm:pl-6">
                  <span
                    class="cursor-pointer font-medium"
                    @click="
                      previewModal = true;
                      advicePreview = item;
                    "
                  >
                    {{ item.translations[$i18n.locale].shortName }}
                  </span>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <div class="text-gray-900">
                    {{ $filters.formatDate(item.createdAt) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <ListPagination
            @change-page="
              (skip) => {
                setPageToSessionStorage(skip / perPage + 1);
                loadData(skip);
              }
            "
            :current-page="currentPage"
            :total-items="adviceRequestStore.count"
            :per-page="perPage"
          />
        </div>
      </div>
    </div>
  </div>
  <Teleport to="body">
    <Transition name="modal-fade">
      <div v-if="previewModal">
        <AdvicePreviewModal @close="previewModal = false" :advice="advicePreview" :linkToSurvey="true" />
      </div>
    </Transition>
  </Teleport>
</template>
