<script>
  export default {
    name: 'AuthPage',
  };
</script>

<script setup>
  import { reactive, ref, toRaw, computed, onMounted } from 'vue';
  import router from '@/router';
  import { useLocalePath } from 'vue-i18n-routing';

  import { useMembershipStore } from '@/stores/membership.store';
  import { useUserStore } from '@/stores/user.store';
  import useAuthStore from '@/stores/auth.store';

  import { signupSchema } from '@/utilities/validation';
  import { getStartingLocale } from '@/translations/i18n';

  import FormStep from '../components/FormStep.vue';
  import AlertBox from '@/components/AlertBox.vue';
  import { useHead } from '@unhead/vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();

  const localePath = useLocalePath();

  // if user is logged in, redirect to user/tests
  const loggedIn = useUserStore().loggedIn;
  onMounted(async () => {
    if (loggedIn) {
      router.push({
        path: localePath('/user/tests'),
      });
    }
  });
  const authStore = useAuthStore();
  const membershipStore = useMembershipStore();
  await membershipStore.find();

  const userData = reactive({});

  // Set user browser language, and change it if he change language in form
  userData.language = getStartingLocale();

  const steps = ref([
    {
      title: 'profile.labels.account',
      comment: 'signup_page_account_description',
      comment_end: '',
      fields: [
        {
          label: 'user.email',
          required: true,
          comment: 'auth.emailFieldComment',
          input: {
            type: 'email',
            name: 'email',
            id: 'email',
            placeholder: 'user.email',
            autocomplete: 'email',
          },
        },
        {
          label: 'common.actions.confirmEmail',
          required: true,
          input: {
            type: 'email',
            name: 'confirmEmail',
            id: 'confirmEmail',
            placeholder: 'common.actions.confirmEmail',
            autocomplete: 'email',
          },
        },
        {
          label: 'user.password',
          required: true,
          input: {
            type: 'password',
            name: 'password',
            id: 'password',
            placeholder: 'user.password',
          },
          comment: 'auth.passwordStrengthError',
        },
        {
          label: 'common.actions.confirmPassword',
          required: true,
          input: {
            type: 'password',
            name: 'confirmPassword',
            id: 'confirm-password',
            placeholder: 'common.actions.confirmPassword',
          },
        },
        {
          label: 'user.language',
          required: true,
          comment: 'user.languageFieldComment',
          input: {
            type: 'select',
            name: 'language',
            id: 'language',
            options: [
              {
                label: 'languages.french',
                value: 'fr',
              },
              {
                label: 'languages.dutch',
                value: 'nl',
              },
            ],
          },
        },
      ],
    },
    {
      title: 'profile.labels.professionalInfo',
      comment: 'signup_page_professional_info_description',
      fields: [
        {
          label: 'user.firstname',
          required: true,
          input: {
            type: 'text',
            name: 'firstName',
            id: 'firstname',
            placeholder: 'user.firstname',
            autocomplete: 'given-name',
          },
        },
        {
          label: 'user.lastname',
          required: true,
          input: {
            type: 'text',
            name: 'lastName',
            id: 'lastname',
            placeholder: 'user.lastname',
            autocomplete: 'family-name',
          },
        },
        {
          label: 'user.function',
          required: true,
          input: {
            type: 'text',
            name: 'role',
            id: 'function',
            placeholder: 'user.function',
          },
        },
        {
          label: 'user.membership',
          required: true,
          comment: 'signup_page_cant_find_my_membership_help_text',
          input: {
            type: 'select',
            name: 'membership',
            id: 'membership',
            placeholder: 'user.membership',
            options: membershipStore.getMembershipsOpt(),
          },
        },
      ],
    },
  ]);
  const passwordStrength = ref(0);
  const loginErrors = ref([]);
  const singupErrorsTranslations = {
    emailError: 'auth.emailError',
    confirmEmail: 'auth.confirmEmailError',
    confirmPassword: 'auth.confirmPasswordError',
    alreadyUsedEmail: 'auth.emailAlreadyUsed',
    domainNotMembership: 'auth.domainNotMembership',
  };
  const gdprAccepted = ref(false);

  const errorFields = computed(() => {
    return loginErrors.value.map((error) => error.path);
  });
  const errorMessages = computed(() => {
    // return only wanted error messages
    return loginErrors.value.filter((error) => error.message !== undefined).map((e) => e.message);
  });

  async function signup(data) {
    const parsedData = signupSchema.cast({
      ...data,
    });
    try {
      loginErrors.value = [];
      if (passwordStrength.value < 3) {
        loginErrors.value.push({
          message: 'auth.passwordStrengthError',
          path: 'password',
        });
      }
      await signupSchema.validate(parsedData, { abortEarly: false });
    } catch (errors) {
      loginErrors.value = errors.inner.map((e) => {
        return {
          path: e.path,
          message: singupErrorsTranslations[e.message],
        };
      });

      scrollToBottom();
      return;
    }

    try {
      if (loginErrors.value.length > 0) return;
      await authStore.signup(toRaw(parsedData), localePath('/verify'));
    } catch (error) {
      loginErrors.value.push({
        message: singupErrorsTranslations[error.message],
        path: error.path,
      });
      scrollToBottom();
    }
  }

  const scrollToBottom = () => {
    // Scroll to bottom of page
    const bottom = document.body.scrollHeight;
    window.scroll({
      top: bottom,
      left: 0,
      behavior: 'smooth',
    });
  };

  useHead({
    title: t('signup_page_title'),
    meta: [
      {
        name: 'description',
        content: t('signup_page_title'),
      },
    ],
  });
</script>

<template>
  <main class="mx-auto max-w-7xl px-4 lg:px-8 py-8">
    <div class="pb-6 mb-6 border-b">
      <h1 class="text-3xl font-medium text-gray-900">
        {{ $t('signup_page_title') }}
      </h1>
      <p class="mt-2 text-gray-600">
        {{ $t('signup_page_description') }}
      </p>
    </div>
    <form @submit.prevent novalidate>
      <div class="sm:divide-y sm:divide-gray-200">
        <FormStep
          v-for="(step, index) in steps"
          @changePassword="passwordStrength = $event"
          :key="step.title"
          :data="userData"
          :step="step"
          :errorFields="errorFields"
          class="sm:pb-6 sm:border-b sm:border-gray-200"
          :class="{
            'pt-6': index > 0,
          }"
        />
        <div class="md:grid md:grid-cols-3 md:gap-4">
          <div class="md:col-start-2 md:col-span-2">
            <div class="flex justify-between items-center pt-6">
              <div class="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="gdpr"
                  name="gdpr"
                  class="h-4 w-4 rounded border-gray-300 text-sprb-600 focus:ring-sprb-700"
                  @change="gdprAccepted = !gdprAccepted"
                />
                <label for="gdpr" class="text-gray-600" v-html="$t('auth.termsAknowledgement')"> </label>
              </div>
              <button
                type="submit"
                class="btn"
                :class="gdprAccepted ? 'btn-primary' : 'btn-disabled'"
                :disabled="!gdprAccepted"
                @click="signup(toRaw(userData))"
              >
                {{ $t('common.actions.signup') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <Transition name="alert">
      <AlertBox class="mt-3" v-if="errorMessages.length > 0" :errors="errorMessages" />
    </Transition>
  </main>
</template>
