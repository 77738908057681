<script setup>
  import { computed } from 'vue';

  const props = defineProps({
    panels: {
      type: Array,
      required: true,
    },
    currentPanel: {
      type: Number,
      default: 0,
    },
  });

  const emit = defineEmits(['changePanel']);

  const internalCurrentPanel = computed({
    get: () => props.currentPanel,
    set: (value) => emit('changePanel', value),
  });
</script>

<template>
  <nav aria-label="Sidebar">
    <div class="space-y-1 mb-4">
      <!-- Current: "bg-gray-200 text-gray-900", Default: "text-gray-700 hover:bg-gray-50" -->
      <div
        v-for="(panel, index) in panels"
        :key="panel._id"
        @click="
          {
            internalCurrentPanel = index;
          }
        "
        :class="
          index === internalCurrentPanel ? 'bg-gray-200' : 'hover:bg-gray-50'
        "
        class="group flex items-center px-3 py-2 font-medium rounded-md cursor-pointer"
      >
        <span class="mr-4" style="min-width: 30px; min-height: 30px">
          <fontawesome-icon
            v-if="panel.slug === 'general-information'"
            :icon="['fas', 'circle-info']"
            class="fa-xl text-sprb-700"
            style="margin-left: 3px; margin-top: 4px"
          />
          <svg
            v-if="panel.slug === 'gender'"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 400 400"
          >
            <defs>
              <linearGradient
                id="genrea"
                x1="11.28"
                y1="313.56"
                x2="310.89"
                y2="87.78"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".1" stop-color="#79b72b"></stop>
                <stop offset=".34" stop-color="#49972d"></stop>
                <stop offset=".62" stop-color="#147530"></stop>
                <stop offset=".76" stop-color="#006831"></stop>
              </linearGradient>
              <linearGradient
                id="genreb"
                x1="12.5"
                y1="314.85"
                x2="340.06"
                y2="147.95"
                xlink:href="#genrea"
              ></linearGradient>
            </defs>
            <g data-name="genre">
              <path
                d="M315.24 102.72c-.91 0-1.82-.08-2.75-.08a80 80 0 102.75.08z"
                style="mix-blend-mode: multiply"
                fill="#33b4ac"
              ></path>
              <path
                d="M315.24 102.72c-7.44-25.15-23.48-42.66-43.9-46.7-59.47-11.75-82.86 33.4-117.63 59-33.93 25-67.63 33.66-88.11 34.56-29.23 1.28-59.73 12.16-56.75 39.68 3.21 29.57 29.23 27.94 44.87 22.39 7-2.48 25-16.49 34.92-3.4S87.08 237 67.73 242.38s-70.64 18.84-59.35 62.31c15.35 59.17 157.91 56.09 249.65-36.5q8.58-8.65 15.83-17.48c44.03-53.36 52.75-109.59 41.38-147.99z"
                fill="url(#genrea)"
              ></path>
              <path
                class="no-stroke"
                d="M315.24 102.72c-.91 0-1.82-.08-2.75-.08a79.08 79.08 0 00-38.63 148.07c44.03-53.36 52.75-109.59 41.38-147.99z"
                fill="url(#genreb)"
              ></path>
              <path
                class="no-stroke"
                d="M315.24 102.72c-.91 0-1.82-.08-2.75-.08a79.08 79.08 0 00-38.63 148.07c44.03-53.36 52.75-109.59 41.38-147.99z"
                fill="#008783"
              ></path>
            </g>
          </svg>
          <svg
            v-if="panel.slug === 'handicap'"
            class="icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 400"
          >
            <defs>
              <linearGradient
                id="handicap-a"
                x1="26.57"
                y1="319.54"
                x2="374.25"
                y2="118.81"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="#00673b"></stop>
                <stop offset="1" stop-color="#324595"></stop>
              </linearGradient>
            </defs>
            <g data-name="handicap">
              <path
                class="draw-path"
                d="M16.63 302.32h301.62V21.82s-51-2.89-84.81 31.35C205.39 81.55 205.12 132 186.72 132 164 132 182 99.7 159.19 96.4s-37.46 36.54-46.61 62-8.5 47.53-25 47.53c-17.82 0-9.73-43.07-39.21-30-27.45 12.13-31.74 80.69-31.74 126.39z"
                fill="url(#handicap-a)"
              ></path>
              <path
                class="draw-path"
                d="M381.71 159.86l-12.64-46C242.36 137.79 140.89 233 107.91 356.25l43.89 20.43C178 265.74 268.44 179.69 381.71 159.86z"
                fill="#6b8bc5"
              ></path>
            </g>
          </svg>
          <svg
            v-if="panel.slug === 'ethnic-background'"
            class="icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 400"
          >
            <defs>
              <linearGradient
                id="racisme-a"
                x1="151.98"
                y1="352.93"
                x2="108.83"
                y2="108.19"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="#194451"></stop>
                <stop offset=".6" stop-color="#288caf"></stop>
                <stop offset="1" stop-color="#32bdef"></stop>
              </linearGradient>
            </defs>
            <g data-name="racisme">
              <path
                class="draw-path"
                d="M302.49 167.24a76.08 76.08 0 00-7.95-11.67c-18.77-22.87-88.24-108.4-100.2-122.91S174 6.21 151.4 6.21c-17.85 0-30.33 14.4-30.33 36V163.1c0 48 50.17 60.11 64.34 60.11h85.49c27.39 0 48.89-24.39 31.59-55.97z"
                fill="#f8b25f"
              ></path>
              <path
                class="draw-path"
                d="M121.07 113.69H64.93c-20.9 0-46.66 30.53-22.18 59 19.33 22.48 121.18 145.16 142.67 171.05h16.79c9.28 0 12.77-5 12.77-12.54v-93.9c0-16.85-29.56-14.09-29.56-14.09V160.9c0-30.06-11-47.21-49.32-47.21z"
                fill="url(#racisme-a)"
              ></path>
              <path
                class="draw-path"
                d="M326.48 167.24H185.41v186.24c0 21.2 13 36.15 35.23 38.51 26.54 2.82 43.21-20.26 53.05-33.73C277 353.8 351 240 358.2 228.11c16.8-27.66 4.3-60.87-31.72-60.87z"
                fill="#3a5ea8"
              ></path>
              <path
                class="draw-path"
                d="M185.41 223.21h85.49c27.39 0 48.89-24.39 31.59-56H185.41z"
                fill="#f18918"
              ></path>
            </g>
          </svg>
          <svg
            v-if="panel.slug === 'sexual-orientation'"
            data-name="lgbtiq+"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 400"
          >
            <defs>
              <linearGradient
                id="lgbtiqa"
                x1="22.05"
                y1="165.95"
                x2="258.84"
                y2="238.34"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".26" stop-color="#f1891f"></stop>
                <stop offset=".94" stop-color="#e73180"></stop>
              </linearGradient>
            </defs>
            <g data-name="new version">
              <path
                d="M174.1 26.55c-28.22-17.67-57.48-12.95-83.48 0a77 77 0 00-28.87 25.1C34 91.18 43.1 142 70.84 185.05S214 385 214 385V134.57c0-50.47-2.91-84.86-39.9-108.02z"
                fill="#f8b25f"
              ></path>
              <path
                d="M337.72 120.12c-24.38-20.68-56.25-13.7-86.33 16.63-27.68 27.9-49.89 70.85-55.91 82-12.57 23.31-19.09 53.23-16.3 77.58C184.62 343.78 214 385 214 385s86.22-115.54 112-152.53c29.09-41.63 41.85-86.8 11.72-112.35z"
                fill="#f5c100"
              ></path>
              <path
                d="M90.62 26.55a77 77 0 00-28.87 25.1C34 91.18 43.1 142 70.84 185.05S214 385 214 385s-1.49-54.11 0-85C223 113 4 139 90.62 26.55z"
                fill="url(#lgbtiqa)"
              ></path>
            </g>
          </svg>
          <svg
            v-if="panel.slug === 'social-background'"
            class="icon-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 400 400"
          >
            <g data-name="new version">
              <path
                class="draw-path"
                d="M206.17 165.68V255c22.43 0 47.25-3.76 68.52-14.53 39.31-19.9 52.19-43.4 52.19-94.28V20S208.06 53.51 206.17 165.68z"
                fill="#fae589"
              ></path>
              <path
                class="draw-path"
                d="M274.69 240.52c-9.57-43-34.07-64.67-68.52-74.84-20.17-6-44.38-8-69.28-8v77.78a221.69 221.69 0 005.19 49.58c25.44 111.05 136.77 92.82 136.77 92.82v-95.61a191.74 191.74 0 00-4.16-41.73z"
                fill="#aa4391"
              ></path>
              <path
                class="draw-path"
                d="M206.17 165.68v-9.1c0-69.26-59.75-121.7-134.32-121.7v131.33c0 40.47 16.77 94.74 70.23 118.86 17.3 7.81 38.42 12.48 64.09 12.48V165.68z"
                fill="#ef9fa1"
              ></path>
              <path
                class="draw-path"
                d="M206.17 165.68V255c22.43 0 47.25-3.76 68.52-14.53-9.57-42.9-34.07-64.62-68.52-74.79z"
                fill="#941b80"
              ></path>
              <path
                class="draw-path"
                d="M206.17 165.68c-20.17-6-44.38-8-69.28-8v77.78a221.69 221.69 0 005.19 49.58c17.3 7.81 38.42 12.48 64.09 12.48V165.68"
                fill="#d08489"
              ></path>
            </g>
          </svg>
          <fontawesome-icon
            v-if="panel.slug === 'additional-criteria'"
            :icon="['fas', 'circle-plus']"
            class="fa-xl text-sprb-700"
            style="margin-left: 3px"
          />
        </span>

        <div>
          <h6
            v-if="panel.slug !== 'general-information'"
            class="text-gray-600 text-xs truncate"
          >
            {{ $t('survey.panelQuestionsLabel') }}
          </h6>
          <h5 class="text-sprb-700 text-sm">
            {{ $t(`panels.${panel._id}.title`) }}
          </h5>
        </div>
      </div>
    </div>
  </nav>
</template>
