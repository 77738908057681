<script setup>
  import { ref } from 'vue';

  import { useUserStore } from '@/stores/user.store.js';
  import { useSurveyStore } from '@/stores/survey.store.js';
  import { useAdviceRequestStore } from '@/stores/adviceRequest.store.js';

  import SurveyTypes from '@/components/SurveyTypes.vue';
  import SurveyList from '@/components/SurveyList.vue';
  import AdviceList from '@/components/AdviceList.vue';
  import { useHead } from '@unhead/vue';

  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  const userStore = useUserStore();
  const surveyStore = useSurveyStore();
  const adviceRequestStore = useAdviceRequestStore();

  const userId = userStore.connectedUser.user._id;
  const SurveyListRef = ref(null);
  const surveysCount = ref(0);
  const surveyListPopulate = [
    {
      path: 'questionnaire',
      populate: ['project', 'panels'],
    },
    { path: 'advicesRequests' },
  ];
  const adviceListPopulate = ['survey', 'adviceFiles'];
  const surveyListSort = ref('updatedAt');
  const surveyListOrder = ref('desc');
  const surveysSessionStorageKey = 'my-tests-currentPage';
  const AdviceListRef = ref(null);
  const advicesRequestsCount = ref(0);
  const advicesSessionStorageKey = 'my-advices-currentPage';

  async function setStores() {
    await surveyStore.getCount({ user: userId });
    await adviceRequestStore.getCount({ user: userId });
  }

  function setCounts() {
    surveysCount.value = surveyStore.count;
    advicesRequestsCount.value = adviceRequestStore.count;
  }

  async function updateSurveys() {
    await SurveyListRef.value.loadData();
    setCounts();
  }

  async function updateAdvices() {
    if (!AdviceListRef.value) return;
    await AdviceListRef.value.loadData();
    setCounts();
  }

  await setStores();
  setCounts();

  function sortBy(field) {
    if (surveyListSort.value === field) {
      surveyListOrder.value = surveyListOrder.value === 'asc' ? 'desc' : 'asc';
    } else {
      surveyListSort.value = field;
      surveyListOrder.value = 'asc';
    }
  }
  useHead({
    title: t('my_tests'),
    meta: [
      {
        name: 'description',
        content: 'test.equal',
      },
    ],
  });
</script>

<template>
  <main class="mx-auto max-w-7xl px-4 lg:px-8 py-8" style="min-height: calc(100vh - 340px)">
    <!-- User's tests -->
    <div class="space-y-5 pb-10 mb-10 border-b border-gray-200">
      <div class="">
        <h1 class="text-3xl font-medium text-gray-900">
          {{ $t('my_tests') }}
        </h1>
        <p class="mt-2 text-gray-700 font-light text-lg">
          {{ surveysCount > 0 ? $t('tests_desc_if_not_empty') : $t('tests_desc_if_empty') }}
        </p>
      </div>
      <div v-if="surveysCount > 0" class="space-y-5">
        <SurveyList
          @sortBy="sortBy($event)"
          @delete="
            {
              updateAdvices();
              updateSurveys();
            }
          "
          ref="SurveyListRef"
          :filters="{ user: userId }"
          :populate="surveyListPopulate"
          :sort="surveyListSort"
          :order="surveyListOrder"
          :sessionStorageKey="surveysSessionStorageKey"
        />
      </div>
      <div v-else>
        <SurveyTypes />
      </div>
    </div>

    <!-- User's advice requests -->
    <div class="space-y-5 pb-10">
      <div class="">
        <h1 class="text-3xl font-medium text-gray-900">
          {{ $t('my_advices') }}
        </h1>
        <p class="mt-2 text-gray-700 font-light text-lg">
          {{ !advicesRequestsCount > 0 ? $t('advices_desc_if_empty') : $t('advices_desc_if_not_empty') }}
        </p>
      </div>
      <AdviceList
        v-if="advicesRequestsCount > 0"
        ref="AdviceListRef"
        :filters="{ user: userId }"
        :sessionStorageKey="advicesSessionStorageKey"
        :populate="adviceListPopulate"
      />
    </div>
  </main>
</template>
