import CoreService from './core.service';

class FaqService extends CoreService {
  constructor() {
    super({
      endpoint: 'faq',
    });
  }

  async findParentCategories({ filter, skip, limit, populate, sort, order }) {
    try {
      if (populate) {
        populate = JSON.stringify(populate);
      }
      const response = await this.axios.get(`${this.endpoint}/category/parents`, {
        params: { ...filter, skip, limit, populate, sort, order },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}

export default new FaqService();
