import CoreService from './core.service';

class QuestionnaireService extends CoreService {
  constructor() {
    super({
      endpoint: 'questionnaire',
    });
  }
}

export default new QuestionnaireService();
