<script>
  export default {
    name: 'UserPage',
  };
</script>

<script setup></script>

<template>
  <RouterView></RouterView>
</template>
