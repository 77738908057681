<script>
  export default {
    name: 'VerifyEmail',
  };
</script>

<script setup>
  import { ref, computed, onMounted } from 'vue';
  import router from '@/router';
  import useAuthStore from '@/stores/auth.store';
  import { useLocalePath } from 'vue-i18n-routing';

  const localPath = useLocalePath()
  const verifyErrors = ref([]);
  const verified = ref(false);
  const authStore = useAuthStore();
  const userId = router?.currentRoute?.value?.query?.user;
  const token = router?.currentRoute?.value?.query?.token;
  const emailSend = ref(false);
  const alreadyValidated = ref(false);
  const validated = computed(() => router?.currentRoute?.value?.query?.validated);
  const email = ref(0);

  onMounted(async () => {
    try {
      validated.value = router?.currentRoute?.value?.query?.validated;
      email.value = router?.currentRoute?.value?.query?.email;
      if (!validated.value) {
        router.push({
          path: localPath('/'),
        });
      }
      if (userId && token && validated.value == 0) {
        const result = await authStore.verify(userId, token, localPath('/verify'));
        console.log({ result });
        if (result) {
          verified.value = true;
        }
      }
    } catch (error) {
      console.log(error.message);
      if (error.message == 'User is already verified') {
        alreadyValidated.value = 1;
      }
      if (error.message == 'invalid_user') {
        authStore.logout(localPath('/'));
      }
      verifyErrors.value.push({ message: error.message });
    }
  });
  const errorMessages = computed(() => {
    return verifyErrors.value.map((error) => error.message);
  });

  async function goToTestList() {
    window.history.replaceState(null, '', '/user/tests');
    router.go(0);
  }
  async function goToHome() {
    window.history.replaceState(null, '', '/');
    router.go(0);
  }

  function resendMail(user) {
    const sended = authStore.resendEmailVerify(user);
    if (sended) {
      emailSend.value = true;
    }
    setTimeout(() => {
      emailSend.value = false;
    }, '5000');
  }
</script>

<template>
  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="my-8 sm:mx-auto sm:w-full sm:max-w-sm">
      <div
        class="border bg-green-100 border-green-400 text-green-700 px-4 py-3 rounded relative text-center"
        role="alert"
      >
        <form v-if="validated == 0 && !alreadyValidated" @submit.prevent class="space-y-6" novalidate>
          <div v-html="$t('auth.validateAccount')"></div>
          <button class="btn btn-primary" @click="resendMail(email)">
            {{ $t('auth.validateAccountSentNewMail') }}
          </button>
        </form>
        <form v-if="validated == 1 && !alreadyValidated" @submit.prevent class="space-y-6" novalidate>
          <div v-html="$t('auth.validatedAccount')"></div>
          <button class="btn btn-primary" @click="goToTestList()">
            {{ $t('common.actions.continue') }}
          </button>
        </form>
        <form v-if="alreadyValidated == 1" @submit.prevent class="space-y-6" novalidate>
          <div v-html="$t('auth.alreadyValidated')"></div>
          <button class="btn btn-primary" @click="goToHome()">
            {{ $t('common.actions.continue') }}
          </button>
        </form>
      </div>
      <Transition name="alert">
        <div
          v-if="emailSend"
          class="border bg-green-100 border-green-400 text-green-700 px-4 py-3 rounded relative text-center mt-3"
          role="alert"
        >
          <span class="block sm:inline">
            {{ $t('auth.validateAccountSentNewMailMessage') }}
          </span>
        </div>
      </Transition>
      <Transition name="alert">
        <AlertBox class="mt-3" v-if="errorMessages.length > 0" :errors="errorMessages" />
      </Transition>
    </div>
  </div>
</template>
