import { EncryptStorage } from 'encrypt-storage';
const encryptStorage = new EncryptStorage(import.meta.env.VITE_SECRET_KEY, {
  storageType: 'sessionStorage',
});

class TokenService {
  getLocalRefreshToken() {
    const user = encryptStorage.getItem('user');
    return user?.refreshToken;
  }

  getLocalAccessToken() {
    const user = encryptStorage.getItem('user');
    return user?.token;
  }

  updateLocalAccessToken(token) {
    const user = encryptStorage.getItem('user');
    user.accessToken = token;
    encryptStorage.setItem('user', JSON.stringify(user));
  }

  getUser() {
    return encryptStorage.getItem('user');
  }

  setUser(user) {
    encryptStorage.setItem('user', JSON.stringify(user));
  }

  removeUser() {
    encryptStorage.removeItem('user');
  }
}

export default new TokenService();
