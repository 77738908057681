import { defineStore } from 'pinia';
import { ref } from 'vue';

import faqService from '@/services/faq.service';

export const useFaqStore = defineStore('faq', () => {
  const questions = ref([]);
  const parentCategories = ref([]);

  async function find(filter = {}, params = {}) {
    try {
      const res = await faqService.find({ filter, ...params });
      if (res) {
        questions.value = res;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findParentCategories(filter = {}, params = {}) {
    try {
      const res = await faqService.findParentCategories({ filter, ...params });
      if (res) {
        parentCategories.value = res;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    questions,
    parentCategories,
    find,
    findParentCategories,
  };
});
