import { defineStore } from 'pinia';
import { ref } from 'vue';

import optionService from '@/services/option.service';

export const useOptionStore = defineStore('option', () => {
  const options = ref([]);
  const currentVersion = ref({});

  async function find(filter = {}, params = {}) {
    if (!sessionStorage.getItem('options')) { 
      try {
        const res = await optionService.find({ filter, ...params });
        if (res) {
          sessionStorage.setItem('options', JSON.stringify(res));
        }
      } catch (error) {
        console.error(error);
      }
    }
    options.value = JSON.parse(sessionStorage.getItem('options'));
    currentVersion.value = options.value.current_version;
  }

  return {
    options,
    currentVersion,
    find,
  };
});
