import CoreService from './core.service';

class SelectListService extends CoreService {
  constructor() {
    super({
      endpoint: 'questionnaire/select_list',
    });
  }
}

export default new SelectListService();
