<script setup>
  import { useI18n } from 'vue-i18n';
  import { useHead } from '@unhead/vue';

  import { useNewsStore } from '@/stores/news.store';
  import { mergeTranslations } from '@/translations/i18n';

  const newsStore = useNewsStore();
  const { t } = useI18n();

  await newsStore.find({}, { sort: 'createdAt', order: 'desc' });
  mergeTranslations(newsStore.news, 'news');

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const locale = 'fr-FR';

    return date.toLocaleString(locale, options);
  }

  useHead({
    title: t('meta.news.title'),
    meta: [
      {
        name: 'description',
        content: t('meta.news.description'),
      },
    ],
  });
</script>

<!-- eslint-disable max-len -->
<template>
  <!-- eslint-disable max-len -->
  <main class="mx-auto max-w-7xl px-4 lg:px-8 py-8" style="min-height: calc(100vh - 340px)">
    <header class="border-b pb-5 mb-5">
      <h1 class="text-3xl font-medium text-gray-900">{{ $t('news.title') }}</h1>
    </header>
    <div v-for="item in newsStore.news" :key="item._id">
      <div
        v-if="item.status === 'published'"
        class="bg-white mb-4 shadow shadow-sprb-700/30 rounded-md overflow-hidden"
      >
        <div class="px-6 py-4">
          <div class="border-b pb-2 mb-2">
            <h2 :id="item._id" class="text-sprb-500 font-medium text-xl uppercase">
              {{ $t(`news.${item._id}.title`) }}
            </h2>
            <span class="text-gray-500 text-sm">{{ formatDate(item.createdAt) }} </span>
          </div>
          <div
            v-html="$t(`news.${item._id}.content`)"
            class="prose max-w-none prose-h2:font-normal prose-h3:font-normal prose-h4:font-normal prose-a:text-sprb-700"
          ></div>
        </div>
      </div>
    </div>
  </main>
</template>
