<script setup>
  import '@/styles/sprb-footer.css';
  import { useLocalePath } from 'vue-i18n-routing';
  import LanguageSwitch from '@/components/LanguageSwitch.vue';

  const localePath = useLocalePath();

  const date = new Date();
  const year = date.getFullYear();
  const socials = [
    {
      name: 'twitter',
      icon: 'twitter',
      link: 'https://twitter.com/SPRB_GOB',
    },
    {
      name: 'facebook',
      icon: 'facebook-f',
      link: 'https://www.facebook.com/SPRB.GOB',
    },
    {
      name: 'linkedin',
      icon: 'linkedin-in',
      link: 'https://www.linkedin.com/company/service-public-regional-de-bruxelles/',
    },
    {
      name: 'youtube',
      icon: 'youtube',
      link: 'https://www.youtube.com/channel/UC0fFY-x4DpHh3_iAb0cVikQ/videos',
    },
  ];
  const legalInfo = [
    {
      translationKey: 'nav_footer_privacy',
      link: '/page/privacy-policy',
    },
    {
      translationKey: 'footer_nav_accessibility',
      link: '/page/accessibility',
    },
    {
      translationKey: 'footer_nav_ecoconception',
      link: '/page/ecoconception',
    },
    // { translationKey: 'footer_nav_plaintes', link: '#' },
    // { translationKey: 'footer_nav_cookies', link: '#' },
  ];
  const administrations = [
    '_mobility',
    '_connectit',
    '_international',
    '_finance',
    '_logement',
    '_synergie',
    '_pouvoirslocaux',
    '_economy',
  ];
</script>

<template>
  <footer>
    <div class="bg-sprb-700">
      <div class="max-w-7xl flex flex-col sm:flex-row justify-between gap-y-6 items-center m-auto p-8">
        <img v-if="$i18n.locale === 'fr'" src="@/assets/logo_sprb_white.svg" alt="Logo SPRB" width="400" height="225" />
        <img v-if="$i18n.locale === 'nl'" src="@/assets/logo_gob_white.svg" alt="Logo SPRB" width="400" height="225" />
        <ul class="flex gap-3">
          <li v-for="(social, index) in socials" :key="index">
            <a
              :href="social.link"
              :aria-label="`Se rendre sur le compte ${social.name} du SPRB`"
              target="_blank"
              rel="noopener"
            >
              <fontawesome-layers class="h-[38px] w-[38px]">
                <fontawesome-icon class="text-white w-full h-full" :icon="['fas', 'circle']"></fontawesome-icon>
                <fontawesome-icon
                  class="text-sprb-700 h-3/5"
                  :icon="['fab', social.icon]"
                  :alt="'logo ' + social.name"
                ></fontawesome-icon>
              </fontawesome-layers>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="bg-white">
      <div class="max-w-7xl flex justify-between items-center m-auto p-8">
        <div class="text-xs">
          <div class="flex gap-3 mb-4">
            <span>© {{ year }}, {{ $t('sprb') }}</span>
            <RouterLink v-for="(link, index) in legalInfo" :key="index" :to="localePath(link.link)">
              {{ $t(link.translationKey) }}
            </RouterLink>
            <div class="display-cookies-banner display-cookies-banner__footer">
              <a href="#">Cookies</a>
            </div>
          </div>
          <div class="space-x-2">
            <LanguageSwitch />
          </div>
        </div>
        <div>
          <img src="@/assets/logo_sprb_gob.svg" alt="Logo bilingue SPRB" width="300" height="169" />
        </div>
      </div>
    </div>
    <div class="h-2 grid grid-cols-8">
      <div v-for="(admin, index) in administrations" :key="index" :class="admin"></div>
    </div>
  </footer>
</template>
