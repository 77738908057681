import $axios from '../config/axios.js';

class TranslationService {
 
  /**
   * Get translations for content, from the api
   * @returns 
   */
  async getAppTranslations() {
    try {
      const res = await $axios.get('/translation/app');
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }

}

export default new TranslationService();
