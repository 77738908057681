<script>
  export default {
    name: 'HomePage',
  };
</script>

<script setup>
  import { computed } from 'vue';
  import { useLocalePath } from 'vue-i18n-routing';
  import { useI18n } from 'vue-i18n';
  import { useHead } from '@unhead/vue';
  import { usePageStore } from '@/stores/page.store.js';
  import { useUserStore } from '@/stores/user.store';
  import { mergeTranslations } from '@/translations/i18n';

  const localePath = useLocalePath();
  const pageStore = usePageStore();
  const { t } = useI18n();

  const isLogged = computed(() => useUserStore().loggedIn);

  await pageStore.find({ key: 'home' });
  mergeTranslations(pageStore.page, 'pages');
  const page = pageStore.page[0];

  useHead({
    title: t('pages.' + page._id + '.title'),
    meta: [
      {
        name: 'description',
        content: t('pages.' + page._id + '.title'),
      },
    ],
  });
</script>

<template>
  <main class="mx-auto max-w-7xl px-4 lg:px-8 py-8" style="min-height: calc(100vh - 340px)">
    <header class="border-b pb-5 mb-5">
      <h1 class="text-3xl font-medium text-gray-900">
        {{ $t('pages.' + page._id + '.title') }}
      </h1>
    </header>
    <div class="prose max-w-none prose-h2:font-normal prose-h3:font-normal prose-h4:font-normal prose-a:text-sprb-700">
      <div v-if="!isLogged" class="not-prose relative isolate overflow-hidden bg-sprb-700 rounded-lg shadow-lg">
        <div class="py-8 px-6">
          <div class="mx-auto max-w-2xl text-center">
            <h2 class="text-2xl font-light text-white">{{ $t('common.cta.title') }}</h2>
            <div class="mt-8 flex items-center justify-center gap-x-6">
              <RouterLink
                :to="localePath('/signup')"
                class="bg-sprb-50 h-10 box-border rounded-sm px-10 py-1.5 shadow-lg hover:bg-white flex items-center"
              >
                {{ $t('common.actions.signup	') }}
              </RouterLink>
              <RouterLink
                :to="localePath('/signin')"
                class="flex items-center bg-transparent h-10 box-border border-2 rounded-sm px-10 shadow-lg text-white hover:bg-white hover:text-sprb-700"
              >
                {{ $t('common.actions.connect') }}
              </RouterLink>
            </div>
          </div>
        </div>
        <svg
          viewBox="0 0 1024 1024"
          class="absolute top-0 left-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
          aria-hidden="true"
        >
          <circle cx="512" cy="512" r="512" fill="url(#8d958450-c69f-4251-94bc-4e091a323369)" fill-opacity="0.7" />
          <defs>
            <radialGradient id="8d958450-c69f-4251-94bc-4e091a323369">
              <stop stop-color="#FFF" />
              <stop offset="1" stop-color="#FFF" />
            </radialGradient>
          </defs>
        </svg>
      </div>
      <div v-html="$t('pages.' + page._id + '.content')"></div>
    </div>
  </main>
</template>
