<script setup>
  import { computed } from 'vue';
  import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
  import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';

  const props = defineProps({
    label: { type: String, default: null },
    options: { type: Array, required: true },
    placeholder: { type: String, default: 'Choose an option' },
    modelValue: { type: [String, Number, Array], required: true },
    multiple: { type: Boolean, default: false },
    error: { type: String, default: null },
    isDisabled: { type: Boolean, default: false },
  });

  const emit = defineEmits(['update:modelValue']);

  const optionLabel = computed(() => {
    return props.options
      .filter((option) => {
        if (Array.isArray(props.modelValue)) {
          return props.modelValue.includes(option.value);
        }

        return props.modelValue === option.value;
      })
      .map((option) => option.label)
      .join(', ');
  });
</script>

<!-- eslint-disable max-len -->
<template>
  <Listbox
    @update:modelValue="(value) => emit('update:modelValue', value)"
    :model-value="props.modelValue"
    :multiple="multiple"
    as="div"
  >
    <ListboxLabel v-if="label" class="block text-sm font-medium text-gray-700">
      {{ $t(label) }}
    </ListboxLabel>
    <div class="relative mt-1">
      <ListboxButton
        class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-sprb-700 focus:outline-none focus:ring-1 focus:ring-sprb-700 sm:text-sm"
        :class="{ 'cursor-not-allowed': isDisabled }"
        :disabled="isDisabled"
      >
        <span v-if="optionLabel" class="block truncate">
          {{ $t(optionLabel) }}
        </span>
        <span v-else class="text-gray-600">
          {{ $t(placeholder) }}
        </span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </ListboxButton>

      <Transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="option in options"
            :key="option.label"
            :value="option.value"
            v-slot="{ active, selected }"
            as="div"
          >
            <li
              :class="[
                active ? 'text-white bg-sprb-600' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-3 pr-9',
              ]"
            >
              <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                {{ $t(option.label) }}
              </span>

              <span
                v-if="selected"
                :class="[active ? 'text-white' : 'text-sprb-600', 'absolute inset-y-0 right-0 flex items-center pr-4']"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </Transition>
      <div v-if="error" class="text-xs text-red-400 mt-1">{{ error }}</div>
    </div>
  </Listbox>
</template>
