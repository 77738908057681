<script setup>
  import { computed } from 'vue';
  import { getCurrentLanguage } from '@/translations/i18n';
  import SurveyTypes from '@/components/SurveyTypes.vue';
  import { useHead } from '@unhead/vue';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const language = computed(() => getCurrentLanguage());

  useHead({
    title: t('new_test_page_title'),
    meta: [
      {
        name: 'description',
        content: t('new_test_page_title'),
      },
    ],
  });
</script>

<template>
  <main class="mx-auto max-w-7xl px-4 lg:px-8 py-8" style="min-height: calc(100vh - 340px)">
    <h1 class="text-3xl font-medium text-gray-900">
      {{ $t('new_test_page_title') }}
    </h1>
    <div class="mt-2 text-gray-700 text-lg" v-html="$t('new_test_page_description_1')"></div>
    <div class="mt-2 text-gray-700 text-lg" v-html="$t('new_test_page_description_2')"></div>
    <SurveyTypes class="py-6 border-y my-6" />

    <div class="flex flex-col md:flex-row gap-6">
      <!-- Video -->
      <div
        onclick="triggerVideoPlayer(event)"
        :data-video="language === 'fr' ? 'Psw0fB-hUJY' : 'yhf-weGJpH8'"
        style="position: relative"
      >
        <div id="ytplayer" style="position: absolute; width: 100%; height: 100%"></div>
        <img
          :src="language === 'fr' ? '/new-test-tuto-FR-video-screenshot.png' : '/new-test-tuto-NL-video-screenshot.png'"
        />
      </div>
      <div class="w-full md:w-1/2 text-gray-700 text-lg" v-html="$t('new_test_page_description_bottom')"></div>
    </div>
  </main>
</template>
