<script>
  export default {
    name: 'SinglePage',
  };
</script>

<script setup>
  import { computed, watch, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { usePageStore } from '@/stores/page.store.js';
  import { mergeTranslations } from '@/translations/i18n';
  import { useHead } from '@unhead/vue';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  const route = useRoute();
  const pageStore = usePageStore();

  const pageKey = computed(() => {
    return route.params.key;
  });

  watch(pageKey, async (newKey) => {
    await loadPageData(newKey);
  });

  await loadPageData(pageKey.value);

  async function loadPageData(key) {
    await pageStore.find({ key });
    mergeTranslations(pageStore.page, 'pages');
  }

  // Scroll to hash element
  // Dom does not exists at 1st load, we need to do it onMounted
  onMounted(() => {
    if (window.location.hash) {
      const scrollToElement = document.getElementById(window.location.hash.substring(1));
      if (scrollToElement) {
        const position = scrollToElement.getBoundingClientRect();
        window.scrollTo(0, position.top + window.scrollY - 20);
      }
    }
  });
  useHead({
    title: t('pages.' + pageStore.page[0]?._id + '.title'),
    meta: [
      {
        name: 'description',
        content: t('pages.' + pageStore.page[0]?._id + '.title'),
      },
    ],
  });
</script>

<template>
  <main class="mx-auto max-w-7xl px-4 lg:px-8 py-8" style="min-height: calc(100vh - 340px)">
    <header class="border-b pb-5 mb-5">
      <h1 class="text-3xl font-medium text-gray-900">
        {{ $t('pages.' + pageStore.page[0]?._id + '.title') }}
      </h1>
    </header>
    <div>
      <div
        class="prose max-w-none prose-a:text-sprb-700"
        v-html="pageStore.page[0].translations[$i18n.locale].content"
      ></div>
    </div>
  </main>
</template>
