import * as yup from 'yup';

const signupSchema = yup.object().shape({
  email: yup.string().required().email('emailError'),
  confirmEmail: yup
    .string()
    .required()
    .email('emailError')
    .oneOf([yup.ref('email')], 'confirmEmail'),
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password')], 'confirmPassword'),
  language: yup.string().required().oneOf(['fr', 'nl']),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  role: yup.string().required(),
  membership: yup.string().required(),
  // notification: yup.string(),
});

/**
 * Passing translation key in required() and emai()
 */
const loginSchema = yup.object().shape({
  email: yup.string().required('emailRequired').email('emailError'),
  password: yup.string().required('passwordRequired'),
});

export { signupSchema, loginSchema };
