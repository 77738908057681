import CoreService from './core.service';
import { getCurrentLanguage } from '@/translations/i18n';
import axios from 'axios';

class SurveyService extends CoreService {
  constructor() {
    super({
      endpoint: 'survey',
    });
  }

  async generatePdf(survey) {
    try {
      const date = new Date();
      const formatedDate = date.toISOString().split('T')[0];
      const siteTitle = {
        fr: "Test d'égalité des chances",
        nl: 'Gelijkekansentest',
      };
      const pdfUrl = import.meta.env.VITE_FRONTEND_URL + '/' + getCurrentLanguage() + '/test-pdf/' + survey._id + '?pdf=true';

      // If necessary CALL API STA - TEMPORARY PATCH
      // const axiosSta = axios.create({
      //   baseURL: 'https://testapista.equal.brussels',
      //   headers: {
      //     'Content-Type': 'application/pdf',
      //   }
      // });
      
      const response = await this.axios.get(`pdf/${survey._id}?language=${getCurrentLanguage()}`, {
        params: { pdfUrl: pdfUrl },
        responseType: 'blob',
        headers: {
          Accept: 'application/pdf',
        },
      });
      if (response) {
        const pdfName = `${formatedDate} - ${siteTitle[getCurrentLanguage()]} - ${
          survey?.questionnaire?.project?.translations?.[getCurrentLanguage()].name
        }`;
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(response.data);
        a.download = `${pdfName}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
      return response.data;
    } catch (error) {
      return { error: error };
    }
  }
}

export default new SurveyService();
