import { defineStore } from 'pinia';
import { ref } from 'vue';

import adviceRequestService from '@/services/adviceRequest.service';

export const useAdviceRequestStore = defineStore('adviceRequest', () => {
  const adviceRequest = ref({});
  const adviceRequests = ref([]);
  const count = ref(0);

  async function create(data, files = []) {
    try {
      const res = await adviceRequestService.create(data, files);
      return res;
    } catch (error) {
      console.error(error);
    }
  }

  async function find(filter = {}, params = {}) {
    try {
      const res = await adviceRequestService.find({ filter, ...params });
      if (res) {
        adviceRequests.value = res;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findById(id, populate) {
    try {
      const res = await adviceRequestService.findById(id, { populate });
      if (res) {
        adviceRequest.value = res;
      }
    } catch (error) {
      // Feed le notif store
      console.error(error);
    }
  }

  async function update(id, data, populate) {
    try {
      const res = await adviceRequestService.update(id, data);
      if (res) {
        await findById(res._id, populate);
      }
    } catch (error) {
      // Feed le notif store
      console.error(error);
    }
  }

  async function deleteOne(id) {
    try {
      await adviceRequestService.delete(id);
    } catch (error) {
      // Feed le notif store
      console.error(error);
    }
  }

  async function getCount(filter) {
    try {
      const res = await adviceRequestService.count({ filter });
      if (res >= 0) {
        count.value = res;
      }
    } catch (error) {
      console.error(error);
    }
  }

  return {
    count,
    adviceRequest,
    adviceRequests,
    create,
    find,
    update,
    deleteOne,
    getCount,
  };
});
