import CoreService from './core.service';

class OptionService extends CoreService {
  constructor() {
    super({
      endpoint: 'app_option',
    });
  }
}

export default new OptionService();
