<script>
  export default {
    name: 'ErrorPage',
  };
</script>

<template>
  <div class="flex flex-col items-center justify-center px-4 md:px-8 lg:px-24 py-8 rounded-lg">
    <p class="text-2xl md:text-3xl lg:text-4xl font-medium tracking-wider text-gray-300">404</p>
    <p class="text-1xl md:text-2xl lg:text-3xl font-medium tracking-wider text-gray-500 mt-4">
      {{ $t('page404.title') }}
    </p>
    <p class="text-gray-500 mt-4 pb-4 border-b-2 text-center">
      {{ $t('page404.message') }}
    </p>
    <a
      href="/"
      class="flex items-center space-x-2 btn btn-primary px-4 py-2 mt-6 rounded transition duration-150"
      title="Return Home"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
        <path
          fill-rule="evenodd"
          d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <span>{{ $t('nav_homepage') }}</span>
    </a>
  </div>
</template>
