import translationService from '@/services/translation.service.js';
import translations from '@/translations/index';
import { createI18n, useI18n } from 'vue-i18n';
import { nextTick, watch, ref } from 'vue';

const appLanguages = ['fr', 'nl'];
const defaultAppLanguage = 'fr';

const i18n = createI18n({
  legacy: false,
  locale: getStartingLocale(),
  messages: translations,
  warnHtmlMessage: false,
});

// Store local for browser refresh page
const locale = ref(i18n.global.locale);
watch(locale, () => {
  localStorage.setItem('user-locale-language', locale.value);
});

// Set Starting locale
function getStartingLocale() {
  const { userBrowserLocale } = getUserLocaleFromBrowser();
  // Locale from local storage after switch language
  if (appLanguages.includes(localStorage.getItem('user-locale-language'))) {
    return localStorage.getItem('user-locale-language');
  }
  // Locale from user browser
  if (appLanguages.includes(userBrowserLocale)) {
    return userBrowserLocale;
  }
  // Locale default
  return defaultAppLanguage;
}

function getUserLocaleFromBrowser() {
  const locale = window.navigator.language || window.navigator.userLanguage;
  return {
    locale: locale,
    userBrowserLocale: locale.split('-')[0],
  };
}

/**
 *
 * @param {String} locale
 * @return {any}
 */
async function loadTranslations() {
  // Merge translations/index.js with DB translations
  try {
    const appTranslations = await translationService.getAppTranslations();
    if (Object.keys(appTranslations).length > 0) {
      for (const translationKey in appTranslations.data) {
        if (translationKey) {
          for (const language in appTranslations.data[translationKey].content) {
            if (language) {
              const tKey = appTranslations.data[translationKey];
              translations[language][translationKey] = tKey.content[language];
            }
          }
        }
      }
    }
    // Merge each language
    for (const lg of appLanguages) {
      if (lg) {
        i18n.global.setLocaleMessage(lg, translations[lg]);
      }
    }
  } catch (e) {
    console.error(e);
  }
  return nextTick();
}

/**
 * Translation function for component, to be used in <script>
 * @param {*} data
 * @return {any}
 */
function _$t(data) {
  const { t } = useI18n();
  return t(data);
}

/**
 * Get translations from DB objects and merge it to i18n messages.
 * Then items translation can be accessed as any other translations
 * @param {*} items List of objects containing "translations" field
 * @param {*} itemsIdentifier Name of the index in the i18n translations messages object
 * Ie : items = [{
 *   _id: "63a2cfd9d78ac541e14ef42e",
 *   attribute_1 : "value 1"
 *   attribute_1 : "value 2",
 *   translations : {
 *     "fr" : {
 *       "key_1" : "value"
 *       "key_2" : "value"
 *     }
 *     "nl" : {
 *       "key_1" : "value"
 *       "key_2" : "value"
 *     }
 *   }
 * }]
 * Use it in template :
 * {{ $t('itemsIdentifier.' + item._id + '.key_1')}}
 */
function mergeTranslations(items, itemsIdentifier) {
  let itemTranslations;
  const returnedTranslations = {};

  for (const locale of appLanguages) {
    itemTranslations = {};
    for (const item of items) {
      itemTranslations[item._id] = item.translations[locale];
    }
    returnedTranslations[itemsIdentifier] = itemTranslations;
    i18n.global.mergeLocaleMessage(locale, returnedTranslations);
  }
}

function getCurrentLanguage() {
  return i18n.global.locale.value;
}

function setLocale(language) {
  if (appLanguages.includes(language)) {
    locale.value = language;
  }
}

export { i18n, loadTranslations, mergeTranslations, getCurrentLanguage, getStartingLocale, setLocale, _$t };
