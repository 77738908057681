import { defineStore } from 'pinia';
import { ref } from 'vue';

import versionService from '@/services/version.service';

export const useVersionStore = defineStore('version', () => {
  const currentVersion = ref({});

  async function getCurrentVersion() {
    try {
      const current = await versionService.findOne({ filter: { status: 'current' } });
      currentVersion.value = current;
    } catch (error) {
      console.error(error);
    }
  }

  return {
    getCurrentVersion,
    currentVersion,
  };
});
