import CoreService from './core.service';

class AdviceRequestService extends CoreService {
  constructor() {
    super({
      endpoint: 'advice',
    });
  }

  async create(data, files) {
    try {
      if (!files.length) {
        await this.axios.post(`${this.endpoint}`, data);
        return;
      }

      const filesArr = [...files];
      const formData = new FormData();

      // filesArr.forEach((file, index) => {
      //   formData.append('item-' + index, file);
      // });
      const infos = [];
      filesArr.forEach((element, i) => {
        infos[i] = {};
        infos[i]['name'] = element?.name;
        infos[i]['type'] = element?.type;
        infos[i]['size'] = element?.size;
      });
      formData.append('infos', JSON.stringify(infos));
      formData.append('data', JSON.stringify(data));
      filesArr.forEach((file, index) => {
        formData.append('item-' + index, file);
      });

      await this.axios.post(`${this.endpoint}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error(error);
    }
  }
}

export default new AdviceRequestService();
