/* eslint-disable camelcase */
import { defineStore } from 'pinia';
import { ref } from 'vue';

import SelectListService from '@/services/selectList.service.js';

export const useSelectListStore = defineStore('selectList', () => {
  const optionLists = ref([]);
  const selectList = ref({});
  const cabinets = ref({});
  const impacts = ref({});
  const regionalCompetencies = ref({});
  const organisms = ref({});
  const exceptions = ref({});

  async function find(filter = {}, params = {}) {
    try {
      // Current version is passed in the filter
      const res = await SelectListService.find({ filter, ...params });
      if (res) {
        cabinets.value = res.find((list) => list.slug === 'cabinet-de-tutelle');
        impacts.value = res.find((list) => list.slug === 'impact');
        regionalCompetencies.value = res.find(
          (list) => list.slug === 'regional-competences'
        );
        organisms.value = res.find((list) => list.slug === 'organisme');
        exceptions.value = res.find((list) => list.slug === 'exceptions');
        optionLists.value = res;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findById(id, populate) {
    try {
      const res = await SelectListService.findById(id, { populate });
      if (res) {
        selectList.value = res[0];
      }
    } catch (error) {
      // Feed le notif store
      console.error(error);
    }
  }

  return {
    optionLists,
    selectList,
    regionalCompetencies,
    exceptions,
    find,
    findById,
  };
});
