<script setup>
  import { computed, ref } from 'vue';
  import TopSignature from '@/components/pageLayer/TopSignature.vue';
  import TopNav from '@/components/pageLayer/TopNav.vue';
  import SiteMap from '@/components/SiteMap.vue';
  import SiteFooter from '@/components/pageLayer/SiteFooter.vue';
  import CoNotice from '@/components/pageLayer/CoNotice.vue';
  import { useUserStore } from '@/stores/user.store';
  import { useHead } from '@unhead/vue';

  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  const skipLinkDisplayed = ref(false);

  const isLogged = computed(() => useUserStore().loggedIn);

  const mainLoggedIn = [
    { to: '/', title: 'nav_homepage' },
    { to: '/user/tests', title: 'nav_my_tests' },
    { to: '/faq', title: 'nav_faq' },
  ];
  const mainLoggedOut = [
    { to: '/', title: 'nav_homepage' },
    { to: '/faq', title: 'nav_faq' },
  ];
  const secondaryLoggedIn = [
    { to: '/news', title: 'nav_news' },
    { to: '/page/legal', title: 'nav_legal_frame' },
    { to: '/page/ressources', title: 'nav_ressources' },
    { to: '/page/formations', title: 'nav_formations' },
    { to: '/user/profile', title: 'nav_profil' },
  ];
  const secondaryLoggedOut = [{ to: '/signin', title: 'nav_signin' }];
  useHead({
    titleTemplate: '%s - ' + t('header_title'),
    title: 'test.equal',
    meta: [
      {
        name: 'description',
        content: 'test.equal',
      },
    ],
  });

  function toggleSkipLink() {
    skipLinkDisplayed.value = !skipLinkDisplayed.value;
  }
</script>

<template>
  <a
    @focus="toggleSkipLink"
    @blur="toggleSkipLink"
    href="#main"
    class="absolute p-2 m-2 bg-sprb-700 text-white"
    :class="{
      'left-0': skipLinkDisplayed,
      'left-[-999em]': !skipLinkDisplayed,
    }"
  >
    {{ $t('common.actions.skipToMain') }}
  </a>
  <TopSignature />
  <CoNotice />
  <TopNav v-if="isLogged" :isLogged="isLogged" :main="mainLoggedIn" :secondary="secondaryLoggedIn" />
  <TopNav v-else :isLogged="isLogged" :main="mainLoggedOut" :secondary="secondaryLoggedOut" />
  <slot></slot>
  <SiteMap />
  <SiteFooter />
</template>
