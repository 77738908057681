import { defineStore } from 'pinia';
import { ref } from 'vue';

import membershipService from '@/services/membership.service';
import { mergeTranslations, getCurrentLanguage } from '@/translations/i18n';

export const useMembershipStore = defineStore('membership', () => {
  const memberships = ref([]);

  async function find(filter = {}, params = {}) {
    try {
      const res = await membershipService.find({ filter, ...params });
      if (res) {
        memberships.value = res;
      }
    } catch (error) {
      console.error(error);
    }
  }

  function getMembershipsOpt() {
    // Merge  translations
    memberships.value.forEach((membership) => {
      const membershipTranslation = {
        _id: membership._id,
        translations: {
          fr: membership.nameFr,
          nl: membership.nameNl,
        },
      };
      mergeTranslations([membershipTranslation], 'memberships');
      return true;
    });
    const language = getCurrentLanguage();
    return memberships.value
      .map((membership) => {
        return {
          label: `memberships.${membership._id}`,
          value: membership._id,
          name: {
            fr: membership.nameFr,
            nl: membership.nameNl,
          },
        };
      })
      .sort((a, b) => a.name[language].localeCompare(b.name[language]));
  }

  return {
    memberships,
    getMembershipsOpt,
    find,
  };
});
