<script setup>
  import { reactive, ref, onMounted, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import router from '@/router';
  import useAuthStore from '@/stores/auth.store';
  import FormField from '../components/FormField.vue';
  import AlertBox from '@/components/AlertBox.vue';
  import { setLocale } from '@/translations/i18n';
  import { useLocalePath } from 'vue-i18n-routing';

  const localePath = useLocalePath();
  const authStore = useAuthStore();
  const errors = ref([]);
  const step = ref(1);
  const userData = reactive({});
  const fields = ref({});
  const emailSend = ref(false);
  const resetOk = ref(false);
  const route = useRoute();
  const loading = ref(false);

  // Switch to url params language
  if (route?.query?.language) {
    setLocale(route.query.language);
  }

  const userId = ref(router?.currentRoute?.value?.query?.user);
  const token = ref(router?.currentRoute?.value?.query?.token);

  const errorsTranslations = {
    user_not_exists: 'auth.emailResetPassError',
    passwords_different: 'auth.passwordsDifferent',
    empty_password: 'auth.emptyNewPassword',
    invalid_token: 'auth.invalidToken',
  };

  onMounted(async () => {
    try {
      userId.value = router?.currentRoute?.value?.query?.user;
      token.value = router?.currentRoute?.value?.query?.token;

      if (userId.value && token.value) {
        step.value = 2;
      } else {
        step.value = 1;
      }

      if (step.value === 1) {
        fields.value = [
          {
            label: 'user.email',
            input: {
              type: 'email',
              id: 'email',
              name: 'email',
            },
          },
        ];
      } else if (step.value === 2) {
        fields.value = [
          {
            label: 'user.password',
            input: {
              type: 'password',
              id: 'password',
              name: 'password',
            },
          },
          {
            label: 'common.actions.confirmPassword',
            input: {
              type: 'password',
              id: 'confirm-password',
              name: 'confirmPassword',
            },
          },
        ];
      }
    } catch (error) {
      errors.value.push({ message: error.message });
    }
  });

  const errorMessages = computed(() => {
    return errors.value.map((error) => error.message);
  });

  async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function sendResetMail(email) {
    loading.value = true;
    try {
      errors.value = [];
      const sended = await authStore.sendResetPassEmail(email);
      if (sended) {
        emailSend.value = true;
      }
      setTimeout(() => {
        emailSend.value = false;
      }, '5000');
    } catch (e) {
      // errors.value.push({
      //   message: errorsTranslations[e.message],
      // });
    }
    await sleep(1000);
    loading.value = false;
  }

  async function resetPassword(userId, token) {
    try {
      errors.value = [];
      if (!userData.password) {
        errors.value.push({
          message: errorsTranslations['empty_password'],
        });
      } else if (userData.password !== userData.confirmPassword) {
        errors.value.push({
          message: errorsTranslations['passwords_different'],
        });
      } else {
        userData['user_id'] = userId;
        userData['token'] = token;
        const result = await authStore.resetPassword(userData);
        if (result) {
          resetOk.value = true;
        }
      }
    } catch (e) {
      errors.value.push({
        message: errorsTranslations[e.message],
      });
    }
  }

  async function gotToLogin() {
    await router.push({
      path: localePath('/signin'),
    });
  }
</script>

<template>
  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="my-8 sm:mx-auto sm:w-full sm:max-w-sm">
      <h1 class="text-center m-3 text-lg text-sprb-600">
        {{ $t('auth.forgottenPassword') }}
      </h1>
      <div
        v-if="resetOk"
        class="border bg-green-100 border-green-400 text-green-700 px-4 py-3 rounded relative text-center"
        role="alert"
      >
        <p>{{ $t('auth.changePasswordSuccess') }}</p>
        <button class="mt-3 btn btn-primary" @click="gotToLogin()">
          {{ $t('common.actions.connect') }}
        </button>
      </div>
      <div v-if="!resetOk" class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="space-y-6">
          <p class="text-gray-600">
            {{ $t('auth.forgottenPasswordMessage') }}
          </p>
          <FormField :fields="fields" :data="userData" />
          <div class="flex">
            <button
              v-if="step === 1"
              class="btn btn-primary w-full justify-center"
              @click="sendResetMail(userData.email)"
            >
              {{ $t('auth.sentPaswwordResetButton') }}
              <div v-if="loading">
                <fontawesome-icon :icon="['fas', 'circle-notch']" class="ml-3 animate-spin" />
              </div>
            </button>
            <button
              v-if="step === 2"
              class="btn btn-primary w-full justify-center"
              @click="resetPassword(userId, token)"
            >
              {{ $t('auth.generatePassword') }}
            </button>
          </div>
          <p class="text-sm text-gray-600">
            {{ $t('auth.resetPasswordDescription') }}
          </p>
        </div>
      </div>
      <Transition name="alert">
        <div
          v-if="emailSend"
          class="border bg-green-100 border-green-400 text-green-700 px-4 py-3 rounded relative text-center mt-3"
          role="alert"
        >
          <span class="block sm:inline">{{ $t('auth.resetPasswordMailSent') }}</span>
        </div>
      </Transition>
      <Transition name="alert">
        <AlertBox class="mt-3" v-if="errorMessages.length > 0" :errors="errorMessages" />
      </Transition>
    </div>
  </div>
</template>
