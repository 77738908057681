<script>
  export default {
    name: 'ProfilePage',
  };
</script>

<script async setup>
  import { ref, toRaw, watch } from 'vue';

  import { useUserStore } from '@/stores/user.store';
  import { useMembershipStore } from '@/stores/membership.store';
  import useAuthStore from '@/stores/auth.store';
  import FormStep from '../components/FormStep.vue';
  import ConfirmModal from '@/components/ConfirmModal.vue';
  import { setLocale } from '@/translations/i18n';
  import { useLocalePath } from 'vue-i18n-routing';
  import { useHead } from '@unhead/vue';
  import { useI18n } from 'vue-i18n';
  const localPath = useLocalePath();

  const authStore = useAuthStore();
  const userStore = useUserStore();
  const membershipStore = useMembershipStore();
  await membershipStore.find();
  const { t } = useI18n();

  const errors = ref([]);
  const emailSend = ref(false);
  const errorsTranslations = {
    empty_password: 'auth.emptyNewPassword',
  };
  const userData = ref(structuredClone(toRaw(userStore.connectedUser.user)));
  const validationModal = ref(false);
  const loading = ref(false);
  const disableResetPassBtn = ref(false);

  // // Set user browser language, and change it if he change language in form
  watch(
    () => userData.value.language,
    (language) => {
      setLocale(language);
    }
  );
  const steps = ref([
    {
      title: 'profile.labels.personalInfo',
      comment: '',
      fields: [
        {
          label: 'user.firstname',
          input: {
            type: 'text',
            name: 'firstName',
            id: 'first-name',
          },
        },
        {
          label: 'user.lastname',
          input: {
            type: 'text',
            name: 'lastName',
            id: 'last-name',
          },
        },
        {
          label: 'user.language',
          comment: 'user.languageFieldComment',
          input: {
            type: 'select',
            name: 'language',
            id: 'language',
            options: [
              {
                label: 'languages.french',
                value: 'fr',
              },
              {
                label: 'languages.dutch',
                value: 'nl',
              },
            ],
          },
        },
      ],
    },
    {
      title: 'profile.labels.professionalInfo',
      comment: '',
      fields: [
        {
          label: 'user.function',
          input: {
            type: 'text',
            name: 'role',
            id: 'function',
          },
        },
        {
          label: 'user.email',
          disabled: true,
          comment: 'user.cantChangeEmail',
          input: {
            type: 'email',
            name: 'email',
            id: 'email-address',
          },
        },
        {
          label: 'user.membership',
          input: {
            type: 'select',
            name: 'membership',
            id: 'membership',
            options: membershipStore.getMembershipsOpt(),
          },
        },
      ],
    },
  ]);

  async function update() {
    try {
      loading.value = true;
      await userStore.update(userData.value);
      await sleep(1000);
      loading.value = false;
    } catch (error) {
      console.log(error);
    }
  }

  async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function sendResetMail(email) {
    try {
      errors.value = [];
      const sended = await authStore.sendResetPassEmail(email);
      if (sended) {
        emailSend.value = true;
        disableResetPassBtn.value = true;
      }
    } catch (e) {
      errors.value.push({
        message: errorsTranslations[e.message],
      });
    }
  }

  async function deleteUser(event) {
    await userStore.deleteUser();
    validationModal.value = false;
    useAuthStore().logout(localPath('/'));
  }

  useHead({
    title: t('nav_profil'),
    meta: [
      {
        name: 'description',
        content: t('nav_profil'),
      },
    ],
  });
</script>

<template>
  <main class="mx-auto max-w-7xl px-4 lg:px-8 py-8" style="min-height: calc(100vh - 340px)">
    <header class="border-b pb-5 mb-5">
      <h1 class="text-3xl font-medium text-gray-900">{{ $t('nav_profil') }}</h1>
    </header>
    <form>
      <FormStep
        v-for="(step, index) in steps"
        :key="step.title"
        :data="userData"
        :step="step"
        class="sm:pb-6 sm:border-b sm:border-gray-200"
        :class="{
          'pt-6': index > 0,
        }"
      />
      <div class="py-6 sm:border-b sm:border-gray-200 md:grid md:grid-cols-3 md:gap-4">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              {{ $t('profile.labels.account') }}
            </h3>
          </div>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <div class="overflow-hidden shadow sm:rounded-md">
            <div class="bg-white p-4">
              <div class="flex justify-between">
                <button
                  @click.prevent="validationModal = true"
                  class="inline-flex items-center rounded-sm border px-4 py-2 text-sm font-medium bg-white text-red-700 border-red-700 hover:border-red-200 hover:bg-red-100"
                >
                  {{ $t('delete_account_button') }}
                </button>
                <div>
                  <button
                    @click.prevent="sendResetMail(userData.email)"
                    class="btn btn-secondary cursor-pointer"
                    :class="{ 'btn-disabled': disableResetPassBtn }"
                    :disabled="disableResetPassBtn"
                  >
                    {{ $t('common.actions.changePwd') }}
                  </button>
                  <Transition name="alert">
                    <div
                      v-if="emailSend"
                      class="border bg-green-100 border-green-400 text-green-700 px-4 py-3 rounded relative text-center mt-3"
                      role="alert"
                    >
                      <span class="block sm:inline">{{ $t('auth.resetPasswordEmailSent') }}</span>
                    </div>
                  </Transition>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6 flex justify-end">
        <button @click.prevent="update" class="btn btn-primary">
          <div v-if="!loading">
            {{ $t('common.actions.confirm') }}
          </div>
          <div v-else-if="loading">
            <fontawesome-icon :icon="['fas', 'circle-notch']" class="animate-spin" />
            {{ $t('common.actions.loading') }}
          </div>
        </button>
      </div>
    </form>
  </main>
  <Teleport to="body">
    <Transition name="modal-fade">
      <div v-if="validationModal">
        <ConfirmModal
          @confirm="deleteUser"
          @close="validationModal = false"
          :title="$t('delete_account_modal_title')"
          :content="$t('delete_account_modal_content')"
          :confirmBtnText="$t('delete_account_modal_button')"
          confirmBtnColor="red"
        />
      </div>
    </Transition>
  </Teleport>
</template>
