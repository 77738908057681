import $axios from '../config/axios.js';

class AuthService {
  async signup(data) {
    try {
      const res = await $axios.post('/user/signup', data);
      if (!res.data.token) return false;
      return res.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }

  async login({ login, password }) {
    try {
      const res = await $axios.post('/user/login', {
        login,
        password,
      });
      if (!res.data.token) return false;
      return res.data;
    } catch (error) {
      let message;
      switch (error.response.data) {
        case 'invalid_login':
          message = 'wrongLogin';
          break;
        case 'invalid_token' || 'invalid_refresh_token':
          message = 'invalidRefreshToken';
          break;
      }
      throw new Error(message);
    }
  }

  async verify(userId, token) {
    try {
      const res = await $axios.get('/user/verify/' + userId + '/' + token);
      if (!res.data) {
        return false;
      }
      return res.data;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }

  async resendEmailVerify(email) {
    try {
      const res = await $axios.get('/user/resend-verify/' + email);
      return res;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }

  async sendResetPassEmail(email) {
    try {
      const res = await $axios.get('/user/reset-password/' + email);
      return res;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }

  async resetPassword(data) {
    try {
      const res = await $axios.post('/user/reset-password', { data });
      return res;
    } catch (error) {
      throw new Error(error.response.data);
    }
  }
}

export default new AuthService();
