import CoreService from './core.service';

class VersionService extends CoreService {
  constructor() {
    super({
      endpoint: 'version',
    });
  }
}

export default new VersionService();
