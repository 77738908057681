<script setup>
  import { ref, toRaw } from 'vue';
  import { useUserStore } from '@/stores/user.store.js';
  import { useAdviceRequestStore } from '@/stores/adviceRequest.store';
  import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
  import { XMarkIcon } from '@heroicons/vue/24/outline';

  import { useSurveyStore } from '@/stores/survey.store';

  import AlertBox from '@/components/AlertBox.vue';

  const props = defineProps({
    origin: {
      type: Object,
      required: true,
    },
  });

  defineEmits(['confirm', 'close']);

  const open = ref(true);
  const submitButtonRef = ref(null);

  const surveyStore = useSurveyStore();
  const userStore = useUserStore();
  const adviceRequestStore = useAdviceRequestStore();

  const userLanguage = userStore.connectedUser.user.language;
  const userId = userStore.connectedUser.user._id;
  const surveyId = surveyStore.survey._id;

  const adviceReq = ref({
    translations: {
      fr: {
        name: props.origin.panel.translations.fr.title + ' - ' + props.origin.question.translations.fr.name,
        shortName:
          props.origin.panel.translations.fr.title +
          ' - ' +
          props.origin.question.translations.fr.name.substring(
            0,
            props.origin.question.translations.fr.name.indexOf(' ')
          ),
      },
      nl: {
        name: props.origin.panel.translations.nl.title + ' - ' + props.origin.question.translations.nl.name,
        shortName:
          props.origin.panel.translations.nl.title +
          ' - ' +
          props.origin.question.translations.fr.name.substring(
            0,
            props.origin.question.translations.nl.name.indexOf(' ')
          ),
      },
    },
    content: '',
    survey: surveyId,
    question: props.origin.question._id,
    panel: props.origin.panel._id,
    user: userId,
    userLanguage: userLanguage,
  });

  const filesToUpload = ref([]);
  const maxFiles = 5;
  const draggedOver = ref(false);
  const errors = ref([]);
  const allowedFileTypes = [
    { mime: 'application/pdf', extension: 'PDF' },
    {
      mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      extension: 'DOCX',
    },
    { mime: 'application/msword', extension: 'DOC' },
    { mime: 'image/jpeg', extension: 'JPG' },
    { mime: 'image/png', extension: 'PNG' },
    { mime: 'application/vnd.ms-powerpoint', extension: 'PPT' },
    { mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', extension: 'PPTX' },
  ];

  // Concatenate all mime types
  const allowedMimeTypes = (function () {
    const mimeTypes = [];
    for (const fileType of allowedFileTypes) {
      mimeTypes.push(fileType.mime);
    }
    return mimeTypes.join(', ');
  })();

  function uploadFile(files, toValidate = true) {
    errors.value = [];

    for (const file of files) {
      if (filesToUpload.value.length >= maxFiles) {
        errors.value.push('advice.wrongMaxFiles');
        break;
      }
      if (toValidate) {
        const valid = validate(file);
        if (!valid) continue;
      }
      const fileExists = filesToUpload.value.some((f) => f.name === file.name);
      if (!fileExists) {
        filesToUpload.value.push(file);
      }
    }
  }

  function validate(file) {
    if (!file) return false;

    const allowedType = (function () {
      for (const type of allowedFileTypes) {
        if (type.mime === file.type) return true;
      }
      return false;
    })();
    const maxFileSize = 100000000; // 100MB
    const allowedSize = file.size <= maxFileSize;
    if (!allowedType) errors.value.push('advice.wrongFileType');
    if (!allowedSize) errors.value.push('advice.wrongFileSize');
    return allowedType && allowedSize;
  }

  function removeElement(array, element) {
    const index = array.indexOf(element);
    array.splice(index, 1);
  }

  function getAllowedExtensions() {
    const allowedExtensions = [];
    for (const fileType of allowedFileTypes) {
      allowedExtensions.push(fileType.extension);
    }
    return allowedExtensions.join(', ');
  }

  async function post(data) {
    await adviceRequestStore.create(data, toRaw(filesToUpload.value));
    await surveyStore.populatedFindById(surveyId);
  }
</script>

<template>
  <!-- eslint-disable max-len -->
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="relative z-10"
      :initialFocus="submitButtonRef"
      @close="
        open = false;
        $emit('close');
      "
    >
      <div class="fixed inset-0 bg-sprb-900/75" aria-hidden="true" />
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl"
            >
              <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button
                  type="button"
                  class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sprb-700 focus:ring-offset-2"
                  @click="
                    open = false;
                    $emit('close');
                  "
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10 bg-sprb-700"
                >
                  <fontawesome-icon
                    :icon="['fas', 'question']"
                    class="h-5 w-5 text-white"
                    transform="grow-4"
                    aria-hidden="true"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                    {{ $t('survey.adviceRequest') }}
                  </DialogTitle>
                  <div class="mt-2">
                    <form class="flex flex-col items-end gap-4 z-20" @submit.prevent>
                      <div class="w-full">
                        <label class="block text-sm text-gray-800 mb-2" for="adviceRequest">
                          <b>{{ $t('survey.label') }}</b> {{ surveyStore.survey.title }}
                          <br />
                          <b>{{ $t('survey.questionLabel') }}</b>
                          {{
                            ` ${$t('panels.' + origin.panel._id + '.title')} - ${$t(
                              'questions.' + origin.question._id + '.name'
                            )}`
                          }}</label
                        >
                        <textarea
                          v-model="adviceReq.content"
                          class="form-input"
                          name="adviceRequest"
                          id="adviceRequest"
                          cols="30"
                          rows="10"
                        ></textarea>
                        <div
                          @dragenter="draggedOver = true"
                          @dragleave="draggedOver = false"
                          @dragover.prevent
                          @drop.prevent="
                            uploadFile($event.dataTransfer.files);
                            draggedOver = false;
                          "
                          class="mt-4 px-2 py-4 border-2 border-dashed border-gray-300 rounded bg-gray-50 flex flex-col gap-2 items-center justify-center transition-transform"
                          :class="{
                            'scale-105': draggedOver,
                          }"
                        >
                          <Transition name="shrink-to-top">
                            <fontawesome-icon
                              v-if="filesToUpload.length === 0"
                              :icon="['fas', 'file-arrow-up']"
                              class="fa-fw text-sprb-400 text-2xl"
                            />
                          </Transition>
                          <div class="text-gray-800 text-center">
                            {{ `${$t('common.actions.dragNDrop')} ${$t('common.conjunctions.or')}` }}
                            <label class="text-sprb-400 cursor-pointer underline">
                              {{ $t('common.actions.browse').toLowerCase() }}
                              <input
                                @change="uploadFile($event.target.files, false)"
                                type="file"
                                :accept="allowedMimeTypes"
                                multiple
                                class="sr-only"
                              />
                            </label>
                            <span class="block text-center text-xs text-gray-500">
                              {{ `${$t('survey.acceptedFile', allowedFileTypes.length)} : ${getAllowedExtensions()}` }}
                              <br />
                              {{ $t('advice.maxFiles') }} {{ $t('advice.maxSize') }}
                            </span>
                            <ul
                              :class="{
                                'mt-2': filesToUpload.length > 0,
                              }"
                            >
                              <TransitionGroup name="slide-from-left">
                                <li v-for="(file, index) in filesToUpload" :key="index" class="group text-gray-500">
                                  <fontawesome-icon
                                    :icon="['fas', 'circle-minus']"
                                    class="hidden opacity-0 group-hover:inline group-hover:opacity-100 transition-opacity fa-fw text-gray-500 cursor-pointer"
                                    @click="removeElement(filesToUpload, file)"
                                  />
                                  {{ file.name.split('.')[0] }}
                                </li>
                              </TransitionGroup>
                            </ul>
                          </div>
                        </div>
                        <Transition name="alert">
                          <AlertBox v-if="errors.length > 0" :errors="errors" />
                        </Transition>
                      </div>
                    </form>
                    <div class="mt-2">
                      <span class="text-sm text-gray-600">
                        {{ $t('test_ask_advice_desc') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-4 flex justify-end">
                <button class="btn btn-secondary mr-3" @click="$emit('close')">
                  {{ $t('common.actions.cancel') }}
                </button>
                <button
                  class="btn btn-primary"
                  ref="submitButtonRef"
                  @click="
                    post(adviceReq);
                    $emit('close');
                  "
                  :disabled="!adviceReq.content"
                >
                  {{ $t('common.actions.send') }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
