// import $axios from '../config/axios.js';
import CoreService from './core.service';

class UserService extends CoreService {
  constructor() {
    super({
      endpoint: 'user',
    });
  }
}

export default new UserService();
