import $axios from '../config/axios.js';
import TokenService from './token.service';
import router from '../router.js';

const setup = () => {
  $axios.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  $axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const path = router?.currentRoute?.value?.path;
      // get language from url : check if url contain /fr/ or /nl/
      const language = path.includes('/fr/') ? '/fr' : '/nl';
      if (err?.response?.status === 401 && path !== '/fr/signin' && path !== '/nl/signup') {
        TokenService.removeUser();
        window.history.replaceState(null, '', language + '/signin');
        router.go(0);
      }

      // const originalConfig = err.config;

      // if (originalConfig.url !== '/auth/signin' && err.response) {
      //   // Access Token was expired
      //   if (err.response.status === 401 && !originalConfig._retry) {
      //     originalConfig._retry = true;

      //     try {
      //       const rs = await api.post('/auth/refreshtoken', {
      //         refreshToken: TokenService.getLocalRefreshToken(),
      //       });

      //       const { accessToken } = rs.data;

      //       store.dispatch('auth/refreshToken', accessToken);
      //       TokenService.updateLocalAccessToken(accessToken);

      //       return axiosInstance(originalConfig);
      //     } catch (_error) {
      //       return Promise.reject(_error);
      //     }
      //   }
      // }

      return Promise.reject(err);
    }
  );
};

export default setup;
