<script setup>
  import { ref } from 'vue';
  import { useLocalePath } from 'vue-i18n-routing';
  import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
  import { XMarkIcon } from '@heroicons/vue/24/outline';

  const localePath = useLocalePath();

  defineProps({
    advice: {
      type: Object,
      required: true,
    },
    linkToSurvey: {
      type: Boolean,
      required: false,
      default: false,
    },
  });

  defineEmits(['confirm', 'close']);
  const open = ref(true);
  const submitButtonRef = ref(null);
  const apiUrl = import.meta.env.VITE_API_URL;
</script>

<template>
  <!-- eslint-disable max-len -->
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      class="relative z-10"
      :initialFocus="submitButtonRef"
      @close="
        open = false;
        $emit('close');
      "
    >
      <div class="fixed inset-0 bg-sprb-900/75" aria-hidden="true" />
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-6 py-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button
                  type="button"
                  class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-sprb-700 focus:ring-offset-2"
                  @click="
                    open = false;
                    $emit('close');
                  "
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div>
                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                  {{ $t('survey.adviceRequest') }}
                </DialogTitle>
                <p class="text-sm text-gray-500">
                  {{ $t('survey.advicepreview.received') }}
                  {{ $filters.formatDate(advice.createdAt) }}
                </p>
                <div class="mt-2">
                  <p class="text-sm text-gray-500 font-light italic mb-4">
                    {{ advice.translations[$i18n.locale].name }}
                  </p>
                  <p class="text-sm text-gray-900 font-medium border-b pb-1 mb-2">
                    {{ $t('survey.advicepreview.yourRequest') }}
                  </p>
                  <div class="text-sm text-gray-700 mb-2">{{ advice.content }}</div>

                  <template v-if="advice?.adviceFiles?.length">
                    <h3 class="text-sm text-gray-800 mb-1">{{ $t('survey.advicepreview.yourFiles') }}</h3>
                    <ul class="text-sm text-gray-600 bg-gray-50 p-3 mb-5 rounded-md space-y-1">
                      <li v-for="file in advice.adviceFiles" :key="file._id">
                        <a :href="`${apiUrl}/upload/${file.filename}`" class="underline">{{ file.filename }}</a>
                      </li>
                    </ul>
                  </template>
                </div>
              </div>
              <div class="mt-2 border-t pt-4">
                <div class="text-sm text-right" v-if="linkToSurvey && advice.survey">
                  <RouterLink
                    @click="$emit('close')"
                    ref="submitButtonRef"
                    :to="localePath(`/test/${advice.survey._id}`)"
                    class="bg-sprb-600 hover:bg-sprb-700 focus:ring-sprb-700 inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    {{ $t('survey.advicepreview.gotToSurveyLink') }}
                  </RouterLink>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
