<script>
  export default {
    name: 'SigninPage',
  };
</script>

<script setup>
  import { reactive, ref, computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useLocalePath } from 'vue-i18n-routing';
  import router from '@/router';
  import { useHead } from '@unhead/vue';

  import useAuthStore from '@/stores/auth.store';

  import { loginSchema } from '@/utilities/validation';
  import FormField from '@/components/FormField.vue';
  import AlertBox from '@/components/AlertBox.vue';
  import { useUserStore } from '@/stores/user.store';
  import { useI18n } from 'vue-i18n';

  const { t } = useI18n();
  const localePath = useLocalePath();
  const loggedIn = useUserStore().loggedIn;
  const authStore = useAuthStore();
  const urlQuery = useRoute().query;

  onMounted(async () => {
    if (loggedIn) {
      router.push({
        path: localePath('/user/tests'),
      });
    }
  });

  const queryError = ['auth.pleaseFirstConnect'];
  const loginErrors = ref([]);
  const userData = reactive({});
  const fields = reactive([
    {
      label: 'user.email',
      input: {
        type: 'email',
        id: 'email',
        name: 'email',
        autocomplete: 'email',
      },
    },
    {
      label: 'user.password',
      input: {
        type: 'password',
        id: 'password',
        name: 'password',
        autocomplete: 'current-password',
      },
    },
  ]);

  const errorFields = computed(() => {
    return loginErrors.value.map((error) => error.path);
  });
  const errorMessages = computed(() => {
    return loginErrors.value.map((error) => error.message);
  });

  const loginSchemaErrorMessage = {
    emailRequired: 'auth.emailRequired',
    emailError: 'auth.emailError',
    passwordRequired: 'auth.passwordRequired',
    wrongLogin: 'auth.wrongLogin',
    invalidRefreshToken: 'auth.invalidRefreshToken',
  };

  async function login(login, password) {
    loginErrors.value = [];

    const parsedLogin = loginSchema.cast({
      email: login,
      password: password,
    });
    // Validate login schema
    try {
      await loginSchema.validate(parsedLogin, { abortEarly: false });
    } catch (errors) {
      console.log(errors);
      for (const error of errors.inner) {
        loginErrors.value.push({
          path: error.path,
          message: loginSchemaErrorMessage[error.message],
        });
      }
      return;
    }
    // Auth login
    try {
      await authStore.login(parsedLogin.email, parsedLogin.password, localePath('/user/tests'), localePath('/verify'));
    } catch (error) {
      loginErrors.value.push({
        path: localePath(error.path),
        message: loginSchemaErrorMessage[error.message],
      });
      return;
    }
  }
  useHead({
    title: t('Login'),
    meta: [
      {
        name: 'description',
        content: t('Login'),
      },
    ],
  });
</script>

<template>
  <div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="my-8 sm:mx-auto sm:w-full sm:max-w-sm">
      <AlertBox v-if="Object.keys(urlQuery).length > 0" :errors="queryError" class="mb-2" />
      <div class="bg-white py-6 px-6 shadow-lg shadow-sprb-700/30 sm:rounded-lg">
        <form @submit.prevent novalidate>
          <div class="space-y-4">
            <FormField :fields="fields" :data="userData" :errorFields="errorFields" />
          </div>
          <div class="flex items-start justify-between mt-2">
            <div class="text-xs">
              <RouterLink :to="localePath('/reset-password')" class="font-medium text-sprb-700 hover:underline">
                {{ $t('auth.forgottenPassword') }}
              </RouterLink>
            </div>
          </div>
          <div class="flex mt-4">
            <button
              type="submit"
              class="btn btn-primary w-full justify-center"
              @click="login(userData.email, userData.password)"
            >
              {{ $t('common.actions.connect') }}
            </button>
          </div>
          <div class="">
            <div class="text-sm mt-2">
              <RouterLink :to="localePath('/signup')" class="font-medium text-sprb-700 hover:underline">
                {{ $t('auth.noAccount') }}
              </RouterLink>
            </div>
          </div>
        </form>
      </div>
      <Transition name="alert">
        <AlertBox v-if="errorMessages.length > 0" :errors="errorMessages" class="mt-6" />
      </Transition>
    </div>
  </div>
</template>
