// import { createRouter, createWebHistory } from 'vue-router';
import { createRouter as _createRouter } from 'vue-i18n-routing';
import { createWebHistory } from 'vue-router';
import { i18n } from './translations/i18n';

import FaqPage from '@/pages/Faq.vue';
import HomePage from '@/pages/Home.vue';
import NotFound from '@/pages/NotFound.vue';
import ProfilePage from '@/pages/Profile.vue';
import ResetPass from '@/pages/ResetPass.vue';
import Signin from '@/pages/Signin.vue';
import Signup from '@/pages/Signup.vue';
import SinglePage from '@/pages/SinglePage.vue';
import SurveyCreate from '@/pages/SurveyCreate.vue';
import SurveyIndex from '@/pages/SurveyIndex.vue';
import SurveyPdf from '@/pages/SurveyPdf.vue';
import SurveyPreview from '@/pages/SurveyPreview.vue';
import SurveySingle from '@/pages/SurveySingle.vue';
import User from '@/pages/User.vue';
import VerifyEmail from '@/pages/VerifyEmail.vue';
import NewsIndex from '@/pages/NewsIndex.vue';
import NewsSinglePage from '@/pages/NewsSingle.vue';

import { useUserStore } from '@/stores/user.store';
import useAuthStore from '@/stores/auth.store.js';

// console.log(window.navigator.language || window.navigator.userLanguage)

const router = _createRouter(i18n, {
  version: 4,
  defaultLocale: 'fr',
  strategy: 'prefix',
  baseUrl: import.meta.env.VITE_FRONTEND_URL,
  locales: [
    {
      code: 'fr',
      name: 'Français',
      iso: 'fr-BE',
    },
    {
      code: 'nl',
      name: 'Nederlands',
      iso: 'nl-BE',
    },
  ],
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      paths: {
        fr: '/accueil',
        nl: '/home',
      },
      component: HomePage,
      meta: {
        authRequired: false,
      },
    },
    {
      path: '/page/:key',
      name: 'single page',
      component: SinglePage,
      meta: {
        authRequired: false,
      },
    },
    {
      path: '/faq',
      name: 'faq',
      component: FaqPage,
      meta: {
        authRequired: false,
      },
    },
    {
      path: '/news',
      name: 'news',
      component: NewsIndex,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/news/:id',
      name: 'news-single',
      component: NewsSinglePage,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      meta: {
        authRequired: false,
      },
    },
    {
      path: '/signin',
      name: 'signin',
      component: Signin,
      meta: {
        authRequired: false,
      },
    },
    {
      path: '/verify',
      name: 'verify',
      component: VerifyEmail,
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPass,
      meta: {
        authRequired: false,
      },
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password-token',
      component: ResetPass,
      meta: {
        authRequired: false,
      },
      beforeEnter(to) {
        const token = to.params.token;
        // TODO check token
        if (token === '1234') {
          useAuthStore().resetPwdStep = 2;
        }
        return '/reset-password';
      },
    },
    {
      path: '/user',
      component: User,
      meta: {
        authRequired: true,
      },
      children: [
        {
          path: 'profile',
          name: 'profile',
          component: ProfilePage,
        },
        {
          path: 'tests',
          name: 'tests',
          component: SurveyIndex,
        },
      ],
    },
    {
      path: '/test/new',
      name: 'newTest',
      component: SurveyCreate,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/test/new/:id',
      name: 'newTestpage',
      component: SurveySingle,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/test/:id',
      name: 'singleTest',
      component: SurveySingle,
      meta: {
        authRequired: true,
      },
    },
    {
      path: '/test-preview/:id',
      name: 'testPreview',
      component: SurveyPreview,
      meta: {
        authRequired: false,
      },
    },
    {
      path: '/test-pdf/:id',
      name: 'testPdf',
      component: SurveyPdf,
      meta: {
        authRequired: false,
        layout: 'PdfViewerLayout',
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'error',
      component: NotFound,
    },
  ],
});

router.beforeEach(async (to, from) => {
  // Router starts its navigation afer router and pinia are installed
  const loggedIn = useUserStore().loggedIn;
  if (to.meta.authRequired && !loggedIn) {
    return {
      path: '/signin',
      query: {
        error: 'unauthorized',
        page: to.name,
      },
      test: 'ok',
    };
  }
  if (to.fullPath === '/') {
    let userLang = window.navigator.language || window.navigator.userLanguage;
    if (userLang != 'fr' && userLang != 'nl') {
      userLang = 'fr';
    }
    return {
      path: '/' + userLang,
    };
  }
  return true;
});

export default router;
