import $axios from '../config/axios.js';

class CoreService {
  constructor({ endpoint }) {
    this.endpoint = endpoint;
    this.axios = $axios;
  }

  async create(data) {
    try {
      const response = await this.axios.post(`${this.endpoint}`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async find({ filter, skip, limit, populate, sort, order }) {
    try {
      if (populate) {
        populate = JSON.stringify(populate);
      }
      const params = {
        params: { ...filter, skip, limit, populate, sort, order },
      };
      const response = await this.axios.get(`${this.endpoint}`, params);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async findOne({ filter, populate }) {
    try {
      if (populate) {
        populate = JSON.stringify(populate);
      }
      const response = await this.axios.get(`${this.endpoint}/findOne`, {
        params: { ...filter, populate },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async findById(id, { populate }) {
    try {
      if (populate) {
        populate = JSON.stringify(populate);
      }
      const response = await this.axios.get(`${this.endpoint}/${id}`, {
        params: { populate },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async count({ filter }) {
    try {
      const response = await this.axios.get(`${this.endpoint}/count`, {
        params: { ...filter },
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async update(id, data) {
    try {
      const response = await this.axios.put(`${this.endpoint}/${id}`, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async delete(id) {
    try {
      const response = await this.axios.delete(`${this.endpoint}/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}

export default CoreService;
