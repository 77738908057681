<script setup>
  import { computed, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import PasswordMeter from 'vue-simple-password-meter';

  import CustomSelect from '@/components/CustomSelect.vue';
  import CustomCheckbox from '@/components/CustomCheckbox.vue';

  const props = defineProps({
    fields: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    errorFields: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(['stopTyping', 'changePassword']);

  const data = computed(() => props.data);
  const errors = computed(() => props.errorFields);

  const isSignup = computed(() => {
    return useRoute().name.includes('signup');
  });

  const passwordIsShown = ref(false);
  const confirmPasswordIsShown = ref(false);

  let timeoutId;

  // TODO refactor both getSubOptions and setDependencyValue
  function getSubOptions(field) {
    const fieldName = field.input.name;
    const inputOptions = field.input.options;
    let index;
    if (data[fieldName] != undefined) {
      index = inputOptions.findIndex((opt) => opt.value === data[fieldName]);
    } else {
      index = 0;
    }

    return inputOptions[index].subOptions;
  }

  function setDependencyValue(dependency, value) {
    data.value[dependency] = {};
    data.value[dependency].type = value;
    data.value[dependency].value = null;
  }

  function resetError(field) {
    if (errors.value.length) {
      const index = errors.value.findIndex((error) => error === field);
      if (index === -1) return;
      errors.value.splice(index, 1);
    }
  }

  function updateArray(field, value) {
    if (data.value[field.input.name].includes(value)) {
      data.value[field.input.name].splice(data.value[field.input.name].indexOf(value), 1);
    } else {
      data.value[field.input.name].push(value);
    }
  }

  function debounceEmit(event, delay = 2000) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      emit(event);
    }, delay);
  }
</script>

<template>
  <div v-for="(field, index) in fields" :key="`${field.label}-${index}`" class="col-span-6 sm:col-span-3 mb-3">
    <label :for="field.input.id" class="block font-medium text-gray-700 mb-3">
      {{ `${$t(field.label)} ${field.required ? '*' : ''}` }}
    </label>
    <input
      v-if="field.input.type === 'text' || field.input.type === 'email'"
      @keyup="debounceEmit('stopTyping')"
      @beforeinput="resetError(field.input.name)"
      v-model="data[field.input.name]"
      :type="field.input.type"
      :name="field.input.name"
      :id="field.input.id"
      :placeholder="field.input.placeholder ? $t(field.input.placeholder) : ''"
      :autocomplete="field.input.autocomplete"
      class="form-input"
      :class="{
        'border border-red-500 bg-red-50': errors.includes(field.input.name),
        'bg-gray-100 cursor-not-allowed': field.disabled,
      }"
      :disabled="field.disabled || isDisabled"
    />
    <template v-else-if="field.input.name === 'password'">
      <div class="relative">
        <input
          @keyup="debounceEmit('stopTyping')"
          @beforeinput="resetError(field.input.name)"
          v-model="data[field.input.name]"
          :type="passwordIsShown ? 'text' : field.input.type"
          :name="field.input.name"
          :id="field.input.id"
          :placeholder="field.input.placeholder ? $t(field.input.placeholder) : ''"
          :autocomplete="field.input.autocomplete"
          class="form-input"
          :class="{
            'border border-red-500 bg-red-50': errors.includes(field.input.name),
            'bg-gray-100 cursor-not-allowed': field.disabled,
          }"
          :disabled="field.disabled || isDisabled"
        />
        <button
          @click="passwordIsShown = !passwordIsShown"
          type="button"
          class="absolute h-full top-0 right-0 px-3 flex items-center text-gray-500"
        >
          <fontawesome-icon :icon="passwordIsShown ? ['fas', 'eye-slash'] : ['fas', 'eye']" />
        </button>
      </div>
      <div v-if="isSignup" class="bg-gray-200 rounded">
        <PasswordMeter @score="$emit('changePassword', $event.score)" :password="data[field.input.name]" />
      </div>
    </template>
    <template v-else-if="field.input.name === 'confirmPassword'">
      <div class="relative">
        <input
          @keyup="debounceEmit('stopTyping')"
          @beforeinput="resetError(field.input.name)"
          v-model="data[field.input.name]"
          :type="confirmPasswordIsShown ? 'text' : field.input.type"
          :name="field.input.name"
          :id="field.input.id"
          :placeholder="field.input.placeholder ? $t(field.input.placeholder) : ''"
          :autocomplete="field.input.autocomplete"
          class="form-input"
          :class="{
            'border border-red-500 bg-red-50': errors.includes(field.input.name),
            'bg-gray-100 cursor-not-allowed': field.disabled,
          }"
          :disabled="field.disabled || isDisabled"
        />
        <button
          @click="confirmPasswordIsShown = !confirmPasswordIsShown"
          type="button"
          class="absolute h-full top-0 right-0 px-3 flex items-center text-gray-500"
        >
          <fontawesome-icon :icon="confirmPasswordIsShown ? ['fas', 'eye-slash'] : ['fas', 'eye']" />
        </button>
      </div>
    </template>
    <textarea
      v-else-if="field.input.type === 'textarea'"
      v-model.trim="data[field.input.name]"
      @keyup="debounceEmit('stopTyping')"
      :name="field.input.name"
      :id="field.input.id"
      class="form-input"
      rows="4"
      :class="{
        'bg-gray-100 cursor-not-allowed': isDisabled,
      }"
      :disabled="isDisabled"
    ></textarea>
    <select
      v-else-if="field.input.type === 'select'"
      @change="debounceEmit('stopTyping')"
      @focus="resetError(field.input.name)"
      v-model.lazy="data[field.input.name]"
      :name="field.input.name"
      :id="field.input.id"
      :autocomplete="field.input.autocomplete"
      class="form-input"
      :class="{
        'border border-red-500 bg-red-50': errors.includes(field.input.name),
      }"
      :disabled="field.disabled || isDisabled"
    >
      <option
        v-for="option in field.input.options"
        :key="option.value"
        :value="option.value"
        :selected="data[field.input.name] === option.value"
      >
        {{ $t(option.label) }}
      </option>
    </select>
    <CustomSelect
      @change="debounceEmit('stopTyping')"
      v-else-if="field.input.type === 'multiselect'"
      v-model="data[field.input.name]"
      :options="field.input.options"
      :multiple="true"
      :isDisabled="isDisabled"
      :placeholder="field.input.placeholder"
    />
    <fieldset v-else-if="field.input.type === 'radioSelect'" class="mt-4">
      <div class="flex justify-between sm:items-center mb-3">
        <div v-for="option in field.input.options" :key="option.value" class="flex items-center">
          <input
            @focusin="setDependencyValue(field.input.name, option.value)"
            :value="option.value"
            :id="option.value"
            :name="field.input.id"
            type="radio"
            class="h-4 w-4 border-gray-300 text-sprb-600 focus:ring-sprb-700"
          />
          <label :for="option.value" class="ml-2 block text-gray-700">
            {{ option.label }}
          </label>
        </div>
      </div>
      <select v-model="data[field.input.name]" :id="field.input.id" :name="field.input.name" class="form-input">
        <option :value="null">Choisir une option</option>
        <option v-for="subOption in getSubOptions(field)" :key="subOption.value" :value="subOption.value">
          {{ subOption.label }}
        </option>
      </select>
    </fieldset>
    <CustomCheckbox
      v-else-if="field.input.type === 'checkbox'"
      @update="updateArray(field, $event)"
      @change="debounceEmit('stopTyping')"
      :options="field.input.options"
      :checked="data[field.input.name]"
      :isDisabled="isDisabled"
      class="grid mt-1 gap-y-4"
    />
    <p v-if="field.comment" class="text-sm mt-2 text-gray-500" v-html="$t(field.comment)"></p>
  </div>
</template>
