<script setup></script>

<template>
  <div class="h-[40px] flex bg-white border-b border-gray-300">
    <div class="w-[60px] flex items-center justify-center border-r border-gray-300">
      <a :href="`https://equal.brussels/${$i18n.locale}/`" target="_blank" rel="noopener">
        <img src="@/assets/blue_iris.svg" width="39" height="24" alt="equal.brussels" />
      </a>
    </div>
    <div class="pl-2 flex items-center">
      <a
        v-if="$i18n.locale == 'fr'"
        href="https://equal.brussels/fr/"
        class="font-sourcesans text-xs"
        target="_blank"
        rel="noopener"
      >
        {{ $t('common.nav.equalLink') }}
      </a>
      <a
        v-if="$i18n.locale == 'nl'"
        href="https://equal.brussels/nl/"
        class="font-sourcesans text-xs"
        target="_blank"
        rel="noopener"
      >
        {{ $t('common.nav.equalLink') }}
      </a>
    </div>
  </div>
</template>
