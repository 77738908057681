import CoreService from './core.service';

class MembershipService extends CoreService {
  constructor() {
    super({
      endpoint: 'membership',
    });
  }
}

export default new MembershipService();
