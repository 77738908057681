<script>
  export default {
    name: 'NewsSinglePage',
  };
</script>

<script setup>
  import { computed, onMounted, ref } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useNewsStore } from '@/stores/news.store';
  import { useRoute } from 'vue-router';
  import { mergeTranslations } from '@/translations/i18n';
  import { useHead } from '@unhead/vue';
  const { t } = useI18n();

  const route = useRoute();
  const newsStore = useNewsStore();

  const newsSingle = ref({});

  const newsKey = computed(() => {
    const slug = route.params.id;
    return {
      slug,
    };
  });

  // watch(newsKey, async (newKey) => {
  //   await loadNewsData(newKey);
  // });

  await loadNewsData(newsKey.value);

  async function loadNewsData(newsKey) {
    if (newsKey.slug) {
      await newsStore.find();
      mergeTranslations(newsStore.news, 'news');

      const newsItem = newsStore.news.find((item) => {
        return item._id === newsKey.slug || item.slugFr === newsKey.slug || item.slug_nl === newsKey.slug;
      });
      console.log(newsItem);
      newsSingle.value = newsItem;
    }
  }

  useHead({
    title: t('meta.news.title'),
    meta: [
      {
        name: 'description',
        content: t('meta.news.description'),
      },
    ],
  });

  // Scroll to hash element
  // Dom does not exists at 1st load, we need to do it onMounted
  onMounted(() => {
    if (window.location.hash) {
      const scrollToElement = document.getElementById(window.location.hash.substring(1));
      if (scrollToElement) {
        const position = scrollToElement.getBoundingClientRect();
        window.scrollTo(0, position.top + window.scrollY - 20);
      }
    }
  });
</script>
<template>
  <main class="mx-auto max-w-7xl sm:px-6 lg:px-8 py-5" style="min-height: calc(100vh - 340px)">
    <header class="border-b pb-5 mb-5">
      <h1 class="text-3xl font-medium text-gray-900">
        {{ newsSingle?.translations?.[$i18n.locale]?.title }}
      </h1>
    </header>
    <div>
      <div class="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="px-4 py-8 sm:px-0">
          <div
            class="wysiwyg mt-2 text-gray-700 font-light"
            v-html="newsSingle?.translations?.[$i18n.locale]?.content"
          ></div>
        </div>
      </div>
    </div>
  </main>
</template>
<style>
  .wysiwyg h2,
  .wysiwyg h3,
  .wysiwyg h4 {
    margin-bottom: 1rem;
    margin-top: 1rem;
    /* font-weight: 400; */
  }
  .wysiwyg h2 {
    font-size: 1.3rem;
  }
  .wysiwyg h3 {
    font-size: 1.2rem;
  }
  .wysiwyg h4 {
    font-size: 1.1rem;
  }
  .wysiwyg p,
  .wysiwyg ul {
    padding-bottom: 1rem;
    font-size: 1rem;
  }
  .wysiwyg ul {
    margin-left: 1rem;
  }
  .wysiwyg ul li {
    list-style-type: disc;
    margin-left: 1rem;
  }
  .wysiwyg a {
    text-decoration: underline;
    color: rgb(47 62 139 / var(--tw-text-opacity));
  }
</style>
