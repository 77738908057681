/* eslint-disable max-len */
const translations = {
  fr: {
    accessibility: {
      alt: {
        irisLink: "equal.brussels: page d'accueil",
        // sprbLink: 'Lien vers le site du SPRB',
      },
      // closeModal: 'Fermer la fenêtre',
    },
    sprb: 'SPRB',
    languages: {
      french: 'Français',
      dutch: 'Néerlandais',
    },
    copy: 'copie',
    common: {
      punctuation: {
        colon: '\u00A0:',
      },
      additionalInformation: 'Pas de ressources pour {target} | Ressource | Ressources',
      conjunctions: {
        or: 'ou',
        at: 'à',
        to: 'à',
        of: 'de',
      },
      nav: {
        equalLink: 'SERVICE PUBLIC REGIONAL DE BRUXELLES - EQUAL.BRUSSELS',
        profile: 'Mon profil',
      },
      cta: {
        title: 'Avant de pouvoir remplir votre premier test, veuillez procéder à votre inscription.',
      },
      actions: {
        signup: `S'inscrire`,
        connect: 'Se connecter',
        next: 'Suivant',
        previous: 'Précédent',
        confirm: 'Confirmer',
        cancel: 'Annuler',
        send: 'Envoyer',
        delete: 'Supprimer',
        changePwd: 'Changer le mot de passe',
        confirmPassword: 'Confirmer le mot de passe',
        confirmEmail: `Confirmer l'email`,
        unsubscribe: 'Se désinscrire',
        dragNDrop: 'Glisser-déposer',
        browse: 'Explorer',
        save: 'Sauvegarder',
        loading: 'Chargement',
        preview: 'Aperçu',
        chooseOption: 'Choisir {option}',
        abandon: 'Abandonner',
        resume: 'Reprendre',
        continue: 'Continuer',
        leave: 'Quitter',
        downloadPdf: 'Télécharger le PDF',
        download: 'Télécharger',
        action: 'Actions',
        skipToMain: 'Aller au contenu principal',
      },
      result: 'Résultats',
    },
    news: {
      title: 'Actualités',
      seeAll: 'Voir toutes les news',
      readMore: 'En savoir plus',
    },
    advice: {
      name: 'Volet du test et question',
      date: 'Date',
      status: 'Status',
      content: 'Contenu',
      new: 'Nouveau',
      processing: 'En cours',
      close: 'Fermé',
      surveyTableTh: 'Test',
      wrongFileType: "Le type de fichier n'est pas accepté.",
      maxSize: 'Limité à 100Mo.',
      maxFiles: '5 fichiers maximum autorisés.',
      wrongMaxFiles: 'Vous avez atteint le nombre maximum de fichiers autorisés.',
    },
    survey: {
      invalidate: {
        draft: 'Tout test non modifié depuis plus de {value} mois sera automatiquement passé en status “abondonné”.',
        abandoned: 'Ce test n’a pas été modifié depuis plus de {value} mois.',
      },
      explain: 'Explication',
      duplicateModalTitle: 'Dupliquer le test',
      duplicateModalContent:
        "Un nouveau test sera créé d'après les informations du test sélectionné.<br><b>Vous devrez toutefois remplir à nouveau les renseignements généraux propres à votre nouveau projet.</b>",
      duplicateModalConfirm: 'Dupliquer',
      creationModalTitle: 'Créer un nouveau test',
      creationModalContent: 'Entrez un nom pour votre test puis cliquez sur "créer" pour créer un nouveau test',
      creationModalButton: 'Créer',
      lastUpdate: 'Dernière modification',
      lastUpdateThe: 'Dernière modification le',
      title: 'Nom du test',
      previewTitle: 'Aperçu avant génération du PDF : ',
      date: 'Date',
      save: 'Sauvegarde',
      createdAt: 'Créé le',
      modifiedAt: 'Modifé le',
      lastSave: 'Dernière @.lower:survey.save le',
      autoSave: '@:survey.save automatique',
      projectTitle: 'Titre du projet',
      type: 'Type',
      projectType: 'Type de projet',
      status: 'Statut',
      completion: 'Complétion',
      adviceRequest: "Demande d'avis",
      version: 'version',
      closed: 'Clôturé',
      open: 'Ouvert',
      aborted: 'Abandonné',
      abortedButton: 'Abandonner le test',
      takeBackButton: 'Reprendre le test',
      part: 'Volet',
      question: 'Question',
      label: 'Test :',
      questionLabel: 'Question :',
      acceptedFile: 'Fichier accepté | Fichiers acceptés',
      chooseOption: 'Choisir une option',
      askedAdvice: 'Vous avez demandé un avis le ',
      panelQuestionsLabel: 'Questions du volet',
      exVat: '€ HTVA',
      previewPageDescription:
        'Cette page est accessible publiquement. Vous pouvez transmettre ce lien à un tiers pour lecture.',
      deletedMessage: "Le test n'existe pas ou a été supprimé.",
      pdfPageTitle: 'Aperçu avant génération du PDF',
      parts: {
        generalInfo: 'Renseignement généraux',
        genre: 'Genre',
        handicap: 'Handicap',
        ethnicOrigin: 'Origine ethnique et culturelle',
        sexualOrientation: 'Orientation sexuelle, identité et expression de genre',
        socialOrigin: 'Origine et situation sociales',
        complementaryCriterion: 'Critères complémentaires (facultatif)',
      },
      advicepreview: {
        yourFiles: 'Vos fichiers :',
        yourRequest: 'Votre demande :',
        received: 'Reçue le ',
        gotToSurveyLink: 'Voir le test',
      },
      exceptions: {
        chooseOptionsPanel: 'Aucun groupe cible concerné',
        explanation: 'Explication',
        panelIsExcepted: "Le volet n'a pas a être rempli.",
      },
      anonymised: 'Anonymisé',
      errors: {
        requiredField: 'Ce champ est obligatoire',
        listLabel: 'Les champs suivants contiennent des erreurs :',
        generatingPdf: 'Erreur lors de la génération du PDF',
      },
    },
    user: {
      firstname: 'Prénom',
      lastname: 'Nom',
      language: 'Langue de préférence',
      function: 'Fonction',
      email: 'Adresse Email',
      membership: 'Organisme public',
      option: '',
      password: 'Mot de passe',
      notification: 'Notifications',
      completedTestMail: 'Recevoir un mail automatiquement avec le PDF du test lorsque celui-ci est clôturé.',
      newsletter: `Recevoir des e-mails d'information (nouvelle version des tests, information liée au test d'égalité des chances, etc.)`,
      languageFieldComment:
        'Votre choix de langue sera utilisé pour les échanges de mails et comme langue par défaut lors de votre connexion.',
      cantChangeEmail:
        'Vous ne pouvez pas modifier votre email. Si vous devez changer de mail, il vous faut créer un nouveau compte.',
    },
    profile: {
      labels: {
        personalInfo: 'Informations personnelles',
        professionalInfo: 'Informations professionnelles',
        option: 'Options',
        account: 'Compte',
      },
    },
    auth: {
      fillThisField: 'Veuillez remplir ce champs',
      sentPaswwordResetButton: 'Envoyer la demande',
      changePasswordSuccess: 'Votre mot de passe a été correctement modifié!',
      resetPasswordMailSent:
        'Vous recevrez un e-mail contenant les instructions vous permettant de réinitialiser votre mot de passe.',
      resetPasswordDescription:
        "Vous allez recevoir un mail dans quelques instants contenant un lien pour réinitialiser votre mot de passe. Ce lien est valable durant 30 minutes.  Pas reçu d'e-mail ? Si vous avez entré un identifiant qui n'est pas une adresse email, réessayez avec votre adresse email. Sinon, patience, cela peut prendre quelques minutes. Pensez également à vérifier votre courrier indésirable.",
      generatePassword: 'Changer le mot de passe',
      validateAccount:
        "<p>Veuillez valider votre compte via le mail de confirmation que vous avez reçu.</p><p> Si vous n'avez rien reçu, vérifiez dans vos Spam.</p>",
      validateAccountSentNewMail: 'Renvoyez un email',
      validateAccountSentNewMailMessage: 'Un nouvel email de validation à été envoyé.',
      validatedAccount: '<p>Merci et bienvenue.</p><p>Votre compte est activé. Vous pouvez commencer votre test.</p>',
      alreadyValidated: '<p>Votre compte est déjà activé.</p>',
      termsAknowledgement:
        "J'ai pris connaissances des <a href='/fr/page/privacy-policy#general-terms' class='underline text-sprb-600'>conditions générales d'utilisation</a> et de la <a href='/fr/page/privacy-policy' class='underline text-sprb-600'>politique de confidentialité</a>",
      rememberMe: 'Se souvenir de moi',
      forgottenPassword: 'Mot de passe oublié ?',
      forgottenPasswordMessage: 'Recevoir un lien de réinitialisation par mail.',
      noAccount: "Je n'ai pas de compte",
      emailError: 'Veuillez renseigner un email valide',
      emailResetPassError: "Cet email n'appartient à aucun utilisateur connu.",
      passwordsDifferent: 'Les mots de passes ne correspondent pas.',
      emptyNewPassword: 'Veuillez renseigner votre nouveau mot de passe.',
      confirmEmailError: "Mauvaise correspondance d'email.",
      confirmPasswordError: 'Mauvaise correspondance de mot de passe',
      invalidToken: "Le lien de réinitialisation du mot de passe n'est plus valable",
      invalidRefreshRoken: 'Votre session a expirée. Veuillez vous reconnecter.',
      pleaseFirstConnect: "Vous devez d'abord vous connecter ou vous inscrire pour pouvoir remplir un nouveau test",
      emailRequired: 'Veuillez renseigner votre email',
      passwordRequired: 'Veuillez renseigner votre mot de passe',
      wrongLogin: "Erreur de connexion : Le nom d'utilisateur et / ou le mot de passe sont incorrects",
      sessionExpired: 'Votre session a expirée. Veuillez vous reconnecter.',
      emailAlreadyUsed: 'Cet email est déjà utilisé.',
      domainNotMembership:
        'Vous ne pouvez pas vous inscrire si vous n\'avez pas une adresse email correspondante à un des organismes publics présents dans la liste. Pour plus d’information contactez le Service equal.brussels via : <a href="mailto:impact.equal{\'@\'}sprb.brussels" target="_blank" class="underline" rel="noopener">impact.equal{\'@\'}sprb.brussels</a>.',
      resetPasswordEmailSent: 'Un email de réinitialisation du mot de passe vous à été envoyé.',
      emailFieldComment:
        "Vous devez être membre d'un organisme public et avoir une adresse email de celui-ci pour vous inscrire.",
      passwordStrengthError:
        'Votre mot de passe doit contenir au moins 10 caractères et doit contenir au moins une majuscule, un caractère spécial et un chiffre.',
    },
    panel: {
      model: 'Pas de volet | Volet | Volets',
      direct: 'Ce volet',
    },
    regionalCompetency: {
      model: 'Pas de compétence régionale | Compétence régionale | Compétences régionales',
      direct: 'Cette compétence régionale',
    },
    page404: {
      title: 'Page non trouvée',
      message: "Désolé, cette page n'existe pas.",
    },
    cookieNotice: {
      noticeMessage: ' Nous utilisons des cookies pour assurer la meilleure expérience sur notre site',
      knowMore: '(En savoir plus)',
      agreementMessage: 'En acceptant, vous autorisez lʼutilisation de ces cookies.',
      parametersTitle: 'Paramètres des cookies',
      parametersMessage: 'Vous pouvez choisir quels cookies vous acceptez',
      parametersButton: 'Enregistrer',
      parametersRequired: 'Cookies nécessaires au fonctionnement du site - REQUIS',
      parametersAnalytics: 'Cookies analytics',
      cookiesAgreement: 'Tout Accepter',
      cookiesParameters: 'Paramètres',
    },
    meta: {
      homepage: {
        title: 'Accueil',
        description: "Bienvenue sur le test d'égalité des chances de la Région de Bruxelles-Capitale",
      },
      faq: {
        title: 'FAQ',
        description: 'FAQ',
      },
      news: {
        title: 'Actualités',
        description: 'Actualités',
      },
    },
  },
  nl: {
    accessibility: {
      alt: {
        irisLink: 'equal.brussels link',
      },
    },
    sprb: 'GOB',
    languages: {
      french: 'Frans',
      dutch: 'Nerderlands',
    },
    common: {
      punctuation: {
        colon: ':',
      },
      result: 'Resultaten',
      additionalInformation: 'Hulpbronnen',
      conjunctions: {
        or: 'of',
        at: 'om',
        to: 'tot',
        of: 'van',
      },
      cta: {
        title: 'Schrijf u in om uw test te kunnen invullen.',
      },
      nav: {
        homepage: 'Homepagina',
        signin: 'Inloggen',
        signup: 'Inschrijven',
        profile: 'Mijn profiel',
        test: 'Mijn testen',
        equalLink: 'GEWESTELIJKE OVERHEIDSDIENST BRUSSEL - EQUAL.BRUSSELS',
      },
      actions: {
        cancel: 'Annuleren',
        connect: 'Inloggen',
        signup: 'Inschrijven',
        delete: 'Verwijderen',
        loading: 'Laden',
        browse: 'Verken',
        next: 'Volgende',
        previous: 'Vorige',
        preview: 'Overzicht',
        confirm: 'Bevestigen',
        send: 'Verzenden',
        changePwd: 'Wachtwoord wijzigen',
        unsubscribe: 'Zich uitschrijven',
        confirmEmail: 'Bevestiging e-mailadres',
        confirmPassword: 'Wachtwoord bevestigen',
        dragNDrop: "'Drag and Drop'",
        save: 'Bewaren',
        continue: 'Doorgaan',
        leave: 'Laat',
        download: 'Download',
        downloadPdf: 'Download PDF',
        action: 'Acties',
        skipToMain: 'Ga naar de hoofdinhoud',
      },
    },

    news: {
      title: 'Nieuws',
      seeAll: 'Zie al het nieuws',
      readMore: 'Lees meer',
    },
    auth: {
      fillThisField: 'Vul dit veld in',
      sentPaswwordResetButton: 'De aanvraag verzenden',
      resetPasswordMailSent: 'Je zal een e-mail ontvangen met instructies waarmee je het wachtwoord kan resetten.',
      generatePassword: 'Een wachtwoord aanmaken',
      resetPasswordDescription:
        'U krijgt binnen enkele ogenblikken een e-mail met een link om uw wachtwoord opnieuw in te stellen. Deze link is gedurende 30 minuten geldig.  Geen e-mail ontvangen? Als u een login hebt ingevoerd die geen e-mailadres is, probeer het dan opnieuw met uw e-mailadres. Zo niet, even geduld, dit kan enkele minuten duren. Bekijk ook uw map met ongewenste e-mails.',
      changePasswordSuccess: 'Je paswoord werd gewijzigd!',
      validateAccount:
        '<p>Valideer je account via de bevestigingsmail die je hebt ontvangen.</p><p> Indien je niets hebt ontvangen, controleer je spamfolder.</p>',
      validateAccountSentNewMail: 'Stuur opnieuw een e-mail',
      validatedAccount: '<p>Dank je wel en welkom.</p><p>Je account is geactiveerd. Je kan met de test beginnen.</p>',
      alreadyValidated: '<p>Uw account is al geactiveerd.</p>',
      validateAccountSentNewMailMessage: 'Er werd een nieuwe bevestigingse-mail gestuurd.',
      termsAknowledgement:
        "Ik heb de <a href='/nl/page/privacy-policy#general-terms' class='underline text-sprb-600'>algemene gebruiksvoorwaarden</a> en het <a href='/nl/page/privacy-policy' class='underline text-sprb-600'>privacy beleid</a> gelezen.",
      rememberMe: 'Mij herinneren',
      forgottenPasswordMessage: 'Ontvang een re-initialisatielink via e-mail.',
      forgottenPassword: 'Wachtwoord vergeten?',
      noAccount: 'Ik heb geen account',
      emailError: 'Voer een geldig e-mailadres in.',
      emailResetPassError: 'Deze e-mail is niet van een bekende gebruiker.',
      passwordsDifferent: 'De wachtwoorden stemmen niet overeen.',
      emptyNewPassword: 'Voer uw nieuwe wachtwoord in.',
      confirmEmailError: 'Slechte e-mail correspondentie.',
      confirmPasswordError: 'Wachtwoord komt niet overeen.',
      invalidToken: 'De wachtwoord reset link is niet langer geldig.',
      invalid_refresh_token: 'Je sessie is afgelopen. Log opnieuw in.',
      pleaseFirstConnect:
        'Je moet eerst inloggen of je inschrijven vooraleer je een nieuwe gelijkekansentest kan invullen.',
      emailRequired: 'Voer je e-mailadres in.',
      passwordRequired: 'Voer je wachtwoord in.',
      sessionExpired: 'Uw sessie is verlopen. Log alsjeblieft opnieuw in.',
      emailAlreadyUsed: 'Deze e-mail is al in gebruik.',
      domainNotMembership:
        'Je kan je niet inschrijven als je geen e-mailadres hebt dat overeenkomt met een van de instellingen op de lijst.',
      resetPasswordEmailSent: 'Er werd een e-mail voor de re-initialisatie van uw paswoord naar u verzonden.',
      wrongLogin: 'Er is een fout opgetreden tijdens het inloggen. Gebruikersnaam en/of wachtwoord zijn onjuist',
      emailFieldComment:
        'U moet lid zijn van een instelling en beschikken over een e-mailadres van die instelling om je in te schrijven.',
      passwordStrengthError:
        'Uw wachtwoord moet ten minste 10 tekens lang zijn en ten minste één hoofdletter, één speciaal teken en één cijfer bevatten.',
    },
    user: {
      cantChangeEmail:
        'U kan uw e-mailadres niet wijzigen. Indien u uw e-mailadres moet wijzigen, moet u een nieuw account aanmaken.',
      firstname: 'Voornaam',
      lastname: 'Naam',
      language: 'Taalvoorkeur',
      function: 'Functie',
      email: 'E-mailadres',
      membership: 'Instelling',
      option: '',
      password: 'Wachtwoord',
      notification: 'Meldingen',
      languageFieldComment:
        'Uw taalkeuze zal worden gebruikt voor de e-mailcorrespondentie en als standaardtaal bij het inloggen.',
    },
    survey: {
      invalidate: {
        draft: 'Elke test die langer dan {value} maanden niet is gewijzigd, krijgt automatisch de status "verlaten".',
        abandoned: 'Deze test is al meer dan {value} maanden niet meer gewijzigd.',
      },
      advicepreview: {
        yourFiles: 'Uw bestanden :',
        yourRequest: 'Je verzoek',
        received: 'Ontvangen op',
        gotToSurveyLink: 'Bekijk de test',
      },
      exceptions: {
        chooseOptionsPanel: 'Geen enkele doelgroep betrokken',
        explanation: 'Toelichting:',
        panelIsExcepted: 'Je moet dit gedeelte niet invullen',
      },
      errors: {
        requiredField: 'Dit veld is verplicht',
        listLabel: 'De volgende velden bevatten fouten:',
        generatingPdf: 'Fout bij het genereren van de PDF',
      },
      explain: 'Uitleg',
      panelQuestionsLabel: 'Vragen over het gedeelte:',
      autoSave: 'Automatische back-up',
      lastSave: 'Laatste back-up gemaakt op',
      duplicateModalTitle: 'Test dupliceren',
      duplicateModalContent:
        'Een nieuwe test wordt aangemaakt op basis van de informatie in de geselecteerde test. U zult echter de algemene informatie die specifiek is voor uw nieuwe project opnieuw moeten invullen.',
      duplicateModalConfirm: 'Dupliceren',
      creationModalTitle: 'Creëer een nieuwe test.',
      creationModalContent: 'Geef de test een naam, klik vervolgens op ‘creëren’ om een nieuwe test te creëren',
      creationModalButton: 'Creëren',
      status: 'Status',
      label: 'Test:',
      questionLabel: 'Vraag:',
      title: 'Naam van de test',
      lastUpdate: 'Laatste wijziging',
      lastUpdateThe: 'Laatste wijziging op',
      previewTitle: 'Preview voordat de pdf wordt gemaakt',
      date: 'Datum',
      save: 'Back-up',
      modifiedAt: 'Gewijzigd op',
      createdAt: 'Gecreëerd op',
      adviceRequest: 'Adviesverzoek',
      completion: 'Afgerond',
      type: 'Type',
      projectType: 'Type project',
      projectTitle: 'Titel van het project',
      acceptedFile: 'Aanvaarde bestanden',
      chooseOption: 'Een optie kiezen',
      askedAdvice: 'U hebt advies aangevraagd op ',
      abortedButton: 'De test verlaten',
      takeBackButton: 'De test hervatten',
      previewPageDescription:
        'Dezer pagina is voor iedereen toegankelijk. U kan deze link aan een derde bezorgen opdat die de test kan lezen.',
      exVat: 'excl. btw',
      deletedMessage: 'De test bestaat niet of werd verwijderd',
      pdfPageTitle: 'Voorbeeld voor het genereren van de PDF',
      anonymised: 'Anoniem',
    },
    advice: {
      name: 'Testonderdeel en vraag',
      date: 'Datum',
      status: 'Status',
      content: 'Inhoud',
      new: 'Nieuw',
      processing: 'Lopende',
      close: 'Gesloten',
      testTableTh: 'Test',
      wrongFileType: 'Fout bestandstype.',
      wrongFileSize: 'Fout bestandsgrootte. Het bestand mag niet groter zijn dan 100MB.',
      maxSize: 'Het bestand mag niet groter zijn dan 100MB.',
      maxFiles: 'Maximaal 5 bestanden toegestaan.',
      wrongMaxFiles: 'Maximum aantal bestanden overschreden.',
    },
    profile: {
      labels: {
        personalInfo: 'Persoonlijke informatie',
        professionalInfo: 'Professionele gegevens',
        option: 'Opties',
        account: 'Account',
      },
    },
    login: {
      rememberMe: 'Onthoud mij',
      forgottenPassword: 'Wachtwoord vergat ?',
      noAccount: 'Ik heb geen account',
    },
    page404: {
      title: 'Pagina niet gevonden',
      message: 'Sorry, deze pagina bestaat niet.',
    },
    cookieNotice: {
      noticeMessage: 'Wij gebruiken cookies om een zo goed mogelijke ervaring op onze website te garanderen',
      knowMore: '(Meer over weten)',
      agreementMessage: 'Door te aanvaarden, aanvaard je het gebruik van deze cookies.',
      parametersTitle: 'Parameters van de cookies',
      parametersMessage: 'Je kunt kiezen welke cookies je aanvaardt',
      parametersButton: 'Opslaan',
      parametersRequired: 'Cookies die nodig zijn voor de werking van de website - VEREIST',
      parametersAnalytics: 'Analytische cookies',
      cookiesAgreement: 'Alles aanvaarden',
      cookiesParameters: 'Parameters',
    },
    meta: {
      homepage: {
        title: 'Home',
        description: 'Welkom op de website over de gelijke kansentest van het Brussels Hoofdstedelijk Gewest',
      },
      faq: {
        title: 'FAQ',
        description: 'FAQ',
      },
      news: {
        title: 'Nieuws',
        description: 'Nieuws',
      },
    },
  },
};

export default translations;
