<script setup>
  import { RouterLink } from 'vue-router';
  import { useLocalePath } from 'vue-i18n-routing';
  import useAuthStore from '@/stores/auth.store';
  import LanguageSwitch from '@/components/LanguageSwitch.vue';

  const localePath = useLocalePath();

  defineProps({
    main: {
      type: Array,
      required: true,
    },
    secondary: {
      type: Array,
    },
    isLogged: {
      type: Boolean,
    },
  });

  let displayEnvName = null;
  if (import.meta.env.VITE_ENV && import.meta.env.VITE_ENV !== 'PROD') {
    displayEnvName = import.meta.env.VITE_ENV;
  }

  function logout() {
    useAuthStore().logout(localePath('/'));
  }
</script>

<template>
  <!-- eslint-disable max-len -->
  <!-- router-link-active router-link-exact-active = force tailwind to include it -->
  <div class="min-h-full">
    <nav class="bg-white shadow-sm">
      <div class="mx-auto max-w-7xl px-4 lg:px-8">
        <div class="flex flex-col md:flex-row justify-between">
          <div class="py-2 flex flex-col justify-center items-center md:items-start">
            <RouterLink :to="localePath('/')">
              <img
                v-if="$i18n.locale == 'fr'"
                class="h-10 w-56"
                src="@/assets/logo-equal-brussels-fr.svg"
                alt="Test d'égalité des chances de la Région de Bruxelles-Capitale"
              />
              <img
                v-if="$i18n.locale == 'nl'"
                class="h-10"
                src="@/assets/logo-equal-brussels-nl.svg"
                alt="Gelijkekansentest van het Brussels Hoofdstedelijk Gewest"
              />
            </RouterLink>
            <div class="text-sprb-700 font-medium mt-1">
              <span v-if="displayEnvName">
                <i>
                  {{ displayEnvName }}
                </i>
              </span>
              {{ $t('header_title') }}
            </div>
          </div>
          <div class="ml-6 flex flex-col text-center">
            <div id="top-sup-nav" class="flex space-x-2 items-center justify-between md:justify-end mb-4 text-sm">
              <RouterLink
                v-for="link in secondary"
                :key="link.to"
                :to="localePath(link.to)"
                class="router-link-default"
                aria-current="page"
              >
                {{ $t(link.title) }}
              </RouterLink>
              <div v-if="isLogged" @click="logout" class="router-link-default cursor-pointer">
                {{ $t('nav_signout') }}
              </div>
              <LanguageSwitch classProp="router-link-default" />
            </div>
            <div id="top-main-nav" class="flex space-x-8 justify-between md:justify-end">
              <RouterLink
                v-for="link in main"
                :key="link.to"
                :to="localePath(link.to)"
                class="router-link-default"
                aria-current="page"
              >
                {{ $t(link.title) }}
              </RouterLink>
              <RouterLink v-if="isLogged" :to="localePath('/test/new')" class="btn btn-primary mb-3 flex items-center">
                {{ $t('nav_new_test') }}
              </RouterLink>
              <RouterLink v-if="!isLogged" :to="localePath('/signup')" class="btn btn-primary mb-3 flex items-center">
                {{ $t('common.actions.signup') }}
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>
