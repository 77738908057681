<script async setup>
  import '@/styles/animation.css';

  import { ref, toRaw, computed, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useLocalePath } from 'vue-i18n-routing';
  import { mergeTranslations } from '@/translations/i18n';
  import { useSurveyStore } from '@/stores/survey.store.js';
  import { useSelectListStore } from '@/stores/selectList.store.js';

  import SurveyForm from '@/components/SurveyForm.vue';
  import ConfirmModal from '@/components/ConfirmModal.vue';
  import FormField from '@/components/FormField.vue';
  import PanelSidebar from '@/components/PanelSidebar.vue';
  import AlertBox from '@/components/AlertBox.vue';

  import { useHead } from '@unhead/vue';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  const localePath = useLocalePath();
  const route = useRoute();
  const router = useRouter();

  const surveyStore = useSurveyStore();
  const selectListStore = useSelectListStore();

  // TODO rename answsersCopy
  const answers = ref({});
  const exceptions = ref({});
  const currentPanel = ref(0);
  const loading = ref(false);
  const validationModal = ref(false);
  const deleteSurveyModal = ref(false);
  const autosaveEnabled = ref(true);
  const editTitle = ref(false);
  const project = ref([]);
  const errors = ref([]);

  // Init autosave
  if (localStorage.getItem('autosave')) {
    if (localStorage.getItem('autosave') == 'true') {
      autosaveEnabled.value = true;
    }
    if (localStorage.getItem('autosave') == 'false') {
      autosaveEnabled.value = false;
    }
  }

  const survey = computed(() => {
    return toRaw(surveyStore.survey);
  });

  watch(autosaveEnabled, (value) => {
    localStorage.setItem('autosave', value);
  });

  watch(currentPanel, () => {
    window.scrollTo({ top: 0 });
    if (autosaveEnabled.value == true) {
      save();
    }
  });

  await surveyStore.populatedFindById(route.params.id);

  await selectListStore.find({
    version: surveyStore.survey.questionnaire.version,
  });

  answers.value = structuredClone(toRaw(surveyStore.survey.answers));
  exceptions.value = structuredClone(toRaw(surveyStore.survey.exceptions));

  // insert questionnaire's project in array to be merged with translations
  project.value.push(surveyStore?.survey?.questionnaire?.project);

  mergeTranslations(surveyStore?.survey?.questionnaire?.panels, 'panels');
  mergeTranslations(project.value, 'projects');
  surveyStore?.survey?.questionnaire?.panels.forEach((panel) => {
    mergeTranslations(panel.questions, 'questions');
  });
  selectListStore?.optionLists.forEach((list) => {
    mergeTranslations(list.options, 'options');
  });

  async function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function save() {
    try {
      loading.value = true;
      survey.value.exceptions = exceptions.value;
      survey.value.answers = answers.value;
      await surveyStore.update(survey.value._id, toRaw(survey.value));
      await sleep(1000);
      loading.value = false;
    } catch (error) {
      console.log(error);
    }
  }

  let timeoutId;
  function debounceSave(delay = 2000) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      save();
    }, delay);
  }

  function hasExceptions() {
    return surveyStore.survey.questionnaire.useFullException || surveyStore.survey.questionnaire.usePartialException;
  }

  function getGeneralInfoUnansweredQuestions() {
    // General info panel is always the first one
    const generalInfoPanelId = Object.keys(surveyStore.survey.answers)[0];
    const generalInfoAnswers = surveyStore.survey.answers[generalInfoPanelId];

    const unansweredQuestions = Object.entries(generalInfoAnswers)
      .filter(([questionId, answer]) => {
        return !answer.value;
      })
      .map(([questionId, answer]) => {
        return { questionId, message: 'survey.errors.requiredField' };
      });

    return unansweredQuestions;
  }

  function updateErrors(questionId) {
    errors.value = errors.value.filter((error) => error.questionId !== questionId);
  }

  async function validateSurvey() {
    const generalInfoUnansweredQuestions = getGeneralInfoUnansweredQuestions();
    let surveyIsValid = true;

    errors.value = [];

    if (generalInfoUnansweredQuestions.length) {
      const generalInfoPanelId = Object.keys(surveyStore.survey.answers)[0];

      errors.value = [...errors.value, ...generalInfoUnansweredQuestions];
      surveyIsValid = false;

      // Redirect to general info panel and scroll to first unanswered question
      currentPanel.value = 0;
      router.push(`#${generalInfoPanelId}-${errors.value[0].questionId}`);
      // If we are already on the info panel, we need to go top
      window.scrollTo({ top: 0 });
    }

    survey.value.status = surveyIsValid ? 'closed' : 'draft';
    await save();

    if (surveyIsValid) {
      router.push({ path: localePath('/user/tests') });
    }
  }

  // Create panel option list for exceptions
  function getPartialExceptionsPanelOptions() {
    const panelOptions = [];
    surveyStore.survey.questionnaire.panels.forEach((panel) => {
      if (panel.useForPartialExceptions) {
        const panelOption = {
          _id: panel._id,
          id: panel._id,
          translations: {
            fr: panel.translations.fr.title,
            nl: panel.translations.nl.title,
          },
        };
        panelOptions.push(panelOption);
      }
    });
    mergeTranslations(panelOptions, 'options');
    return panelOptions;
  }

  // Full and partial exceptions
  // Need to be declared last because of translation merge dependencies
  const exceptionsForm = ref([
    {
      fields: [
        {
          label: 'test_exception_full_question_label',
          required: false,
          input: {
            type: 'checkbox',
            name: 'fullExceptionCheckboxes',
            id: 'fullExceptionCheckboxes',
            options: selectListStore.exceptions.options,
          },
        },
      ],
    },
    {
      fields: [
        {
          label: 'test_exception_partial_question_label',
          required: false,
          input: {
            placeholder: 'survey.exceptions.chooseOptionsPanel',
            type: 'checkbox',
            name: 'partialExceptionSelect',
            id: 'partialExceptionSelect',
            options: getPartialExceptionsPanelOptions(),
          },
        },
        {
          label: 'survey.exceptions.explanation',
          required: false,
          input: {
            type: 'textarea',
            name: 'partialExceptionExplanation',
            id: 'partialExceptionExplanation',
          },
        },
      ],
    },
  ]);

  async function deleteSurvey() {
    await surveyStore.deleteOne(survey.value._id);
    router.push({
      path: localePath('/user/tests'),
    });
  }

  useHead({
    title: surveyStore.survey.title,
    meta: [
      {
        name: 'description',
        content: surveyStore.survey.title,
      },
    ],
  });
</script>

<!-- eslint-disable max-len -->
<template>
  <div class="sticky top-0 z-10 bg-white border-y border-y-gray-300">
    <nav class="mx-auto max-w-7xl px-4 lg:px-8 py-4">
      <div class="grid grid-cols-6 gap-4">
        <div class="col-span-6 lg:col-span-4">
          <div v-if="!editTitle" class="text-gray-500 text-sm font-light leading-3">
            <RouterLink :to="localePath('/user/tests')" class="cursor-pointer hover:text-sprb-700">
              {{ $t('nav_my_tests') }}
            </RouterLink>
            <span class="mx-1">&#8594;</span>
          </div>
          <div class="">
            <div v-if="editTitle" class="flex space-x-2 h-11">
              <input
                @keydown.enter="editTitle = false"
                @keyup="debounceSave()"
                class="form-input mt-0 rounded-none text-lg"
                :class="{
                  'bg-gray-100 cursor-not-allowed': survey.status === 'closed',
                }"
                :disabled="survey.status === 'closed'"
                v-model="survey.title"
              />
              <button class="btn btn-secondary" @click="editTitle = false">{{ $t('common.actions.confirm') }}</button>
            </div>
            <h1
              v-if="!editTitle"
              @click="editTitle = true"
              class="text-2xl font-medium text-gray-900 cursor-pointer underline decoration-2 decoration-dashed decoration-gray-300 underline-offset-8 hover:decoration-sprb-700"
            >
              {{ survey.title }}
            </h1>
          </div>
        </div>
        <div class="col-span-6 lg:col-span-2 flex items-center justify-start lg:justify-end space-x-4">
          <div class="">
            <div
              v-if="survey.status === 'closed'"
              class="rounded-full px-2 text-xs font-semibold leading-5 bg-green-100 text-green-800"
            >
              {{ $t('test_status_closed') }}
            </div>
            <div
              v-if="survey.status === 'draft'"
              class="rounded-full px-2 text-xs font-semibold leading-5 bg-sprb-100 text-sprb-600"
            >
              {{ $t('test_status_draft') }}
            </div>
            <div
              v-if="survey.status === 'abandoned'"
              class="rounded-full px-2 text-xs font-semibold leading-5 bg-red-100 text-red-700"
            >
              {{ $t('test_status_abandoned') }}
            </div>
          </div>
          <RouterLink
            :to="localePath(`/test-preview/${survey._id}`)"
            :title="$t('common.actions.preview')"
            class="text-gray-400 hover:text-sprb-700 text-xl"
          >
            <fontawesome-icon :icon="['fas', 'file-pdf']" aria-hidden="true" />
          </RouterLink>
          <button
            v-if="survey.status !== 'closed'"
            @click="deleteSurveyModal = true"
            :title="$t('common.actions.delete')"
            class="text-gray-400 hover:text-red-500 text-xl"
          >
            <fontawesome-icon :icon="['fas', 'trash']" />
          </button>
          <div class="grow lg:grow-0 flex justify-end lg:justify-start">
            <button
              @click="validationModal = true"
              v-if="survey.status !== 'closed'"
              type="button"
              class="btn btn-green"
            >
              {{ $t('test_validation_bouton') }}
            </button>
          </div>
        </div>
      </div>
    </nav>
  </div>

  <main class="mx-auto max-w-7xl px-4 lg:gap-8 lg:px-8 py-8 lg:grid lg:grid-cols-3">
    <form @submit.prevent class="lg:col-span-2">
      <AlertBox v-if="errors.length" class="mb-10 px-4">
        <p>{{ $t('survey.errors.listLabel') }}</p>
        <ul class="list-inside list-disc">
          <li v-for="error in errors" :key="error">
            {{ $t(`questions.${error.questionId}.name`) }}
          </li>
        </ul>
      </AlertBox>

      <div class="bg-white overflow-hidden rounded-lg shadow mb-4">
        <div v-if="currentPanel === 0" class="px-2 py-4 flex justify-start">
          <div class="flex-shrink-0 w-16">
            <svg class="scale-75" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 75">
              <defs>
                <clipPath id="a" transform="translate(-21.74 -191.25)">
                  <path d="M21.74 191.25h374.39v69.27H21.74z" style="fill: none" />
                </clipPath>
              </defs>
              <g style="clip-path: url(#a)">
                <path
                  d="M51.61 191.25c-15 0-29.87 11.29-29.87 30.18 0 18.32 17 29.71 31.7 29.71 16.22 0 24-5.08 37.71-2.32A37.21 37.21 0 0 1 110.49 259a3.45 3.45 0 0 0 4.3.68c1.19-.79 2-2.27 1.19-5.81-1.87-8.25-6.27-17.48-11.37-25.36-10-15.48-30.35-37.21-53-37.21"
                  style="fill: #2f3e8b"
                  transform="translate(-21.74 -191.25)"
                />
              </g>
            </svg>
          </div>
          <div class="ml-2">
            <label class="block mb-1 text-gray-500 leading-none">{{ $t('survey.projectType') }}</label>
            <div class="text-xl font-medium">
              {{ $t('projects.' + surveyStore.survey.questionnaire.project._id + '.name') }}
            </div>
            <div class="mt-4">
              <p v-html="$t('new_test_page_description_2')" class="mb-3" />
              <p v-if="$t(`projects.${surveyStore.survey.questionnaire.project._id}.description`)">
                {{ $t(`projects.${surveyStore.survey.questionnaire.project._id}.description`) }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Suspense>
        <SurveyForm
          @stopTyping="if (autosaveEnabled) save();"
          @updateErrors="updateErrors"
          v-if="surveyStore.survey.questionnaire.panels && surveyStore.survey.questionnaire.panels[currentPanel]"
          :survey="surveyStore.survey"
          :answers="answers"
          :optionLists="selectListStore.optionLists"
          :currentPanel="currentPanel"
          :errors="errors"
        />
      </Suspense>

      <!-- Exceptions -->
      <div v-if="currentPanel === 0 && hasExceptions()" class="overflow-hidden rounded-lg bg-white shadow mb-5">
        <div class="p-4">
          <h3 class="text-xl font-medium mb-3">
            {{ $t('test_exception_title') }}
          </h3>
          <div v-if="surveyStore.survey.questionnaire.useFullException">
            <h3 class="mb-3 bg-sprb-50 px-4 py-2 border border-sprb-300 text-sprb-900 rounded">
              {{ $t('test_exception_full_question') }}
            </h3>
            <FormField
              @stopTyping="if (autosaveEnabled) save();"
              :fields="exceptionsForm[0].fields"
              :data="exceptions"
              :isDisabled="survey.status === 'closed'"
            />
          </div>
          <div
            v-if="surveyStore.survey.questionnaire.usePartialException"
            :class="{ 'mt-8': surveyStore.survey.questionnaire.useFullException }"
          >
            <h3 class="mb-3 bg-sprb-50 px-4 py-2 border border-sprb-300 text-sprb-900 rounded">
              {{ $t('test_exception_partial_question') }}
            </h3>
            <FormField
              @stopTyping="if (autosaveEnabled) save();"
              :fields="exceptionsForm[1].fields"
              :data="exceptions"
              :isDisabled="survey.status === 'closed'"
            />
          </div>
        </div>
      </div>

      <div class="mt-5 flex justify-end gap-4">
        <button
          v-if="currentPanel > 0"
          @click="
            --currentPanel;
            if (autosaveEnabled) save();
          "
          type="button"
          class="btn btn-secondary"
        >
          {{ $t('common.actions.previous') }}
        </button>
        <button
          v-if="currentPanel < surveyStore.survey.questionnaire.panels.length - 1"
          @click="
            ++currentPanel;
            if (autosaveEnabled) save();
          "
          type="button"
          class="btn btn-primary"
        >
          {{ $t('common.actions.next') }}
        </button>
        <button
          v-else-if="currentPanel === surveyStore.survey.questionnaire.panels.length - 1"
          @click="
            validationModal = true;
            if (autosaveEnabled) save();
          "
          type="button"
          class="btn text-white shadow-sm"
          :class="{
            'bg-green-700 hover:bg-green-800 focus:ring-green-600': survey.status !== 'closed',
            'bg-gray-400 cursor-not-allowed': survey.status === 'closed',
          }"
          :disabled="survey.status === 'closed'"
        >
          {{ $t('test_validation_bouton') }}
        </button>
      </div>
    </form>
    <div class="lg:col-span-1">
      <div class="sticky top-32">
        <PanelSidebar
          @changePanel="(panel) => (currentPanel = panel)"
          :panels="surveyStore.survey.questionnaire.panels"
          :currentPanel="currentPanel"
        />
        <div class="rounded bg-white shadow overflow-hidden">
          <div class="group h-1.5 hover:h-5 relative">
            <div
              class="absolute w-full text-sm text-center z-10 pointer-events-none hidden group-hover:block"
              :class="{
                'text-white': surveyStore.survey.completion >= 60,
                'text-sprb-700': surveyStore.survey.completion < 60,
              }"
            >
              {{ surveyStore.survey.completion }} %
            </div>
            <div class="flex items-center gap-2">
              <div
                role="meter"
                aria-valuemin="0"
                :aria-valuenow="surveyStore.survey.completion"
                aria-valuemax="100"
                aria-labelledby="progression"
                class="transition-all duration-100 ease relative h-1.5 hover:h-5 w-full bg-gray-200"
              >
                <div
                  :style="`width: ${surveyStore.survey.completion}%`"
                  class="absolute h-full"
                  :class="{
                    'bg-green-600': surveyStore.survey.completion == 100,
                    'bg-sprb-600': surveyStore.survey.completion < 100,
                  }"
                ></div>
              </div>
            </div>
          </div>
          <div class="p-4 flex justify-start items-center gap-3" v-if="survey.status !== 'closed'">
            <button
              @click="save()"
              type="button"
              :aria-disabled="autosaveEnabled"
              class="btn"
              :class="{
                'btn-disabled cursor-pointer': autosaveEnabled && !loading,
                'btn-primary': !autosaveEnabled || loading,
              }"
            >
              <div v-if="!loading">
                {{ $t('common.actions.save') }}
              </div>
              <div v-else-if="loading">
                <fontawesome-icon :icon="['fas', 'circle-notch']" class="animate-spin" />
                {{ $t('common.actions.loading') }}
              </div>
            </button>
            <button
              :class="{
                'bg-sprb-700': autosaveEnabled,
                'bg-gray-300': !autosaveEnabled,
              }"
              class="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent Transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              id="autosaveSwitch"
              role="switch"
              :aria-checked="autosaveEnabled"
              @click="autosaveEnabled = !autosaveEnabled"
            >
              <span
                aria-hidden="true"
                class="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 Transition duration-200 ease-in-out"
                :class="{
                  'translate-x-5': autosaveEnabled,
                  'translate-x-0': !autosaveEnabled,
                }"
              ></span>
            </button>
            <label for="autosaveSwitch" class="text-xs font-medium text-sprb-700 text-left">
              {{ $t('survey.autoSave') }}
            </label>
          </div>
          <div class="px-4 pb-4 text-sm text-gray-400 text-start">
            {{ `${$t('survey.lastSave')} ${$filters.formatDate(surveyStore.survey.updatedAt)}` }}
          </div>
        </div>
      </div>
    </div>
  </main>
  <Teleport to="body">
    <Transition name="modal-fade">
      <div v-if="validationModal">
        <ConfirmModal
          @confirm="
            validateSurvey();
            validationModal = false;
          "
          @close="validationModal = false"
          :title="$t('test_validation_modal_title')"
          :content="$t('test_validation_modal_content')"
          :confirmBtnText="$t('test_validation_bouton')"
          confirmBtnColor="green"
        />
      </div>
    </Transition>
    <Transition name="modal-fade">
      <div v-if="deleteSurveyModal">
        <ConfirmModal
          @confirm="
            deleteSurvey(surveyToDelete);
            deleteSurveyModal = false;
          "
          @close="deleteSurveyModal = false"
          :title="$t('delete_test_modal_title')"
          :content="$t('delete_test_modal_content')"
          :confirmBtnText="$t('delete_test_modal_confirm')"
          :confirmBtnColor="'red'"
        />
      </div>
    </Transition>
  </Teleport>
</template>
