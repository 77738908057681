import { defineStore } from 'pinia';
import { ref } from 'vue';

import questionnaireService from '@/services/questionnaire.service.js';

const useQuestionnaireStore = defineStore('questionnaire', () => {
  const questionnaire = ref({});
  const questionnaires = ref([]);

  async function find(filter = {}, params = {}) {
    try {
      const res = await questionnaireService.find({ filter, ...params });
      if (res) {
        questionnaires.value = res;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findById(id, populate) {
    try {
      const res = await questionnaireService.findById(id, { populate });
      if (res) {
        questionnaire.value = res;
      }
    } catch (error) {
      // Feed le notif store
      console.error(error);
    }
  }

  return {
    questionnaire,
    questionnaires,
    find,
    findById,
  };
});

export default useQuestionnaireStore;
