<script async setup>
  import '@/styles/animation.css';

  import { ref, computed, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useLocalePath } from 'vue-i18n-routing';
  import { mergeTranslations, setLocale } from '@/translations/i18n';
  import { useSurveyStore } from '@/stores/survey.store.js';
  import { useUserStore } from '@/stores/user.store.js';
  import { useSelectListStore } from '@/stores/selectList.store.js';
  import SurveyPdfPreview from '@/components/SurveyPdfPreview.vue';
  import PanelSidebar from '@/components/PanelSidebar.vue';
  import { useHead } from '@unhead/vue';
  import { useI18n } from 'vue-i18n';
  const { t } = useI18n();

  const route = useRoute();
  const localePath = useLocalePath();

  const surveyStore = useSurveyStore();
  const userStore = useUserStore();
  const selectListStore = useSelectListStore();

  const currentPanel = ref(0);
  const project = ref([]);
  const loading = ref(false);
  const pdfError = ref(false);

  const isUserConnectedAndOwnedTest = computed(() => {
    return userStore?.connectedUser?.user?._id == surveyStore?.survey?.user?._id;
  });

  watch(currentPanel, () => {
    const top = document.getElementById('panel-' + currentPanel.value).offsetTop;
    // 122 is the height of the navbar + 10px margin
    window.scrollTo({ top: top - 122 });
  });

  // Switch to url params language
  if (route?.query?.language) {
    setLocale(route.query.language);
  }

  await surveyStore.populatedFindById(route.params.id);

  // If survey is deleted, don't try to load questionnaire
  if (surveyStore?.survey?._id) {
    await process();
  }

  async function process() {
    await selectListStore.find({
      version: surveyStore?.survey?.questionnaire?.version,
    });

    // insert questionnaire's project in array to be merged with translations
    project.value.push(surveyStore?.survey?.questionnaire?.project);

    mergeTranslations(surveyStore?.survey?.questionnaire?.panels, 'panels');
    mergeTranslations(project.value, 'projects');
    surveyStore?.survey?.questionnaire?.panels.forEach((panel) => {
      mergeTranslations(panel.questions, 'questions');
    });
    selectListStore?.optionLists.forEach((list) => {
      mergeTranslations(list.options, 'selectLists');
    });
  }

  async function generatePdf() {
    pdfError.value = false;
    loading.value = true;
    const generatePdf = await surveyStore.generatePdf();
    if (generatePdf?.error) {
      pdfError.value = true;
    }
    loading.value = false;
  }

  useHead({
    title: surveyStore?.survey?.title,
    meta: [
      {
        name: 'description',
        content: t('survey.previewTitle'),
      },
    ],
  });
</script>

<!-- eslint-disable max-len -->
<template>
  <div class="sticky top-0 z-10 bg-white border-y border-y-gray-300">
    <nav class="mx-auto max-w-7xl px-4 lg:px-8 py-4">
      <div class="grid grid-cols-6 gap-4">
        <div class="col-span-6 lg:col-span-4">
          <div class="text-gray-500 text-sm font-light leading-3">
            <RouterLink :to="localePath('/user/tests')" class="cursor-pointer hover:text-sprb-700">
              {{ $t('nav_my_tests') }}
            </RouterLink>
            <span class="mx-1">&#8594;</span>
          </div>
          <div class="">
            <h1 class="text-2xl font-medium text-gray-900">
              {{ surveyStore?.survey?.title }}
            </h1>
          </div>
        </div>
        <div class="col-span-6 lg:col-span-2 flex items-center justify-start lg:justify-end space-x-4">
          <RouterLink
            v-if="isUserConnectedAndOwnedTest && surveyStore?.survey?.status != 'closed'"
            :to="localePath(`/test/${surveyStore?.survey?._id}`)"
          >
            <button
              type="button"
              class="btn btn-green"
              :class="{
                'bg-green-700 hover:bg-green-800 focus:ring-green-600': surveyStore?.survey?.status !== 'closed',
                'bg-gray-400 cursor-not-allowed': surveyStore?.survey?.status === 'closed',
              }"
              :disabled="surveyStore?.survey?.status === 'closed'"
            >
              {{ $t('survey.takeBackButton') }}
            </button>
          </RouterLink>
          <div class="grow lg:grow-0 flex justify-end lg:justify-start">
            <div>
              <button
                @click="generatePdf()"
                type="button"
                class="btn"
                :class="{
                  'btn-disabled': loading,
                  'btn-primary': !loading,
                }"
              >
                <div v-if="!loading" class="space-x-2">
                  <fontawesome-icon :icon="['fas', 'download']" />
                  <span>{{ $t('common.actions.downloadPdf') }}</span>
                </div>
                <div v-else-if="loading">
                  <fontawesome-icon :icon="['fas', 'circle-notch']" class="animate-spin" />
                  {{ $t('common.actions.loading') }}
                </div>
              </button>
              <div v-if="pdfError" class="text-red-500 text-sm font-light leading-3 p-1 mt-2 absolute bg-red-100 mr-4">
                {{ $t('survey.errors.generatingPdf') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <main class="mx-auto max-w-7xl px-4 lg:gap-8 lg:px-8 py-8 lg:grid lg:grid-cols-3">
    <div class="border border-l-8 border-sprb-100 border-l-sprb-700 bg-sprb-50 p-4 col-span-3">
      <div class="flex">
        <div class="ml-3">
          <h3 class="text-sm font-medium text-sprb-700">
            {{ $t('survey.previewTitle') }}
          </h3>
          <div class="mt-2 text-sm text-sprb-500">
            <p>
              {{ $t('survey.previewPageDescription') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <template v-if="surveyStore?.survey?._id">
      <SurveyPdfPreview
        :survey="surveyStore?.survey"
        :questionnaire="surveyStore?.survey?.questionnaire"
        :project="surveyStore?.survey?.questionnaire?.project"
        :exceptions="surveyStore?.survey?.exceptions"
        :selectLists="selectListStore?.optionLists"
      />
      <div class="lg:col-span-1">
        <div class="sticky top-32">
          <PanelSidebar
            @changePanel="(panel) => (currentPanel = panel)"
            :panels="surveyStore?.survey?.questionnaire.panels"
            :currentPanel="currentPanel"
          />
        </div>
      </div>
    </template>
    <div v-else class="text-center m-5">
      <i>
        {{ $t('survey.deletedMessage') }}
      </i>
    </div>
  </main>
</template>
