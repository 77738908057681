<template>
  <div class="mx-auto max-w-7xl px-4 lg:px-8 pb-8">
    <ul class="flex gap-2">
      <template v-if="isHome">
        <li
          v-for="page in pages"
          :key="page.title"
          class="p-2 text-sm text-sprb-500 border border-sprb-100 bg-sprb-50 rounded-3xl"
        >
          <RouterLink :to="page.path">
            {{ $t(page.title) }}
          </RouterLink>
        </li>
      </template>
      <li v-else class="p-2 text-sm text-sprb-500 border border-sprb-100 bg-sprb-50 rounded-3xl">
        <RouterLink :to="homepage.path">
          {{ $t(homepage.title) }}
        </RouterLink>
      </li>
    </ul>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { useRoute, RouterLink } from 'vue-router';
  import { useLocalePath } from 'vue-i18n-routing';

  const route = useRoute();
  const localePath = useLocalePath();

  const currentPath = computed(() => route.path.split('/').pop());
  const isHome = computed(() => currentPath.value === 'fr' || currentPath.value === 'nl');

  const homepage = { title: 'nav_homepage', path: localePath('/') };
  const pages = [
    { title: 'nav_faq', path: localePath('/faq') },
    { title: 'nav_inscription', path: localePath('/signup') },
    { title: 'nav_signin', path: localePath('/signin') },
    { title: 'nav_footer_privacy', path: localePath('/page/privacy-policy') },
    { title: 'nav_footer_ecoconception', path: localePath('/page/ecoconception') },
  ];
</script>
