<script setup>
  import { useRoute } from 'vue-router';
  import PanelIcons from '@/components/SurveyPanelIcons.vue';
  const route = useRoute();
  const isPDF = route?.query?.pdf === 'true' ? true : false;
  /**
   * Display the preview of the PDF to be print
   * It contains only the PDF file content
   */
  const props = defineProps({
    // Actual survey filled by user
    survey: {
      type: Object,
      required: true,
    },
    // Survey's questionnaire ref. Used as model to build the full survey
    questionnaire: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
    exceptions: {
      type: Object,
      required: true,
    },
    selectLists: {
      type: Array,
      required: true,
    },
  });

  function getSelectedOption(listId, optionId) {
    const selectedList = props.selectLists.find((list) => {
      return list._id === listId;
    });
    const selectedOption = selectedList.options.find((option) => {
      return option.id === optionId;
    });

    return selectedOption;
  }

  // Filter questionnaire's questions list with only active ones
  function getActiveQuestions(panel) {
    return panel.questions.filter((question) => {
      return !props.questionnaire.inactiveQuestions.includes(question._id);
    });
  }

  // Get questinnaire exceptions
  function displayExceptions(panel) {
    return (
      panel.slug === 'general-information' &&
      (props.questionnaire.useFullException || props.questionnaire.usePartialException)
    );
  }

  function panelIsInExceptions(panel) {
    // Full exceptions
    if (props.exceptions.fullExceptionCheckboxes.length > 0 && panel.useForFullExceptions) {
      return true;
    }
    // Partial exceptions
    if (props.exceptions.partialExceptionSelect.includes(panel._id)) {
      return true;
    }

    return false;
  }

  // If additional critere is not in exceptions, and is empty, we do not display questions details.
  function panelIsNotEmptyAdditionalCriteria(panel) {
    if (panel.slug == 'additional-criteria') {
      if (panelIsInExceptions(panel)) {
        return true;
      }
      let emptyPanel = true;
      // eslint-disable-next-line guard-for-in
      const answers = props.survey.answers[panel._id];
      for (const answer in answers) {
        if (Object.prototype.hasOwnProperty.call(answers, answer)) {
          if (answers[answer].value != '') {
            emptyPanel = false;
          }
        }
      }
      return !emptyPanel;
    } else {
      return true;
    }
  }

  function getPanelBySlug(panelSlug) {
    return props.questionnaire.panels.find((panel) => panel.slug === panelSlug);
  }

  function getQuestionBySlug(panelSlug, questionSlug) {
    const panel = getPanelBySlug(panelSlug);
    const question = panel.questions.find((question) => question.slug === questionSlug);

    return question;
  }

  function getQuestionLabelBySlug(panelSlug, questionSlug) {
    const question = getQuestionBySlug(panelSlug, questionSlug);
    return `questions.${question._id}.name`;
  }

  function questionIsInSurveyBySlug(panelSlug, questionSlug) {
    const panel = getPanelBySlug(panelSlug);
    const question = getQuestionBySlug(panelSlug, questionSlug);
    // eslint-disable-next-line no-prototype-builtins
    return props.survey.answers[panel._id].hasOwnProperty(question._id);
  }

  function getAnswerByQuestionSlug(panelSlug, questionSlug) {
    const panel = getPanelBySlug(panelSlug);
    const question = getQuestionBySlug(panelSlug, questionSlug);
    return props.survey.answers[panel._id][question._id]?.value || false;
  }
</script>

<!-- eslint-disable max-len -->
<template>
  <div id="contentToPrint" class="col-span-2">
    <div
      class="mb-4 p-4"
      :class="{
        'bg-white overflow-hidden rounded-lg shadow ': !isPDF,
      }"
    >
      <div class="flex items-center justify-start">
        <div class="flex-shrink-0 flex items-center justify-center w-16">
          <svg class="scale-75 w-16 h-16" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 100 75">
            <defs>
              <clipPath id="a" transform="translate(-21.74 -191.25)">
                <path d="M21.74 191.25h374.39v69.27H21.74z" style="fill: none" />
              </clipPath>
            </defs>
            <g style="clip-path: url(#a)">
              <path
                d="M51.61 191.25c-15 0-29.87 11.29-29.87 30.18 0 18.32 17 29.71 31.7 29.71 16.22 0 24-5.08 37.71-2.32A37.21 37.21 0 0 1 110.49 259a3.45 3.45 0 0 0 4.3.68c1.19-.79 2-2.27 1.19-5.81-1.87-8.25-6.27-17.48-11.37-25.36-10-15.48-30.35-37.21-53-37.21"
                style="fill: #2f3e8b"
                transform="translate(-21.74 -191.25)"
              />
            </g>
          </svg>
        </div>
        <div class="ml-2">
          <div class="flex space-x-2">
            <label class="block mb-1 text-gray-500 leading-none">{{ $t('survey.projectType') }}</label>
            <div
              v-if="survey.status === 'closed' && !isPDF"
              class="rounded-full px-2 text-xs font-semibold leading-5 bg-green-100 text-green-800"
            >
              {{ $t('test_status_closed') }}
            </div>
            <div
              v-if="survey.status === 'draft'"
              class="rounded-full px-2 text-xs font-semibold leading-5 bg-sprb-100 text-sprb-600"
            >
              {{ $t('test_status_draft') }}
            </div>
            <div
              v-if="survey.status === 'abandoned'"
              class="rounded-full px-2 text-xs font-semibold leading-5 bg-red-100 text-red-700"
            >
              {{ $t('test_status_abandoned') }}
            </div>
          </div>
          <div class="text-xl font-medium">
            {{ $t('projects.' + survey.questionnaire.project._id + '.name') }}
          </div>
          <div class="text-sm text-gray-500">
            {{
              survey.status === 'closed'
                ? '' + $filters.formatDate(survey.updatedAt, { noHours: true })
                : $t('survey.lastUpdateThe') + ' ' + $filters.formatDate(survey.updatedAt, { noHours: true })
            }}
          </div>
        </div>
      </div>
      <div
        class="mt-4"
        :class="{
          'text-sm': !isPDF,
        }"
      >
        <p v-html="$t('new_test_page_description_2')" class="mb-3" />
        <p>{{ $t(`projects.${project._id}.description`) }}</p>
      </div>
    </div>

    <div
      :class="{
        'overflow-hidden shadow rounded-lg': !isPDF,
      }"
    >
      <div
        class="divide-y divide-gray-200 px-4 pb-4 space-y-4"
        :class="{
          'bg-white overflow-hidden rounded-lg shadow': !isPDF,
        }"
      >
        <div
          v-for="(panel, index) in questionnaire.panels"
          :key="panel._id"
          :id="'panel-' + index"
          class="pt-4"
          style="page-break-after: always"
        >
          <div class="text-sprb-700 text-xl font-bold mb-2 flex">
            <PanelIcons :panelSlug="panel.slug"></PanelIcons>
            {{ $t(`panels.${panel._id}.title`) }}
          </div>

          <!-- General Informations -->
          <dl v-if="panel.slug === 'general-information'" class="grid grid-cols-2">
            <div class="col-span-1">
              <div class="flex gap-2">
                <dt
                  :class="{
                    'font-medium text-gray-700': !isPDF,
                    'font-bold text-gray-900': isPDF,
                  }"
                >
                  {{ $t(getQuestionLabelBySlug('general-information', 'firstname')) + $t('common.punctuation.colon') }}
                </dt>
                <dd class="inline text-gray-500">
                  <span v-if="survey?.user?.firstName">{{ survey.user.firstName }}</span>
                  <span v-else class="">{{ $t('survey.anonymised') }}</span>
                </dd>
              </div>
              <div class="flex gap-2 mt-1">
                <dt
                  :class="{
                    'font-medium text-gray-700': !isPDF,
                    'font-bold text-gray-900': isPDF,
                  }"
                >
                  {{ $t(getQuestionLabelBySlug('general-information', 'lastname')) + $t('common.punctuation.colon') }}
                </dt>
                <dd class="inline text-gray-500">
                  <span v-if="survey?.user?.lastName">{{ survey.user.lastName }}</span>
                  <span v-else class="">{{ $t('survey.anonymised') }}</span>
                </dd>
              </div>
              <div class="flex gap-2 mt-1">
                <dt
                  :class="{
                    'font-medium text-gray-700': !isPDF,
                    'font-bold text-gray-900': isPDF,
                  }"
                >
                  {{ $t(getQuestionLabelBySlug('general-information', 'email')) + $t('common.punctuation.colon') }}
                </dt>
                <dd class="inline text-gray-500">
                  <span v-if="survey?.user?.email">{{ survey.user.email }}</span>
                  <span v-else class="">{{ $t('survey.anonymised') }}</span>
                </dd>
              </div>
            </div>
            <div class="col-span-1">
              <div class="gap-2">
                <dt
                  :class="{
                    'font-medium text-gray-700': !isPDF,
                    'font-bold text-gray-900': isPDF,
                  }"
                >
                  {{
                    $t(getQuestionLabelBySlug('general-information', 'guardianship')) +
                    $t('common.punctuation.colon') +
                    ' '
                  }}
                </dt>
                <dd
                  v-if="getAnswerByQuestionSlug('general-information', 'guardianship')"
                  class="contents text-gray-500"
                >
                  {{
                    $t(
                      `selectLists['${
                        getSelectedOption(
                          getQuestionBySlug('general-information', 'guardianship').selectList,
                          getAnswerByQuestionSlug('general-information', 'guardianship')
                        )?._id
                      }']`
                    )
                  }}
                </dd>
                <dd v-else>/</dd>
              </div>
              <div class="gap-2 mt-1">
                <dt
                  :class="{
                    'font-medium text-gray-700': !isPDF,
                    'font-bold text-gray-900': isPDF,
                  }"
                >
                  {{
                    $t(getQuestionLabelBySlug('general-information', 'organization')) +
                    $t('common.punctuation.colon') +
                    ' '
                  }}
                </dt>
                <dd
                  v-if="getAnswerByQuestionSlug('general-information', 'organization')"
                  class="contents text-gray-500"
                >
                  {{
                    $t(
                      `selectLists['${
                        getSelectedOption(
                          getQuestionBySlug('general-information', 'organization').selectList,
                          getAnswerByQuestionSlug('general-information', 'organization')
                        )?._id
                      }']`
                    )
                  }}
                </dd>
                <dd v-else>/</dd>
              </div>
              <div class="gap-2 mt-1">
                <dt
                  :class="{
                    'font-medium text-gray-700': !isPDF,
                    'font-bold text-gray-900': isPDF,
                  }"
                >
                  {{
                    $t(getQuestionLabelBySlug('general-information', 'competence')) +
                    $t('common.punctuation.colon') +
                    ' '
                  }}
                </dt>
                <dd v-if="getAnswerByQuestionSlug('general-information', 'competence')" class="contents text-gray-500">
                  {{
                    $t(
                      `selectLists['${
                        getSelectedOption(
                          getQuestionBySlug('general-information', 'competence').selectList,
                          getAnswerByQuestionSlug('general-information', 'competence')
                        )?._id
                      }']`
                    )
                  }}
                </dd>
                <dd v-else>/</dd>
              </div>
            </div>
            <div class="col-span-2 mt-2">
              <div class="">
                <dt
                  :class="{
                    'font-medium text-gray-700': !isPDF,
                    'font-bold text-gray-900': isPDF,
                  }"
                >
                  {{
                    $t(getQuestionLabelBySlug('general-information', 'project-title')) + $t('common.punctuation.colon')
                  }}
                </dt>
                <dd v-if="getAnswerByQuestionSlug('general-information', 'project-title')" class="text-gray-500">
                  {{ getAnswerByQuestionSlug('general-information', 'project-title') }}
                </dd>
                <dd v-else>/</dd>
              </div>

              <dt
                :class="{
                  'font-medium text-gray-700 mt-2': !isPDF,
                  'font-bold text-gray-900 mt-2': isPDF,
                }"
              >
                {{
                  $t(getQuestionLabelBySlug('general-information', 'project-description')) +
                  $t('common.punctuation.colon')
                }}
              </dt>
              <dd v-if="getAnswerByQuestionSlug('general-information', 'project-description')" class="text-gray-500">
                {{ getAnswerByQuestionSlug('general-information', 'project-description') }}
              </dd>
              <dd v-else>/</dd>
            </div>

            <div
              class="col-span-2 mt-2"
              v-for="(amount, index) in [
                'grant-amount',
                'quel-est-le-budget-estime-du-marche-public-ou-de-la-concession',
                'planned-amount',
              ]"
              :key="index"
            >
              <div v-if="questionIsInSurveyBySlug('general-information', amount)">
                <dt
                  :class="{
                    'font-medium text-gray-700': !isPDF,
                    'font-bold text-gray-900': isPDF,
                  }"
                >
                  {{ $t(getQuestionLabelBySlug('general-information', amount)) + $t('common.punctuation.colon') }}
                </dt>
                <dd v-if="getAnswerByQuestionSlug('general-information', amount)" class="text-gray-500">
                  <span v-if="$i18n.locale === 'nl'" style="padding-right: 4px">€</span>
                  <span>
                    {{
                      (!isNaN(amount)
                        ? new Intl.NumberFormat('nl-BE').format(getAnswerByQuestionSlug('general-information', amount))
                        : getAnswerByQuestionSlug('general-information', amount)) || '/'
                    }}
                  </span>
                  <span style="padding-left: 4px">{{ $t('survey.exVat') }}</span>
                </dd>
                <dd v-else>/</dd>
              </div>
            </div>
          </dl>

          <div v-else-if="!panelIsInExceptions(panel) && panelIsNotEmptyAdditionalCriteria(panel)">
            <div v-for="question in getActiveQuestions(panel)" :key="question._id" class="question mb-4">
              <div
                class="block"
                :class="{
                  'font-medium text-gray-700': !isPDF,
                  'font-bold text-gray-900': isPDF,
                }"
              >
                {{ $t(`questions.${question._id}.name`) }}
              </div>
              <div
                v-if="$t(`questions.${question._id}.comment`)"
                v-html="$t(`questions.${question._id}.comment`)"
                class="text-sm text-gray-500 mb-2"
              />
              <div v-if="question.fieldType === 'select'" class="content text-gray-500">
                <span v-if="survey.answers[panel._id][question._id].value">
                  <div>
                    {{
                      $t(
                        `selectLists['${
                          getSelectedOption(question.selectList, survey.answers[panel._id][question._id].value)._id
                        }']`
                      )
                    }}
                  </div>
                  <div v-if="survey.answers[panel._id][question._id].explanation" class="italic">
                    {{ survey.answers[panel._id][question._id].explanation }}
                  </div>
                </span>
                <span v-else>/</span>
              </div>
              <div v-else-if="question.fieldType === 'radio'" class="content text-gray-500">
                <span v-if="survey.answers[panel._id][question._id].value">
                  {{
                    $t(
                      `selectLists['${
                        getSelectedOption(question.selectList, survey.answers[panel._id][question._id].value)._id
                      }']`
                    )
                  }}
                  - {{ survey.answers[panel._id][question._id].explanation }}
                </span>
                <span v-else>/</span>
              </div>
              <div v-else class="content">
                <span class="text-gray-500">
                  <span class="text-gray-500">
                    {{ survey.answers[panel._id][question._id].value || '/' }}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div v-else-if="!panelIsNotEmptyAdditionalCriteria(panel)">
            <span class="survey-answer text-gray-500">/</span>
          </div>
          <div v-else class="text-gray-500">
            <i>{{ $t('survey.exceptions.panelIsExcepted') }}</i>
          </div>
          <!-- exceptions -->
          <div v-if="displayExceptions(panel)" class="mt-4">
            <div class="text-lg font-medium mb-3">
              {{ $t('test_exception_title') }}
            </div>
            <div v-if="questionnaire.usePartialException" class="mt-2">
              <div class="question-label">
                {{ $t('test_exception_partial_question') }}
              </div>
              <div v-if="exceptions?.partialExceptionSelect?.length > 0" class="question">
                <ul class="survey-answer list-disc pl-5 mt-3">
                  <li
                    class="survey-answer text-gray-500"
                    v-for="(exceptionId, index) in exceptions.partialExceptionSelect"
                    :key="index"
                  >
                    {{ $t(`panels.${exceptionId}.title`) }}
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="questionnaire.usePartialException" class="mt-4">
              <div v-if="exceptions?.partialExceptionSelect?.length > 0" class="question">
                <div class="question-label">
                  {{ $t('survey.exceptions.explanation') + $t('common.punctuation.colon') }}
                </div>
                <div class="survey-answer text-gray-700">
                  {{ exceptions.partialExceptionExplanation || '/' }}
                </div>
              </div>
              <div v-else>
                <i class="text-gray-500">
                  {{ $t('survey.exceptions.chooseOptionsPanel') }}
                </i>
              </div>
            </div>
            <div
              v-if="questionnaire.useFullException && exceptions.fullExceptionCheckboxes?.length > 0"
              class="exception mt-3"
            >
              <h3 class="subtitle">
                <b>
                  {{ $t('test_exception_full_question') }}
                </b>
              </h3>
              <ul class="survey-answer list-disc pl-5 mt-3">
                <li
                  v-for="(exceptionId, index) in exceptions.fullExceptionCheckboxes"
                  :key="index"
                  class="survey-answer text-gray-500"
                >
                  {{
                    $t(
                      `selectLists.${
                        getSelectedOption(selectLists.find((list) => list.slug === 'exceptions')?._id, exceptionId)._id
                      }`
                    )
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
